import { FREQUENCIES } from '../constants/app';

interface CustomFrequency {
  value: string;
  name: string;
  type: string;
}

export const getCustomFrequencyValue = (value: string): CustomFrequency => {
  const frequency = Number(value);
  if (frequency % 365 === 0) {
    return { value: (frequency / 365).toFixed(0), name: 'years', type: 'YEAR' };
  } else if (frequency % 30 === 0) {
    return {
      value: (frequency / 30).toFixed(0),
      name: 'months',
      type: 'MONTH',
    };
  } else if (frequency % 7 === 0) {
    return { value: (frequency / 7).toFixed(0), name: 'weeks', type: 'WEEK' };
  } else {
    return { value, name: 'days', type: 'DAY' };
  }
};

export const getActivityFrequencyOptionValue = (value: string): string => {
  switch (value) {
    case FREQUENCIES[0]:
      return 'Daily';
    case FREQUENCIES[1]:
      return 'Weekly';
    case FREQUENCIES[2]:
      return 'Monthly';
    case FREQUENCIES[3]:
      return 'Quarterly';
    case FREQUENCIES[4]:
      return 'Yearly';
    default:
      // eslint-disable-next-line no-case-declarations
      const frequency = getCustomFrequencyValue(value);
      return `${frequency.value} ${frequency.name}`;
  }
};
