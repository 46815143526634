import { Observable } from 'rxjs';
import { GET, POST, PUT } from './baseApi';
import {
  Maintenance,
  CreateMaintenanceRequest,
  MaintenancesRequest,
  MaintenancesResponse,
  CostOverTimeRequest,
  CostOverTimeResponseItem,
  CompareCostOvertimeResponse,
} from '../../models/maintenance';

const MAINTENANCE_ENDPOINT = '/maintenance/';
const MAINTENANCE_COST_OVER_TIME_ENDPOINT = `${MAINTENANCE_ENDPOINT}costOverPeriod`;
const MAINTENANCE_COMPARE_COST_OVER_TIME_ENDPOINT = `${MAINTENANCE_ENDPOINT}compareCostOverPeriod`;

export const getMaintenances = (
  payload?: MaintenancesRequest
): Observable<MaintenancesResponse> =>
  GET(MAINTENANCE_ENDPOINT, { ...payload });

export const getMaintenance = (
  maintenanceID: string
): Observable<Maintenance> => GET(`${MAINTENANCE_ENDPOINT}${maintenanceID}`);

export const createMaintenance = (
  maintenance: CreateMaintenanceRequest
): Observable<Maintenance> => POST(MAINTENANCE_ENDPOINT, { ...maintenance });

export const updateMaintenance = (
  maintenance: Maintenance
): Observable<Maintenance> =>
  PUT(`${MAINTENANCE_ENDPOINT}${maintenance.maintenanceId}`, {
    ...maintenance,
  });

export const getCostOverPeriod = (
  payload: CostOverTimeRequest
): Observable<Array<CostOverTimeResponseItem>> =>
  GET(MAINTENANCE_COST_OVER_TIME_ENDPOINT, { ...payload });

export const getCompareCostOverPeriod = (
  payload: CostOverTimeRequest
): Observable<CompareCostOvertimeResponse> =>
  GET(MAINTENANCE_COMPARE_COST_OVER_TIME_ENDPOINT, { ...payload });
