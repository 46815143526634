import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BiAddToQueue } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import MachineList from '../../../machine/component/MachineList/MachineList';
import { RootState } from '../../../../app/redux/rootReducer';
import { isAdmin as checkIsAdmin } from '../../../auth/redux/authSelector';

const AdminMachineDetails: React.FC = () => {
  const state = useSelector((state: RootState) => state);
  const isAdmin = checkIsAdmin(state);

  useEffect(() => {
    document.title = 'Machines';
  }, []);

  return (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          {isAdmin && (
            <>
              <a href="machines-config" className="add-new-link-btn ft-size-16">
                <BiAddToQueue size={18} /> Add New Machines
              </a>
            </>
          )}
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-lg-12">
          <MachineList />
        </div>
      </div>
    </div>
  );
};

export default AdminMachineDetails;
