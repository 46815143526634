import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Category } from '../../../../models/category';
import { getCategories } from '../../../../services/api/categoryApi';
import CategoryCard from '../CategoryCard/CategoryCard';
import { notificationActions } from '../../../../app/redux/notification.slice';
import { Loader } from '../../../../shared/components';
import { RootState } from '../../../../app/redux/rootReducer';

interface Props {
  state: RootState;
  isCategoryLoading: boolean;
}

const CategoryList: React.FC<Props> = ({ state, isCategoryLoading }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState<Array<Category>>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    getCategories().subscribe(
      (response: Array<Category>) => {
        setCategoryList(response);
        setIsLoading(false);
      },
      (err: Error) => {
        setIsLoading(false);
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: err.message,
            title: `Error: Couldn't Fetch Categories`,
          })
        );
      }
    );
  }, [dispatch, isCategoryLoading]);

  return (
    <>
      {isLoading || isCategoryLoading ? (
        <Loader />
      ) : (
        <div className="row">
          {categoryList.map((item, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <CategoryCard categoryData={item} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  isCategoryLoading: state.organization.categoryLoading,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
