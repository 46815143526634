import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Col, Row } from 'reactstrap';

import { notificationActions } from '../../../app/redux/notification.slice';
import { RootState } from '../../../app/redux/rootReducer';
import { chartTypes } from '../../../constants/chart';
import { DashboardFilter, Maintenance } from '../../../models/maintenance';
import { getMaintenances } from '../../../services/api/maintenanceApi';
import AppChart from '../../../shared/components/AppChart/AppChart';
import {
  machinesGroupByType,
  maintenanceGroupByUser,
} from '../../../utils/groupUtils';
import MaintenanceGroup from '../../maintenance/component/MaintenanceGroup';
import { getMachineList, getMachineType } from '../redux/organizationSelector';
import DashboardFilterBar from './DashboardFilterBar';
import { Machine } from '../../../models/machine';
import { MachineType } from '../../../models/machineType';
import { getUserList } from '../../../app/redux/appSelector';
import HomeTopDashboard from './HomeTopDashboard/HomeTopDashboard';
import { User } from '../../../models/user';

interface KeyValueMap<T> {
  [key: string]: T[];
}

interface UserGraphItem {
  userId: string;
  email: string;
  me: number;
  others: number;
  ongoing: number;
}

interface MachineGraphItem {
  machineTypeId: string;
  machineType: string;
  on: number;
  off: number;
}

interface Props {
  machineList: Array<Machine>;
  getMachineTypeDetails: (machineType: string) => MachineType | undefined;
  users: Array<User>;
}

const AdminDashboard: React.FC<Props> = ({
  machineList,
  getMachineTypeDetails,
  users,
}: Props) => {
  const [completedMaintenanceCount, setCompletedMaintenanceCount] = useState(0);
  const [period, setPeriod] = useState<DashboardFilter>();
  const [userGraphData, setUserGraphData] = useState<Array<UserGraphItem>>([]);
  const [maintenanceArray, setMaintenanceArray] = useState<Array<Maintenance>>(
    []
  );
  const [machineGraphData, setMachineGraphData] = useState<
    Array<MachineGraphItem>
  >([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const machineGroups = machinesGroupByType(
      machineList
    ) as KeyValueMap<Machine>;
    setMachineGraphData(
      Object.keys(machineGroups).map((key) => {
        let on = 0;
        for (let i = 0; i < machineGroups[key].length; i = i + 1) {
          if (machineGroups[key][i].on) {
            on = on + 1;
          }
        }
        return {
          machineTypeId: key,
          machineType: getMachineTypeDetails(key)?.name || '',
          on,
          off: machineGroups[key].length - on,
        };
      })
    );
  }, [machineList]);

  const getResponsibleGraphData = (maintenanceArray: Array<Maintenance>) => {
    let completedSum = 0;
    const orderByUser = maintenanceGroupByUser(
      maintenanceArray
    ) as KeyValueMap<Maintenance>;
    setUserGraphData(
      Object.keys(orderByUser).map((key) => {
        let me = 0;
        let others = 0;
        let ongoing = 0;
        for (let i = 0; i < orderByUser[key].length; i = i + 1) {
          if (orderByUser[key][i].endedByUser) {
            if (
              key === orderByUser[key][i].startedByUser.userId &&
              key === orderByUser[key][i].endedByUser?.userId
            ) {
              me = me + 1;
            } else {
              others = others - 1;
            }
          } else {
            ongoing = ongoing + 1;
          }
        }
        completedSum = completedSum + me - others;
        return {
          userId: key,
          email: orderByUser[key][0].user.email,
          me,
          others,
          ongoing,
        };
      })
    );
    setCompletedMaintenanceCount(completedSum);
  };

  useEffect(() => {
    if (period) {
      getMaintenances({
        dataRange: `${period.date},${moment(period.date)
          .tz(moment.tz.guess())
          .add(period.period - 1, 'days')
          .format('YYYY-MM-DD')}`,
      }).subscribe(
        (response) => {
          getResponsibleGraphData(response.items);
          setMaintenanceArray(response.items);
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Fetch Maintenance`,
            })
          );
        }
      );
    }
  }, [dispatch, period]);

  const overview = useSelector(
    (state: RootState) => state.organization.overview
  );

  const getOverviewPieChartData = () => {
    const graphData = [];
    if (overview) {
      graphData.push({
        key: 'Delayed',
        data: overview.delayedMaintenances.length,
      });
      graphData.push({
        key: 'Ongoing',
        data: overview.ongoingMaintenances.length,
      });
      graphData.push({
        key: 'Completed',
        data: completedMaintenanceCount,
      });
    }
    return graphData;
  };

  const getOverviewPercentage = () => {
    if (overview) {
      const sum =
        completedMaintenanceCount +
        overview.ongoingMaintenances.length +
        overview.delayedMaintenances.length;
      return `${String(Math.round((completedMaintenanceCount * 100) / sum))} %`;
    }
    return '';
  };

  const onChangeHandler = (filter: DashboardFilter) => {
    setPeriod(filter);
  };

  return (
    <div className="container pt-1 main-container-div">
      <DashboardFilterBar compare={false} onChange={onChangeHandler} />
      <HomeTopDashboard
        delayed={overview?.delayedMaintenances.length}
        onGoing={overview?.ongoingMaintenances.length}
        upComing={overview?.upcomingMaintenances.length}
        users={users.length}
      />
      <div className="row">
        <div className="col">
          <AppChart
            label="Machine Details"
            chartType={chartTypes.BRUSH_BAR}
            data={machineGraphData}
            width={800}
            dataKey={['machineType', 'off', 'on']}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <AppChart
            label="User Responsibility"
            chartType={chartTypes.POSITIVE_NEGATIVE}
            data={userGraphData}
            dataKey={['email', 'others', 'me', 'ongoing']}
          />
        </div>
        <div className="col">
          <AppChart
            label="Overview"
            width={300}
            height={200}
            chartType={chartTypes.PIE}
            data={getOverviewPieChartData()}
            innerText={getOverviewPercentage()}
            dataKey={[]}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col">
          <MaintenanceGroup maintenanceArray={maintenanceArray} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  machineList: getMachineList(state),
  getMachineTypeDetails: (machineType: string) =>
    getMachineType(state, machineType),
  users: getUserList(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
