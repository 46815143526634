/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Observable, concat, from, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { map, filter, mergeMap, catchError } from 'rxjs/operators';
import moment from 'moment-timezone';

import { organizationActions } from './organizationSlice';
import {
  createMachineType,
  getMachineTypes,
} from '../../../services/api/machineTypeApi';
import {
  getActivityTypes,
  createActivityType,
} from '../../../services/api/activityTypeApi';
import { createMachine, deleteMachine } from '../../../services/api/machineApi';
import { appAction } from '../../../app/redux/appSlice';
import { navigationActions } from '../../../app/redux/navigationSlice';
import { createActivity } from '../../../services/api/activityApi';
import { getMaintenances } from '../../../services/api/maintenanceApi';
import { getMachines } from '../../../services/api/machineApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { getOverview } from '../../../services/api/overviewApi';
import { getCategories } from '../../../services/api/categoryApi';
import { authActions } from '../../auth/redux/authSlice';
import { createCategory } from '../../../services/api/categoryApi';
import { createSection } from '../../../services/api/sectionApi';
import {
  createWorkshop,
  getWorkshops,
} from '../../../services/api/workshopApi';

const fetchMachineTypeEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchMachineTypeList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchMachineTypeList.match),
    mergeMap((action) =>
      concat(
        from(getMachineTypes()).pipe(
          map((response) =>
            organizationActions.fetchMachineTypeListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Machine Type`,
        })
      )
    )
  );

const fetchWorkshopEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchWorkshopList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchWorkshopList.match),
    mergeMap((action) =>
      concat(
        from(getWorkshops(action.payload)).pipe(
          map((response) =>
            organizationActions.fetchWorkshopListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Workshops`,
        })
      )
    )
  );

const fetchActivityTypeEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchActivityTypeList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchActivityTypeList.match),
    mergeMap((action) =>
      concat(
        from(getActivityTypes()).pipe(
          map((response) =>
            organizationActions.fetchActivityTypeListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Activity Type`,
        })
      )
    )
  );

const createMachineEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.createMachine),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.createMachine.match),
    mergeMap((action) => {
      if (action.payload.machineTypeId === 'new') {
        const machineType = {
          name: action.payload.name,
          horsePower: action.payload.horsePower,
          specification: action.payload.specification,
          machineSection: action.payload.machineSection,
          companyId: action.payload.companyId,
        };
        return concat(
          of(appAction.setLoading(true)),
          from(createMachineType(machineType)).pipe(
            mergeMap((response) =>
              concat(
                of(
                  organizationActions.createMachine({
                    ...action.payload,
                    machineTypeId: response.id,
                  })
                ),
                of(organizationActions.fetchMachineTypeList())
              )
            ),
            catchError((error: Error) =>
              of(
                notificationActions.setToast({
                  severity: 'danger',
                  message: error.message,
                  title: 'Error: Failed To Create Machine Type',
                })
              )
            )
          )
        );
      }
      const machine = {
        machineName: action.payload.machineName,
        machineTypeId: action.payload.machineTypeId,
        machineSubNumber: action.payload.machineSubNumber,
        responsibleUserId: action.payload.responsibleUserId,
        createdAt: action.payload.createdAt,
      };
      return concat(
        of(appAction.setLoading(true)),
        from(createMachine(machine)).pipe(
          mergeMap((response) =>
            concat(
              of(
                navigationActions.setNavigationDetails({
                  pathname: `/machines/${response.machineId}`,
                  queryParams: {},
                })
              ),
              of(organizationActions.fetchMachineList())
            )
          ),
          catchError((error: Error) =>
            of(
              notificationActions.setToast({
                severity: 'danger',
                message: error.message,
                title: 'Error: Failed To Create Machine',
              })
            )
          )
        ),
        of(appAction.setLoading(false))
      );
    })
  );

const createActivityEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.createActivity),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.createActivity.match),
    mergeMap((action) => {
      if (action.payload.activityTypeId === 'new') {
        const activityType = {
          machineTypeId: action.payload.machineTypeId,
          name: action.payload.name,
          frequency: Number(action.payload.frequency),
          companyId: action.payload.companyId,
        };
        return concat(
          from(createActivityType(activityType)).pipe(
            mergeMap((response) =>
              concat(
                of(
                  organizationActions.createActivity({
                    ...action.payload,
                    activityTypeId: response.activityTypeId,
                  })
                ),
                of(organizationActions.fetchActivityTypeList())
              )
            ),
            catchError((error: Error) =>
              of(
                notificationActions.setToast({
                  severity: 'danger',
                  message: error.message,
                  title: 'Error: Failed To Create Activity Type',
                })
              )
            )
          )
        );
      }
      const activity = {
        machineId: action.payload.machineId,
        activityTypeId: action.payload.activityTypeId,
        responsibleUserId: action.payload.responsibleUserId,
        nextDueDate: action.payload.nextDueDate,
        on: action.payload.on || true,
        createdAt: moment().tz(moment.tz.guess()).format(),
      };
      return concat(
        from(createActivity(activity)).pipe(
          mergeMap((response) =>
            of(organizationActions.createActivitySuccess())
          ),
          catchError((error: Error) =>
            concat(
              of(
                notificationActions.setToast({
                  severity: 'danger',
                  message: error.message,
                  title: 'Error: Failed To Create Activity',
                })
              ),
              of(organizationActions.createActivitySuccess())
            )
          )
        )
      );
    })
  );

const fetchMaintenanceListEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchMaintenanceList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchMaintenanceList.match),
    mergeMap((action) =>
      concat(
        from(getMaintenances(action.payload)).pipe(
          map((response) =>
            organizationActions.fetchMaintenanceListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Maintenances`,
        })
      )
    )
  );

const fetchMachineListEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchMachineList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchMachineList.match),
    mergeMap((action) =>
      concat(
        from(getMachines()).pipe(
          map((response) =>
            organizationActions.fetchMachineListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Machines`,
        })
      )
    )
  );

const fetchOverviewEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchOverview),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchOverview.match),
    mergeMap((action) =>
      concat(
        from(getOverview()).pipe(
          map((response) => organizationActions.fetchOverviewSuccess(response))
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Data`,
        })
      )
    )
  );

const deleteMachineEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.deleteMachine),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.deleteMachine.match),
    mergeMap((action) =>
      concat(
        from(deleteMachine(action.payload.machineId)).pipe(
          mergeMap(() =>
            concat(
              of(organizationActions.fetchMachineList()),
              of(
                navigationActions.setNavigationDetails({
                  pathname: `/machines`,
                  queryParams: {},
                })
              )
            )
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: 'Error: Failed To Delete Machine',
        })
      )
    )
  );

const fetchCategoryListEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetCategoryList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetCategoryList.match),
    mergeMap(() =>
      concat(
        from(getCategories()).pipe(
          map((response) =>
            organizationActions.fetCategoryListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: 'Fetch Categories',
        })
      )
    )
  );

const resetCategoryEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(authActions.logoutUser),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(authActions.logoutUser.match),
    map(() => organizationActions.setSelectedCategory(''))
  );

const createCategoryEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.createCategory),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.createCategory.match),
    mergeMap((action) => {
      const category = {
        categoryName: action.payload.categoryName,
        responsibleUserId: action.payload.responsibleUserId,
      };
      return concat(
        from(createCategory(category)).pipe(
          mergeMap((response) =>
            of(organizationActions.createCategorySuccess())
          ),
          catchError((error: Error) =>
            concat(
              of(
                notificationActions.setToast({
                  severity: 'danger',
                  message: error.message,
                  title: 'Error: Failed To Create Category',
                })
              ),
              of(organizationActions.createCategorySuccess())
            )
          )
        )
      );
    })
  );

const createSectionEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.createSection),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.createSection.match),
    mergeMap((action) => {
      const section = {
        categoryId: action.payload.categoryId,
        sectionName: action.payload.sectionName,
        responsibleUserId: action.payload.responsibleUserId,
      };
      return concat(
        from(createSection(section)).pipe(
          mergeMap((response) =>
            of(organizationActions.createSectionSuccess())
          ),
          catchError((error: Error) =>
            concat(
              of(
                notificationActions.setToast({
                  severity: 'danger',
                  message: error.message,
                  title: 'Error: Failed To Create Section',
                })
              ),
              of(organizationActions.createSectionSuccess())
            )
          )
        )
      );
    })
  );

const createWorkshopEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.createWorkshop),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.createWorkshop.match),
    mergeMap((action) => {
      const workshop = {
        workshopName: action.payload.workshopName,
        responsibleUserId: action.payload.responsibleUserId,
        categoryId: action.payload.categoryId,
        sectionId: action.payload.sectionId,
      };
      return concat(
        from(createWorkshop(workshop)).pipe(
          mergeMap((response) =>
            of(organizationActions.createWorkshopSuccess())
          ),
          catchError((error: Error) =>
            concat(
              of(
                notificationActions.setToast({
                  severity: 'danger',
                  message: error.message,
                  title: 'Error: Failed To Create Workshop',
                })
              ),
              of(organizationActions.createWorkshopSuccess())
            )
          )
        )
      );
    })
  );

export const organizationEpic = [
  fetchMachineTypeEpic,
  fetchWorkshopEpic,
  fetchActivityTypeEpic,
  createMachineEpic,
  createActivityEpic,
  createCategoryEpic,
  createSectionEpic,
  createWorkshopEpic,
  fetchMaintenanceListEpic,
  fetchMachineListEpic,
  fetchOverviewEpic,
  deleteMachineEpic,
  fetchCategoryListEpic,
  resetCategoryEpic,
];
