import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

import { RootState } from '../redux/rootReducer';
import { notificationActions } from '../redux/notification.slice';

const TOAST_TIME_TO_LIVE = 3000;
const MAX_SHOWING_TOAST_COUNT = 10;

const GlobalToast = () => {
  const toasts = useSelector((state: RootState) =>
    Object.values(state.notification.toasts)
  );
  const dispatch = useDispatch();
  const dismissedToasts = useRef<string[]>([]);

  useEffect(() => {
    const newToasts = toasts
      .map((t) => t.id)
      .filter((id) => !dismissedToasts.current.includes(id));
    newToasts.forEach((tId) => {
      setTimeout(() => {
        dispatch(notificationActions.dismissToast(tId));
        const index = dismissedToasts.current.indexOf(tId);
        dismissedToasts.current.splice(index, 1);
      }, TOAST_TIME_TO_LIVE);
      dismissedToasts.current.push(tId);
    });
  }, [toasts]);

  return (
    <div className="toast-container">
      {toasts.slice(0, MAX_SHOWING_TOAST_COUNT).map((toast) => (
        <Toast key={toast.id}>
          <ToastHeader icon={toast.severity}>{toast.title}</ToastHeader>
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      ))}
    </div>
  );
};

export default GlobalToast;
