import React from 'react';
import styled from 'styled-components';

interface AppButtonProps {
  type?: 'button' | 'submit';
  text?: string;
  loading?: boolean;
  onClick?: () => void;
  link?: boolean;
  sm?: boolean;
}

interface StyledButtonProps {
  sm?: boolean;
}

const Button = styled.button<StyledButtonProps>`
  width: ${(props) => (props.sm ? 'auto' : '100%')};
  padding: ${(props) => (props.sm ? '.25rem .75rem' : '.5rem')};
  border-radius: 0.5rem;
  border: none;
  color: #ffffff;
  margin-top: ${(props) => (props.sm ? '0' : '10px')};
  font-size: ${(props) => (props.sm ? '.9rem' : '1rem')};
`;

const LinkButton = styled.button`
  font-size: 1rem;
  color: gray;
  border: none;
  background: none;
`;

const AppButton = (props: AppButtonProps): JSX.Element => {
  const { type, text, loading, onClick, link, sm } = props;

  return link ? (
    <LinkButton disabled={loading} onClick={onClick} type={type}>
      {' '}
      {text && text}
    </LinkButton>
  ) : (
    <Button
      className="bg-primary"
      disabled={loading}
      onClick={onClick}
      sm={sm}
      type={type}
    >
      {text && text}
    </Button>
  );
};

AppButton.defaultProps = {
  text: '',
  type: 'button',
  loading: false,
  onClick: null,
  link: false,
  sm: false,
};

export default AppButton;
