import React, { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import storage from '../../services/storage/localStorage';
import storageConstants from '../../constants/storage';
import AppRoutes, { NOT_FOUND, OrizelRoute } from '../routes';
import {
  Loader,
  ProtectedRoute,
  UnauthenticatedRoute,
} from '../../shared/components';
import SideBar from '../components/SideBar/SideBar';
import { getAuth, getUserRole } from '../../modules/auth/redux/authSelector';
import { AuthenticationResponse } from '../../models/auth';
import TopBar from '../components/TopBar/TopBar';
import { authActions } from '../../modules/auth/redux/authSlice';
import { getAppLoading } from '../redux/appSelector';
import GlobalNavigation from '../components/navigation/GlobalNavigation';

import styles from './App.module.scss';
import { appAction } from '../redux/appSlice';
import GlobalToast from '../components/GlobalToast';
import { selectedCategorySelector } from '../../modules/dashboard/redux/organizationSelector';

const AppContainer: React.FC = () => {
  const dispatch = useDispatch();

  const auth = useSelector(getAuth);
  const loading = useSelector(getAppLoading);
  const userRole = useSelector(getUserRole);
  const selectedCategory = useSelector(selectedCategorySelector);

  const [isCollapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!isCollapsed);
  };

  useEffect(() => {
    //  eslint-disable-next-line
    const authFromStorage: AuthenticationResponse | null = storage.get(
      storageConstants.AUTH_RESPONSE
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const selectedCategoryId: string = storage.get(
      storageConstants.SELECTED_CATEGORY
    );

    if (authFromStorage) {
      dispatch(
        authActions.authenticateUserSuccess({
          ...authFromStorage,
          selectedCategoryId,
        })
      );
    }
    dispatch(appAction.loadingSuccess());
  }, []);

  const renderContainer = () => (
    <BrowserRouter>
      <GlobalNavigation />
      <GlobalToast />
      <div className={styles.main_root}>
        <div className="container-fluid pl-0 pr-0">
          {auth && (
            <TopBar
              isCollapsed={isCollapsed}
              toggleCollapsed={toggleCollapsed}
            />
          )}
        </div>
        <div className={styles.subRoot}>
          {auth && selectedCategory && (
            <SideBar
              userRole={userRole}
              subscription={auth.subscription}
              isCollapsed={isCollapsed}
            />
          )}
          <div className={styles.route__container}>
            <Switch>
              {AppRoutes(userRole).map(
                ({
                  authenticated,
                  exact,
                  component: Component,
                  path,
                  common,
                }: OrizelRoute) =>
                  common ? (
                    <Route
                      key={path}
                      path={path}
                      exact={exact}
                      component={Component}
                    />
                  ) : (
                    <Route key={path} exact={exact} path={path}>
                      {authenticated ? (
                        <ProtectedRoute component={Component} />
                      ) : (
                        <UnauthenticatedRoute component={Component} />
                      )}
                    </Route>
                  )
              )}
              {/* TODO: Cant give sub url directly */}
              {/* <Redirect from="*" to={NOT_FOUND} /> */}
            </Switch>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );

  return loading ? <Loader /> : <>{renderContainer()}</>;
};

export default AppContainer;
