import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../app/redux/rootReducer';
import { User } from '../../../../models/user';
import { getAuth } from '../../../auth/redux/authSelector';
import UserView from '../../../user/page/UserView';

interface Props {
  user: User | null;
}

const Profile: React.FC<Props> = ({ user }: Props) => (
  <div className="container">
    <div className="row">
      <div className="col-lg-12 header-txt"></div>
      {user && <UserView user={user} />}
    </div>
  </div>
);

const mapStateToProps = (state: RootState) => ({
  user: getAuth(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
