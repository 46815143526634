import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { UpdatePasswordViewModel } from '../../../models/user';
import { RootState } from '../../../app/redux/rootReducer';
import { getId } from '../../auth/redux/authSelector';
import { updatePassword } from '../../../services/api/userApi';
import { notificationActions } from '../../../app/redux/notification.slice';

interface Props {
  userId?: string;
  onSubmitClick?: () => void;
}

const initialValues = {
  oldPassword: '',
  newPassword: '',
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('This is a required field'),
  newPassword: Yup.string().required('This is a required field'),
});

const UpdatePasswordForm: React.FC<Props> = ({
  userId,
  onSubmitClick,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!userId) {
      dispatch(
        notificationActions.setToast({
          severity: 'danger',
          message: 'Not a valid user',
          title: 'Error: Invalid User',
        })
      );
    }
  }, []);

  const onSubmitHandler = (values: UpdatePasswordViewModel) => {
    onSubmitClick && onSubmitClick();
    setIsLoading(true);
    userId &&
      updatePassword(userId, values).subscribe(
        () => {
          history.push('/');
          dispatch(
            notificationActions.setToast({
              severity: 'success',
              message: 'Successfully updated password',
              title: 'Success',
            })
          );
        },
        (error: Error) => {
          setIsLoading(false);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: 'Error: Failed To Update Password',
            })
          );
        }
      );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <AppInput
          name="oldPassword"
          label="Old Password*"
          placeholder="Old Password*"
          type="password"
          loading={isLoading}
        />
        <AppInput
          name="newPassword"
          label="New Password*"
          placeholder="New Password*"
          type="password"
          loading={isLoading}
        />
        <AppButton type="submit" text="Update" loading={!userId || isLoading} />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  userId: getId(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
