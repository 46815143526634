import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import { CreateCompany } from '../../../models/company';
import {
  AppButton,
  AppDropdown,
  AppInput,
} from '../../../shared/forms/components';
import { createCompany } from '../../../services/api/companyApi';
import { appAction } from '../../../app/redux/appSlice';
import { notificationActions } from '../../../app/redux/notification.slice';
import { RootState } from '../../../app/redux/rootReducer';
import { getCompanyTypeList } from '../../../app/redux/appSelector';
import { CompanyType } from '../../../models/companyType';

const getInitialValues = (companyTypes: Array<CompanyType>) => ({
  companyTypeId: companyTypes.length > 0 ? companyTypes[0].companyTypeId : '',
  name: '',
  adminEmail: '',
});

const validationSchema = Yup.object().shape({
  companyTypeId: Yup.string().required(),
  name: Yup.string().required(),
  adminEmail: Yup.string().email().required(),
});

interface Props {
  companyTypes: Array<CompanyType>;
  onSubmitCallBack?: () => void;
}

const CreateCompanyForm: React.FC<Props> = ({
  companyTypes,
  onSubmitCallBack,
}: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (values: CreateCompany) => {
    setIsLoading(true);
    createCompany(values).subscribe(
      (response) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message:
              'Successfully created company and invitation was sent to the admin',
            title: 'Success',
          })
        );
        dispatch(appAction.fetchCompanyList({}));
        onSubmitCallBack && onSubmitCallBack();
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Create Company',
          })
        );
        setIsLoading(false);
      }
    );
  };

  const getCompanyTypesOptions = () =>
    companyTypes.map((companyType) => (
      <option key={companyType.companyTypeId} value={companyType.companyTypeId}>
        {companyType.companyName}
      </option>
    ));

  return (
    <Formik
      initialValues={getInitialValues(companyTypes)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <AppInput
          name="name"
          label="Company Name*"
          placeholder="Enter Company Name"
          type="text"
          loading={isLoading}
        />
        <AppInput
          name="adminEmail"
          label="Admin Email*"
          placeholder="Enter Admin Email"
          type="email"
          loading={isLoading}
        />
        <AppDropdown
          name="companyTypeId"
          label="Company Type*"
          placeholder="Enter Company Type"
          loading={isLoading}
        >
          {getCompanyTypesOptions()}
        </AppDropdown>
        <AppButton type="submit" text="Create" loading={isLoading} />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyTypes: getCompanyTypeList(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompanyForm);
