/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavigationState {
  pathname?: string;
  queryParams?: { [key: string]: string | number | boolean };
}

const setNavigationDetails = (
  state: NavigationState,
  action: PayloadAction<NavigationState>
) => {
  const { pathname, queryParams } = action.payload;
  state.pathname = pathname;
  if (queryParams) {
    state.queryParams = { ...queryParams };
  }
};

const clearNavigationDetails = (state: NavigationState) => {
  state.pathname = undefined;
  state.queryParams = undefined;
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {} as NavigationState,
  reducers: {
    setNavigationDetails,
    clearNavigationDetails,
  },
});

export const navigationActions = {
  ...navigationSlice.actions,
};

export default navigationSlice.reducer;
