import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { MdViewHeadline } from 'react-icons/md';

import { getUser } from '../../../services/api/userApi';
import { User } from '../../../models/user';
import { AppModal, AppTable, Loader } from '../../../shared/components';
import UpdateUserForm from '../forms/UpdateUserForm';
import { RootState } from '../../../app/redux/rootReducer';
import { getUserRole } from '../../../app/redux/appSelector';
import { getUserRole as getLoggedUserRole } from '../../auth/redux/authSelector';
import { roleTypes } from '../../../constants/userRole';
import { Maintenance } from '../../../models/maintenance';
import {
  getActivityType,
  getMachineType,
} from '../../dashboard/redux/organizationSelector';
import { getMaintenances } from '../../../services/api/maintenanceApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import MaintenanceDetails from '../../maintenance/component/MaintenanceDetails';
import { CgMoreVerticalR } from 'react-icons/cg';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { MAINTENANCES } from '../../maintenance/routes';
import { DATE_FORMAT } from '../../../constants/dateTime';

import './UserView.css';

const maintenancesStateList = ['STARTED', 'DELAYED', 'NOT_DELAYED'];

interface Props {
  user?: User;
}

interface ParamsProps {
  id: string;
}

const UserViewPage: React.FC<Props> = ({ user }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMaintenance, setIsLoadingMaintenance] = useState(false);
  const { id } = useParams<ParamsProps>();
  const [userDetails, setUserDetails] = useState<User>();
  const state = useSelector((state: RootState) => state);
  const [showModal, setShowModal] = useState(false);
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [
    currentMaintenanceItem,
    setCurrentMaintenanceItem,
  ] = useState<Maintenance>();
  const [maintenanceList, setMaintenanceList] = useState<Array<Maintenance>>(
    []
  );

  const userRole = getLoggedUserRole(state);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const maintenanceModalToggle = () => {
    setShowMaintenanceModal(!showMaintenanceModal);
  };

  const maintenanceModalOpen = (maintenance: Maintenance) => {
    maintenanceModalToggle();
    setCurrentMaintenanceItem(maintenance);
  };

  const updateSuccessCallBack = (user: User) => {
    setUserDetails(user);
    handleModalToggle();
  };

  const onMoreClickHandler = () => {
    dispatch(
      organizationActions.fetchMaintenanceList({
        userId: id,
        dataRange: `${moment()
          .tz(moment.tz.guess())
          .add(-1, 'months')
          .format(DATE_FORMAT)},${moment()
          .tz(moment.tz.guess())
          .format(DATE_FORMAT)}`,
      })
    );
    history.push(MAINTENANCES);
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      getUser(id).subscribe(
        (response: User) => {
          setUserDetails(response);
          setIsLoading(false);
        },
        (error: Error) => {
          setIsLoading(false);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Fetch User Details`,
            })
          );
        }
      );
    } else if (user) {
      setUserDetails(user);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    document.title = 'User Details';

    if (id) {
      setIsLoadingMaintenance(true);
      getMaintenances({ userId: id, limit: 5 }).subscribe(
        (response) => {
          setMaintenanceList(response.items);
          setIsLoadingMaintenance(false);
        },
        (error: Error) => {
          setIsLoadingMaintenance(false);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Fetch Maintenances`,
            })
          );
        }
      );
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isDelayedMaintenance(dueDate: any, endDate: any) {
    const dueValue = moment(dueDate).tz(moment.tz.guess()); //todays date
    const startValue = moment(endDate).tz(moment.tz.guess()); // another date
    const duration = moment.duration(dueValue.diff(startValue));
    const days = duration.asDays();

    // eslint-disable-next-line no-negated-condition
    if (!isNaN(days)) {
      if (days >= 0) {
        return maintenancesStateList[2];
      } else {
        return maintenancesStateList[1];
      }
    } else {
      return maintenancesStateList[0];
    }
  }

  const getMaintenanceTableData = () => {
    const array: Array<JSX.Element> = [];
    maintenanceList.map((item, i) => {
      const activityTypeDetails = getActivityType(
        state,
        item.activity.activityTypeId
      )!;
      const machineTypeDetails = getMachineType(
        state,
        item.activity.machine.machineTypeId
      )!;

      const maintenancesState = isDelayedMaintenance(
        item.dueDate,
        item.startTime
      );

      array.push(
        <tr key={item.maintenanceId}>
          {maintenancesState == maintenancesStateList[0] && (
            <td>
              <Badge color="info">On Going</Badge>
            </td>
          )}
          {maintenancesState == maintenancesStateList[1] && (
            <td>
              <Badge color="danger">Delayed</Badge>
            </td>
          )}
          {maintenancesState == maintenancesStateList[2] && (
            <td>
              <Badge color="success">On Time</Badge>
            </td>
          )}
          <th scope="row">{1 + i}</th>
          <td>{activityTypeDetails?.name || ''}</td>
          <td>{item.activity.machine.machineName}</td>
          <td>{item.activity.machine.machineSubNumber}</td>
          <td>{machineTypeDetails ? machineTypeDetails.name : ''}</td>
          <td>
            <a
              onClick={() => maintenanceModalOpen(item)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} />
            </a>
          </td>
        </tr>
      );
    });
    return array;
  };
  return (
    <div className="container main-container-div">
      {isLoading ? (
        <Loader />
      ) : (
        userDetails && (
          <div className="table_outer_div table_user_view">
            <div className="container">
              <div className="row align-items-start">
                <div className="col header-txt">
                  <h5>
                    {userDetails.firstName} {userDetails.lastName}
                  </h5>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="profile-img-td">
                          <img src="/images/users/test.jpg" alt="" />
                        </td>
                        <td>
                          <Table borderless>
                            <tbody>
                              <tr>
                                <td>
                                  <b>First Name:</b>
                                </td>
                                <td>{userDetails.firstName}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Last Name:</b>
                                </td>
                                <td>{userDetails.lastName}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Email:</b>
                                </td>
                                <td>{userDetails.email}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Role:</b>
                                </td>
                                <td>
                                  {
                                    getUserRole(state, userDetails.roleId)
                                      ?.roleName
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Created Date:</b>
                                </td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>-</td>
                                <td>
                                  {userRole &&
                                    (userRole.roleName ===
                                      roleTypes.SUPER_ADMIN ||
                                      userRole.roleName ===
                                        roleTypes.ADMIN) && (
                                      <Button
                                        onClick={handleModalToggle}
                                        className="add-new-link-btn ft-size-20"
                                      >
                                        Edit
                                      </Button>
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <div className="row">
        <div className="col-lg-12">
          <div className="table_outer_div">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 header-txt">
                  <h5>MAINTENANCE LIST</h5>
                </div>
              </div>
            </div>
            {isLoadingMaintenance ? (
              <Loader />
            ) : (
              <AppTable
                head={['', '', 'Activity', 'Machine', 'Sub No', 'Type', '']}
                data={getMaintenanceTableData()}
                limit={5}
              />
            )}
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <a
                  onClick={onMoreClickHandler}
                  className="add-new-link-btn-thin-border ft-size-16 float-right"
                >
                  <CgMoreVerticalR size={18} /> More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userDetails && (
        <AppModal
          onClose={handleModalToggle}
          show={showModal}
          title="Edit User Details"
        >
          <UpdateUserForm
            user={userDetails}
            userUpdateSuccessCallBack={updateSuccessCallBack}
            isSelfUpdate={user ? true : false}
          />
        </AppModal>
      )}
      <AppModal
        onClose={() => maintenanceModalToggle()}
        show={showMaintenanceModal}
        title="Maintenance details"
        size="lg"
      >
        <MaintenanceDetails maintenanceDetailsObj={currentMaintenanceItem!} />
      </AppModal>
    </div>
  );
};

export default UserViewPage;
