import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

interface Props {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  inline?: boolean;
}

const AppCheckBox: React.FC<Props> = ({
  label,
  checked = false,
  onChange,
  inline = false,
}: Props) => {
  const [isChecked, setIsChecked] = useState(checked);

  const onChangeHandler = () => {
    setIsChecked(!isChecked);
    onChange && onChange(!isChecked);
  };

  return (
    <FormGroup check inline={inline}>
      <Label check>
        <Input
          type="checkbox"
          onChange={onChangeHandler}
          defaultChecked={checked}
          className="check-box-input"
        />{' '}
        {label}
      </Label>
    </FormGroup>
  );
};

export default AppCheckBox;
