import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { BsCircleFill } from 'react-icons/bs';
import { MdViewHeadline } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Input, Form } from 'reactstrap';
import { debounce } from 'lodash';

import { RootState } from '../../../../app/redux/rootReducer';
import {
  getMachineList,
  getMachineType,
  getWorkshop,
} from '../../../dashboard/redux/organizationSelector';
import { Machine } from '../../../../models/machine';
import { MachineType } from '../../../../models/machineType';
import { AppTable } from '../../../../shared/components';
import { organizationActions } from '../../../dashboard/redux/organizationSlice';
import { MachineState } from '../../../../models/machine';
import { Workshop } from '../../../../models/workshop';

interface Props {
  state: RootState;
  machines: Array<Machine>;
  fetchMachines: () => void;
  updateMachinePage: (pageNo: number) => void;
}

let machineTypeDetails: MachineType;
let workshopDetails: Workshop;

const MachineList: React.FC<Props> = ({
  state,
  machines,
  fetchMachines,
  updateMachinePage,
}: Props) => {
  const [machineList, setMachineList] = useState<Array<Machine>>([]);
  const { list, loading, error, currentPage } = useSelector(
    (state: RootState): MachineState => state.organization.machine
  );

  const filterMachines = (value: string) => {
    const searchKey = value.toLocaleLowerCase();
    const filteredMachines = machines.filter((machine) => {
      const machineType = getMachineType(state, machine.machineTypeId);
      if (
        machineType &&
        ((machineType.name &&
          machineType.name.toLocaleLowerCase().includes(searchKey)) ||
          (machineType.machineSection &&
            machineType.machineSection
              .toLocaleLowerCase()
              .includes(searchKey)) ||
          (machine.machineName &&
            machine.machineName.toLocaleLowerCase().includes(searchKey)))
      ) {
        return true;
      }
      return false;
    });
    setMachineList(filteredMachines);
  };

  useEffect(() => {
    if (list) {
      setMachineList(machines);
    } else if (!loading) {
      fetchMachines();
    }
  }, [loading]);

  const getTableData = () => {
    const array: Array<JSX.Element> = [];
    machineList.map((item, i = 1) => {
      machineTypeDetails = getMachineType(state, item.machineTypeId)!;

      if (item.workshopId)
        workshopDetails = getWorkshop(state, item.workshopId)!;

      array.push(
        <tr key={item.machineId}>
          <th scope="row">{i + 1}</th>
          {item.on && (
            <td>
              <BsCircleFill className="float-center machine-state-on" />
            </td>
          )}
          {!item.on && (
            <td>
              <BsCircleFill className="float-center machine-state-off" />
            </td>
          )}
          <td>{item.machineName}</td>
          <td>{machineTypeDetails?.name}</td>
          <td>{workshopDetails?.workshopName}</td>
          <td>{item.machineSubNumber}</td>
          <td>
            <Link
              to={'machines/'.concat(item.machineId)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} />
            </Link>
          </td>
        </tr>
      );
    });
    return array;
  };

  const handleOnPageChange = (page: number, from: number, to: number) => {
    updateMachinePage(page);
  };

  const handleOnChangeFilter = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.length >= 3) {
        filterMachines(value);
      }
    },
    1000
  );

  const handleClearFilter = () => {
    setMachineList(machines);
    (document.getElementById('input-field-search') as HTMLInputElement).value =
      '';
  };

  return (
    <div className="table_outer_div">
      <div className="container">
        <div className="row">
          <div className="col header-txt">
            <h5>MACHINE LIST</h5>
          </div>
          <div className="col">
            <Form className="form-outer-div form-outer-div-search">
              <Input
                id="input-field-search"
                placeholder="Search(Enter atleast 3 letters)"
                onChange={handleOnChangeFilter}
                type="text"
                className="input-field input-field-search"
              />
              <AiFillCloseCircle
                className="eye-icon-password"
                size={20}
                onClick={handleClearFilter}
              />
            </Form>
          </div>
        </div>
      </div>

      {!loading && !error && (
        <AppTable
          head={[
            '',
            '',
            'Machine Name',
            'Machine Type',
            'Workshop',
            'Sub Number',
            '',
          ]}
          data={getTableData()}
          limit={10}
          pagination={true}
          pageOnChange={handleOnPageChange}
          startingPage={currentPage}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  machines: getMachineList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMachines: () => {
    dispatch(organizationActions.fetchMachineList());
  },
  updateMachinePage: (pageNo: number) => {
    dispatch(organizationActions.updateMachineCurrentPage(pageNo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MachineList);
