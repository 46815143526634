import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import { CreateWorkshop, Workshop } from '../../models/workshop';

const CATEGORY_ENDPOINT = '/category/';
const SECTION_ENDPOINT = '/section/';
const WORKSHOP_ENDPOINT = '/workshop/';

export const getWorkshop = (
  categoryId: string,
  sectionId: string,
  workshopId: string
): Observable<Workshop> =>
  GET(
    `${CATEGORY_ENDPOINT}${categoryId}${SECTION_ENDPOINT}${sectionId}${WORKSHOP_ENDPOINT}`
  );

export const getWorkshops = (categoryId: string): Observable<Array<Workshop>> =>
  GET(`${CATEGORY_ENDPOINT}${categoryId}${WORKSHOP_ENDPOINT}`);

export const createWorkshop = (
  workshop: CreateWorkshop
): Observable<Workshop> =>
  POST(
    `${CATEGORY_ENDPOINT}${workshop.categoryId}${SECTION_ENDPOINT}${workshop.sectionId}${WORKSHOP_ENDPOINT}`,
    {
      ...workshop,
    }
  );

export const getWorkshopsBySection = (
  categoryId: string,
  sectionId: string
): Observable<Array<Workshop>> =>
  GET(
    `${CATEGORY_ENDPOINT}${categoryId}${SECTION_ENDPOINT}${sectionId}${WORKSHOP_ENDPOINT}`
  );

export const updateWorkshop = (
  categoryId: string,
  workshop: Workshop
): Observable<Workshop> =>
  PUT(
    `${CATEGORY_ENDPOINT}${categoryId}${WORKSHOP_ENDPOINT}${workshop.workshopId}`,
    {
      ...workshop,
    }
  );

export const deleteWorkshop = (
  categoryId: string,
  workshopId: string
): Observable<null> =>
  DELETE(`${CATEGORY_ENDPOINT}${categoryId}${WORKSHOP_ENDPOINT}${workshopId}`);
