import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import { RootState } from '../../../app/redux/rootReducer';
import { Machine } from '../../../models/machine';
import {
  AppButton,
  AppDropdown,
  AppInput,
} from '../../../shared/forms/components';
import { updateMachine } from '../../../services/api/machineApi';
import DualToggleSwitch from '../../../shared/forms/components/ToggleSwitch';
import { notificationActions } from '../../../app/redux/notification.slice';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { Workshop } from '../../../models/workshop';
import { getWorkshops } from '../../dashboard/redux/organizationSelector';

const getInitialValues = (machine: Machine) => ({
  ...machine,
});

const validationSchema = Yup.object().shape({
  machineName: Yup.string().required('This is a required field'),
  machineSubNumber: Yup.string().required('This is a required field'),
});

interface Props {
  machine: Machine;
  handleModalToggle?: () => void;
  workshops: Array<Workshop>;
  setMachine?: (machine: Machine) => void;
}

const EditMachineForm: React.FC<Props> = ({
  machine,
  handleModalToggle,
  setMachine,
  workshops,
}: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const workshopOptions = workshops.map((workshop) => (
    <option key={workshop.workshopId} value={workshop.workshopId}>
      {workshop.workshopName}
    </option>
  ));

  const onSubmitHandler = (values: Machine) => {
    setIsLoading(true);
    updateMachine(values).subscribe(
      (response: Machine) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully Updated Machine',
            title: 'Success',
          })
        );
        handleModalToggle && handleModalToggle();
        setMachine && setMachine(response);
        dispatch(organizationActions.fetchMachineList());
      },
      (err: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: err.message,
            title: 'Error: Failed To Update Machine',
          })
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues(machine)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <AppInput
            name="machineName"
            label="Machine Name*"
            placeholder="Enter Machine Name"
            type="text"
            loading={isLoading}
          />
          <AppInput
            name="machineSubNumber"
            label="Sub No*"
            placeholder="Enter Sub No"
            type="text"
            loading={isLoading}
          />
          <AppDropdown
            name={`workshopId`}
            label="Workshop Name"
            placeholder="Workshop"
            loading={isLoading}
          >
            <option defaultChecked value="">
              Not Selected
            </option>
            {workshopOptions}
          </AppDropdown>
          <DualToggleSwitch
            leftLabel="Off"
            rightLabel="On"
            name="on"
            handleToggle={setFieldValue}
            isOn={values.on}
          />
          <AppButton type="submit" text="Update" loading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  workshops: getWorkshops(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditMachineForm);
