import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { resetPasswordApply } from '../../../services/api/userApi';
import { LOGIN } from '../routes/index';
import { notificationActions } from '../../../app/redux/notification.slice';
import { ResetPasswordApplyRequest } from '../../../models/auth';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
});

const ResetPasswordApplyForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmitHandler = (values: ResetPasswordApplyRequest) => {
    setIsLoading(true);
    resetPasswordApply(values.email).subscribe(
      () => {
        history.push(LOGIN);
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Check Your Email Inbox',
            title: 'Success',
          })
        );
      },
      (error: Error) => {
        setIsLoading(false);
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Send Email',
          })
        );
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form className="p-4 my-2">
        <div className="d-flex justify-content-center mb-5 text-center" />
        <div className="text-center mt-3">
          <img
            className="logo-login"
            src="images/orizel_logo/orizel_logo.jpg"
            alt="logo"
          />
        </div>
        <h2 className="title-tag-style my-2 text-center mb-4">
          RESET PASSWORD
        </h2>
        <AppInput
          name="email"
          label="Email*"
          placeholder="Email*"
          type="email"
          loading={isLoading}
        />
        <AppButton type="submit" text="Submit" loading={isLoading} />
      </Form>
    </Formik>
  );
};

export default ResetPasswordApplyForm;
