import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { BsPlayFill } from 'react-icons/bs';

import { getId, getUserRole } from '../../auth/redux/authSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { Activity } from '../../../models/activity';
import { createMaintenance } from '../../../services/api/maintenanceApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { UserRole } from '../../../models/userRole';
import { roleTypes } from '../../../constants/userRole';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { getMachineType } from '../../dashboard/redux/organizationSelector';
import { MachineType } from '../../../models/machineType';

interface Props {
  maintenanceDetailsObj: Activity;
  currentActivityName: string;
  currentMachineTypeName: string;
  userId?: string;
  modalToggle?: () => void;
  userRole: UserRole | null;
  getMachineTypeDetails: (machineTypeId: string) => MachineType | undefined;
}

const DelayedMaintenanceView: React.FC<Props> = ({
  maintenanceDetailsObj,
  currentActivityName,
  currentMachineTypeName,
  userId,
  modalToggle,
  getMachineTypeDetails,
  userRole,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleStartActivity = () => {
    setIsLoading(true);
    if (userId) {
      createMaintenance({
        activityId: maintenanceDetailsObj.activityId,
        userId,
        startTime: moment().tz(moment.tz.guess()).format(),
      }).subscribe(
        (response) => {
          dispatch(
            notificationActions.setToast({
              severity: 'success',
              message:
                'Successfully started activity. Check ongoing maintenances for more details',
              title: 'Success',
            })
          );
          setIsLoading(false);
          modalToggle && modalToggle();
          dispatch(organizationActions.fetchOverview());
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: 'Error: Failed To Start Maintenance',
            })
          );
        }
      );
    } else {
      dispatch(
        notificationActions.setToast({
          severity: 'danger',
          message: `Couldn't find responsible person`,
          title: 'Error: Failed To Start Maintenance',
        })
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="table_outer_div table_outer_div-border-none">
            <div className="container">
              <Table BorderLess>
                <tbody>
                  <tr>
                    <td>
                      <b>Machine Name</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.machine === null
                        ? '-'
                        : maintenanceDetailsObj.machine.machineName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Machine Type</b>
                    </td>
                    <td>
                      {currentMachineTypeName === null
                        ? '-'
                        : currentMachineTypeName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Section</b>
                    </td>
                    <td>
                      {getMachineTypeDetails(
                        maintenanceDetailsObj.machine.machineTypeId
                      )?.machineSection || ''}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Sub Number</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.machine === null
                        ? '-'
                        : maintenanceDetailsObj.machine.machineSubNumber}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Activity</b>
                    </td>
                    <td>{currentActivityName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Due Date</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj === null
                        ? '-'
                        : moment(maintenanceDetailsObj.nextDueDate)
                            .tz(moment.tz.guess())
                            .format('YYYY-MM-DD')}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      {userRole &&
        (userRole.roleName === roleTypes.ADMIN ||
          userRole.roleName === roleTypes.MAINTENANCE) && (
          <div className="row">
            <div className="col">
              <a
                onClick={handleStartActivity}
                className={
                  isLoading
                    ? 'a-tag-disable add-new-link-btn ft-size-16 float-right'
                    : 'add-new-link-btn ft-size-16 float-right'
                }
              >
                <BsPlayFill size={18} /> Start Maintenance
              </a>
            </div>
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userId: getId(state),
  userRole: getUserRole(state),
  getMachineTypeDetails: (machineTypeId: string) =>
    getMachineType(state, machineTypeId),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DelayedMaintenanceView);
