import { ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { Input, Row, Col } from 'reactstrap';
import styled from 'styled-components';

import { FREQUENCIES } from '../../../../constants/app';
import { getCustomFrequencyValue } from '../../../../utils/organizationUtils';

import './AppInput.css';

interface CustomTypeEnum {
  [key: string]: {
    name: string;
    value: number;
  };
}

const customTypeEnum: CustomTypeEnum = {
  DAY: {
    name: 'Days',
    value: 1,
  },
  WEEK: {
    name: 'Weeks',
    value: 7,
  },
  MONTH: {
    name: 'Months',
    value: 30,
  },
  YEAR: {
    name: 'Years',
    value: 365,
  },
};

interface Props {
  name: string;
  placeholder: string;
  label?: string;
  readOnly?: boolean;
  loading?: boolean;
  value?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const Label = styled.label`
  font-size: 1rem;
`;
const ErrorLabel = styled.span`
  fontsize: 0.8rem;
  color: red;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
`;

const AppFrequencyInput = (props: Props): JSX.Element => {
  const { placeholder, label, name, readOnly, setFieldValue, value } = props;

  const getFieldValue = (value: string) => {
    if (FREQUENCIES.includes(value)) {
      return value;
    } else {
      return 'custom';
    }
  };

  const getCustomType = (value?: string) => {
    if (value) {
      return getCustomFrequencyValue(value).type;
    }
    setFieldValue(name, '1');
    return 'DAY';
  };

  const [isCustom, setIsCustom] = useState(
    value && getFieldValue(value) === 'custom' ? true : false
  );
  const [customType, setCustomType] = useState(getCustomType(value));
  const [customInput, setCustomInput] = useState(
    value ? getCustomFrequencyValue(value).value : undefined
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === 'custom') {
      setIsCustom(true);
      setFieldValue(name, '');
    } else {
      setFieldValue(name, value);
      isCustom && setIsCustom(false);
    }
  };

  const handleCustomTypeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomType(e.target.value);
    setFieldValue(
      name,
      value
        ? String(customTypeEnum[e.target.value].value * Number(customInput))
        : undefined
    );
  };

  const handleCustomInputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(
      name,
      String(Number(e.target.value) * customTypeEnum[customType].value)
    );
    setCustomInput(e.target.value);
  };

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <div className="form-outer-div">
        <Input
          type="select"
          onChange={handleOnChange}
          defaultValue={value ? getFieldValue(value) : undefined}
          disabled={readOnly}
        >
          <option value={FREQUENCIES[0]}>Daily</option>
          <option value={FREQUENCIES[1]}>Weekly</option>
          <option value={FREQUENCIES[2]}>Monthly</option>
          <option value={FREQUENCIES[3]}>Quarterly</option>
          <option value={FREQUENCIES[4]}>Yearly</option>
          <option value="custom">Custom</option>
        </Input>
      </div>
      <div className="form-outer-div">
        {isCustom && (
          <Row>
            <Col md={6}>
              <Input
                type="text"
                name="customWeek"
                defaultValue={customInput}
                placeholder={placeholder}
                onChange={handleCustomInputOnChange}
                readOnly={readOnly}
              />
            </Col>
            <Col md={6}>
              <Input
                type="select"
                onChange={handleCustomTypeOnChange}
                defaultValue={customType}
                disabled={readOnly}
              >
                {Object.keys(customTypeEnum).map((key) => (
                  <option key={key} value={key}>
                    {customTypeEnum[key]['name']}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        )}
      </div>

      <ErrorMessage name={name}>
        {(error: string) => <ErrorLabel>{error}</ErrorLabel>}
      </ErrorMessage>
    </Container>
  );
};

AppFrequencyInput.defaultProps = {
  label: '',
  loading: false,
  readOnly: false,
  value: '1',
};

export default AppFrequencyInput;
