import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineHome, AiFillSetting } from 'react-icons/ai';
import { ImUsers } from 'react-icons/im';
import { BsGear } from 'react-icons/bs';
import { MdNotificationsActive } from 'react-icons/md';
import { GiGears } from 'react-icons/gi';
import { useLocation } from 'react-router-dom';
import { RiBuilding3Fill } from 'react-icons/ri';
import moment from 'moment-timezone';

import { DASHBOARD } from '../../../modules/dashboard/routes';
import SideBarItem from '../SideBarItem/SideBarItem';
import { UserRole } from '../../../models/userRole';
import { roleTypes } from '../../../constants/userRole';
import { COMPANIES } from '../../../modules/company/routes';
import { MACHINES } from '../../../modules/machine/routes';
import { USERS } from '../../../modules/user/routes';
import { MAINTENANCES } from '../../../modules/maintenance/routes';
import { OVERVIEW } from '../../../modules/overview/routes';
import { Subscription } from '../../../models/subscription';
import { SETTINGS_CATEGORY } from '../../../modules/settings/routes';

import './sideBar.css';

interface SideBarRoute {
  title: string;
  route: string;
  Icon: IconType;
}

const superAdminRoutes: SideBarRoute[] = [
  {
    title: 'Home',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'Companies',
    route: COMPANIES,
    Icon: RiBuilding3Fill,
  },
  {
    title: 'Users',
    route: USERS,
    Icon: ImUsers,
  },
];

const adminRoutes: Array<SideBarRoute> = [
  {
    title: 'Home',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'Overview',
    route: OVERVIEW,
    Icon: MdNotificationsActive,
  },
  {
    title: 'Machines',
    route: MACHINES,
    Icon: BsGear,
  },
  {
    title: 'Users',
    route: USERS,
    Icon: ImUsers,
  },
  {
    title: 'Maintenances',
    route: MAINTENANCES,
    Icon: GiGears,
  },
  {
    title: 'Settings',
    route: SETTINGS_CATEGORY,
    Icon: AiFillSetting,
  },
];

const maintenanceRoutes: SideBarRoute[] = [
  {
    title: 'Home',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'Overview',
    route: OVERVIEW,
    Icon: MdNotificationsActive,
  },
  {
    title: 'Maintenances',
    route: MAINTENANCES,
    Icon: GiGears,
  },
  {
    title: 'Machines',
    route: MACHINES,
    Icon: BsGear,
  },
  {
    title: 'Users',
    route: USERS,
    Icon: ImUsers,
  },
];

const viewerRoute: SideBarRoute[] = [
  {
    title: 'Home',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'Overview',
    route: OVERVIEW,
    Icon: MdNotificationsActive,
  },
  {
    title: 'Maintenances',
    route: MAINTENANCES,
    Icon: GiGears,
  },
  {
    title: 'Machines',
    route: MACHINES,
    Icon: BsGear,
  },
  {
    title: 'Users',
    route: USERS,
    Icon: ImUsers,
  },
];

interface SideBarProps {
  isCollapsed: boolean;
  userRole: UserRole | null;
  subscription?: Subscription;
}

const SideBar: React.FC<SideBarProps> = ({
  isCollapsed,
  userRole,
  subscription,
}: SideBarProps): JSX.Element => {
  // const [isCollapsed, setCollapsed] = useState(true);

  let routes: Array<SideBarRoute> = [];
  if (userRole) {
    switch (userRole.roleName) {
      case roleTypes.ADMIN:
        routes = [...adminRoutes];
        break;
      case roleTypes.SUPER_ADMIN:
        routes = [...superAdminRoutes];
        break;
      case roleTypes.MAINTENANCE:
        routes = [...maintenanceRoutes];
        break;
      case roleTypes.VIEWERS:
        routes = [...viewerRoute];
        break;
      default:
        routes = [];
    }
  }
  const location = useLocation();

  // function ClickCollapseBtn() {
  //   const vwG = Math.max(
  //     document.documentElement.clientWidth || 0,
  //     window.innerWidth || 0
  //   );

  //   // if (true) {
  //   const navBarDivElement = document.getElementById('side-bar-left')!;

  //   if (isCollapsed) {
  //     navBarDivElement.style.width = '220px';
  //   } else {
  //     navBarDivElement.style.width = '0px';
  //   }
  //   // }

  //   setCollapsed(!isCollapsed);
  // }

  // useEffect(() => {
  //   const vwG = Math.max(
  //     document.documentElement.clientWidth || 0,
  //     window.innerWidth || 0
  //   );

  //   if (vwG > 960) {
  //     const navBarDivElement = document.getElementById('side-bar-left')!;

  //     setTimeout(function () {
  //       navBarDivElement.style.width = '220px';
  //       setCollapsed(!isCollapsed);
  //       console.log(isCollapsed);
  //     }, 3000);
  //     console.log('tes');
  //   }
  // }, []);

  return (
    <div className="main">
      <div id="side-bar-left" className="sidebar">
        <ul>
          {routes.map((route) => (
            <SideBarItem
              key={route.route}
              route={route}
              currentPath={location.pathname}
            />
          ))}
        </ul>
        {!isCollapsed && subscription && (
          <div className="subscription-plan">
            <div>subscribed {subscription.name}</div>
            <div>
              Expired On:{' '}
              {moment(subscription.subscriptionTo)
                .tz(moment.tz.guess())
                .format('YYYY-MM-DD')}
            </div>
          </div>
        )}
      </div>
      {/* <a className="collapse-btn-nav-bar" onClick={() => ClickCollapseBtn()}>
        {isCollapsed && <BiExpand />}
        {!isCollapsed && <BiCollapse />}
      </a> */}
    </div>
  );
};

export default SideBar;
