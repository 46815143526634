import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BiAddToQueue } from 'react-icons/bi';

import { AppModal, Loader } from '../../../shared/components';
import { RootState } from '../../../app/redux/rootReducer';
import { isAdmin as checkIsAdmin } from '../../auth/redux/authSelector';
import CategoryList from '../../category/components/CategoryList/CategoryList';
import CreateCategoryForm from '../../category/forms/CreateCategoryForm';

interface ParamsProps {
  id: string;
}
const CategoryListViewPage: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const state = useSelector((state: RootState) => state);
  const isAdmin = checkIsAdmin(state);

  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          {isAdmin && (
            <>
              <button
                onClick={handleModalToggle}
                className="add-new-link-btn ft-size-16"
              >
                <BiAddToQueue size={18} /> Add a Category
              </button>
              <AppModal
                onClose={handleModalToggle}
                show={showModal}
                title="Add a Category"
              >
                <CreateCategoryForm onSubmit={handleModalToggle} />
              </AppModal>
            </>
          )}
        </div>
      </div>
      <CategoryList />
    </div>
  );
};

export default CategoryListViewPage;
