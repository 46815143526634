import AdminMachineDetails from '../../dashboard/container/Admin/AdminMachineDetails';
import MachinesFirstTimeConfigForm from '../forms/MachinesFirstTimeConfigForm';
import MachineViewPage from '../page/MachineView';

export const MACHINES = '/machines';
export const MACHINE = `${MACHINES}/:id`;
export const MACHINES_CONFIG = '/machines-config';

export default [
  {
    path: MACHINES,
    component: AdminMachineDetails,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: MACHINE,
    component: MachineViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: MACHINES_CONFIG,
    component: MachinesFirstTimeConfigForm,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
