import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../app/redux/rootReducer';
import { UserRole } from '../../../../models/userRole';
import { WorkInProgress } from '../../../../shared/components';
import { getUserRole } from '../../../auth/redux/authSelector';
import { roleTypes } from '../../../../constants/userRole';
import CompanyOverview from '../../../overview/components/CompanyOverview';
import AdminDashboard from '../../component/AdminDashboard';
import MaintenanceDashboard from '../../component/MaintenanceDashboard';

interface Props {
  userRole: UserRole | null;
}

const Dashboard: React.FC<Props> = ({ userRole }: Props) => {
  useEffect(() => {
    document.title = 'Home';
  }, []);

  const getUserDashboard = () => {
    if (userRole) {
      switch (userRole.roleName) {
        case roleTypes.VIEWERS:
          return <CompanyOverview />;
        case roleTypes.ADMIN:
          return <AdminDashboard />;
        case roleTypes.MAINTENANCE:
          return <MaintenanceDashboard />;
        default:
          return <WorkInProgress />;
      }
    } else {
      return <WorkInProgress />;
    }
  };

  return (
    <div>
      <div className="container">
        {/* <div className="row align-items-start">
          <div className="col header-txt">
            <h3>Dashboard</h3>
          </div>
        </div> */}
      </div>
      <div>{getUserDashboard()}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userRole: getUserRole(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
