import { object, string, SchemaOf } from 'yup';

export interface LoginModel {
  email: string;
  password: string;
}

export type LoginValidationSchema = SchemaOf<LoginModel>;

export const getInitialValues = (): LoginModel => ({
  email: '',
  password: '',
});

export const getValidationSchema = (): LoginValidationSchema =>
  object().shape({
    email: string().required('Email is required').email('Email is invalid'),
    password: string().required('Password is required'),
    // .min(8, 'Password must contain at least 8 characters'),
  });
