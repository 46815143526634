import React from 'react';
import { Table } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { RootState } from '../../../app/redux/rootReducer';
import { Maintenance } from '../../../models/maintenance';
import { getMachineType } from '../../dashboard/redux/organizationSelector';
import { getActivityType } from '../../dashboard/redux/organizationSelector';

interface MaintenanceViewProps {
  maintenanceDetailsObj: Maintenance;
}

const MaintenanceDetails: React.FC<MaintenanceViewProps> = ({
  maintenanceDetailsObj,
}) => {
  const state = useSelector((state: RootState) => state);
  const machineType = getMachineType(
    state,
    maintenanceDetailsObj.activity.machine.machineTypeId
  );
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="table_outer_div table_outer_div-border-none">
            <div className="container">
              <Table BorderLess>
                <tbody>
                  <tr>
                    <td>
                      <b>Machine Name</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.activity.machine.machineName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Machine Type</b>
                    </td>
                    <td>{machineType ? machineType.name : ''}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Section</b>
                    </td>
                    <td>{machineType ? machineType.machineSection : ''}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Sub Number</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.activity.machine.machineSubNumber}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Activity</b>
                    </td>
                    <td>
                      {
                        getActivityType(
                          state,
                          maintenanceDetailsObj.activity.activityTypeId
                        )?.name
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Due Date</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.dueDate &&
                        moment(maintenanceDetailsObj.dueDate)
                          .tz(moment.tz.guess())
                          .format('YYYY-MM-DD')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Start Time</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.startTime &&
                        moment(maintenanceDetailsObj.startTime)
                          .tz(moment.tz.guess())
                          .format('YYYY-MM-DD h:mm:ss a')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>End Time</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.endTime &&
                        moment(maintenanceDetailsObj.endTime)
                          .tz(moment.tz.guess())
                          .format('YYYY-MM-DD h:mm:ss a')}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="table_outer_div table_outer_div-border-none">
            <div className="container">
              <Table BorderLess>
                <tbody>
                  <tr>
                    <td>
                      <b>Replacement Note</b>
                    </td>
                    <td>{maintenanceDetailsObj.replacementNote || ''}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Reason</b>
                    </td>
                    <td>{maintenanceDetailsObj.reason}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Maintenance Cost (LKR)</b>
                    </td>
                    <td>{maintenanceDetailsObj?.maintenanceCost || ''}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Replacement Cost (LKR)</b>
                    </td>
                    <td>{maintenanceDetailsObj?.replacementCost || ''}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Responsible Person</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.user ? (
                        <Link
                          to={'/users/'.concat(
                            maintenanceDetailsObj.user.userId
                          )}
                        >
                          {maintenanceDetailsObj.user.firstName &&
                            maintenanceDetailsObj.user.lastName &&
                            maintenanceDetailsObj.user.firstName
                              ?.concat(' ')
                              .concat(maintenanceDetailsObj.user.lastName)}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Started By</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.startedByUser ? (
                        <Link
                          to={'/users/'.concat(
                            maintenanceDetailsObj.startedByUser.userId
                          )}
                        >
                          {maintenanceDetailsObj.startedByUser
                            ? `${
                                maintenanceDetailsObj.startedByUser.firstName ||
                                ''
                              } ${
                                maintenanceDetailsObj.startedByUser.lastName ||
                                ''
                              }`
                            : ''}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Ended By</b>
                    </td>
                    <td>
                      {maintenanceDetailsObj.endedByUser ? (
                        <Link
                          to={'/users/'.concat(
                            maintenanceDetailsObj.endedByUser.userId
                          )}
                        >
                          {maintenanceDetailsObj.endedByUser.firstName || ''}{' '}
                          {maintenanceDetailsObj.endedByUser.lastName || ''}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceDetails;
