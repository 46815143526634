import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FiEdit } from 'react-icons/fi';

import { AppModal } from '../../../../shared/components';
import { isAdmin } from '../../../auth/redux/authSelector';
import { RootState } from '../../../../app/redux/rootReducer';
import { Section } from '../../../../models/section';
import { getUser } from '../../../../app/redux/appSelector';
import UserList from '../../../settings/components/UserList/UserList';
import WorkshopList from '../../../workshop/components/WorkshopList/WorkshopList';

import './SectionView.css';
import { User } from '../../../../models/user';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import EditSectionForm from '../../forms/EditSectionForm';

interface Props {
  categoryId: string;
  sectionDetailsObj: Section;
  getUserDetails: (userId: string) => User | undefined;
}

const SectionView: React.FC<Props> = ({
  categoryId,
  sectionDetailsObj,
  getUserDetails,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [sectionObj, setSectionObj] = useState<Section>(sectionDetailsObj);

  const state = useSelector((state: RootState) => state);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    document.title = 'Section: '.concat(sectionDetailsObj.sectionName);
  }, []);

  const getUserAvatar = (userId: string) => {
    const user = getUserDetails(userId);
    return user ? (
      <Link to={'/users/'.concat(user.userId)}>
        <Avatar
          name={
            user.firstName &&
            user.lastName &&
            user.firstName.concat(' ').concat(user.lastName)
          }
          size="30"
          round="50%"
          color="#303f9f"
          fgColor="white"
        />
      </Link>
    ) : (
      ''
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="table_outer_div">
            <div className="container">
              <div className="row align-items-start">
                <div className="col header-txt">
                  <h5>Section Details</h5>
                </div>
              </div>
              <Table BorderLess>
                <tbody>
                  <tr>
                    <td>
                      <b>Section Name</b>
                    </td>
                    <td>{sectionObj?.sectionName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Number of Workshop</b>
                    </td>
                    <td>{sectionObj?.numOfWorkshops}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Responsible User</b>
                    </td>
                    <td>{getUserAvatar(sectionObj.responsibleUserId)}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Created Date:</b>
                    </td>
                    <td>
                      {/* {categoryObj.createdAt
                        ? moment(categoryObj.createdAt)
                            .tz(moment.tz.guess())
                            .format('YYYY-MM-DD')
                        : ''} */}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="row">
                <div className="col">
                  {isAdmin(state) && (
                    <a
                      onClick={handleModalToggle}
                      className="add-new-link-btn-thin-border ft-size-16 float-right"
                    >
                      <FiEdit size={18} /> Edit
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <WorkshopList
            categoryId={categoryId}
            sectionId={sectionObj.sectionId}
          />
          {/* <UserList categoryId="" sectionId={sectionObj.sectionId} /> */}
        </div>
      </div>
      {isAdmin(state) && (
        <>
          <AppModal
            onClose={handleModalToggle}
            show={showModal}
            title="Edit Details"
          >
            <EditSectionForm
              categoryId={categoryId}
              section={sectionObj}
              handleModalToggle={handleModalToggle}
              setSection={setSectionObj}
            />
          </AppModal>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  getUserDetails: (userId: string) => getUser(state, userId),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SectionView);
