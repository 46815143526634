import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { RiFileEditFill } from 'react-icons/ri';
import { BiAddToQueue } from 'react-icons/bi';
import { Table } from 'reactstrap';
import { debounce } from 'lodash';

import { notificationActions } from '../../../app/redux/notification.slice';
import { Subscription } from '../../../models/subscription';
import { getSubscriptions } from '../../../services/api/subscriptionApi';
import { AppModal, AppTable } from '../../../shared/components';
import CreateEditSubscriptionForm from '../../subscription/forms/CreateEditSubscriptionForm';
import { Company } from '../../../models/company';
import { getCompany, updateCompany } from '../../../services/api/companyApi';
import { RootState } from '../../../app/redux/rootReducer';
import { getCompanyType } from '../../../app/redux/appSelector';
import { CompanyType } from '../../../models/companyType';
import { DualToggleSwitch } from '../../../shared/forms/components/ToggleSwitch/DualToggleSwitch';

interface Props {
  getCompanyTypeDetails: (companyTypeId: string) => CompanyType | undefined;
}

interface ParamsProps {
  id: string;
}

const CompanyDetails: React.FC<Props> = ({ getCompanyTypeDetails }) => {
  const [subscriptionHistory, setSubscriptionHistory] = useState<
    Array<Subscription>
  >([]);
  const [
    selectedSubscription,
    setSelectedSubscription,
  ] = useState<Subscription>();
  const [
    currentSubscription,
    setCurrentSubscription,
  ] = useState<Subscription>();
  const [showModal, setShowModal] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [render, setRender] = useState(false);

  const { id } = useParams<ParamsProps>();
  const dispatch = useDispatch();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleOnClickAddSubscription = () => {
    setSelectedSubscription(undefined);
    toggleModal();
  };

  const handleModalOpen = (subscription: Subscription) => {
    setSelectedSubscription(subscription);
    toggleModal();
  };

  const handleOnSuccess = () => {
    toggleModal();
    setRender(!render);
  };

  const getSubscriptionTableData = () =>
    subscriptionHistory.map((subscription, index) => (
      <tr key={subscription.subscriptionId}>
        <th scope="row">{index + 1}</th>
        <td>{subscription.name}</td>
        <td>
          {moment(subscription.subscriptionFrom)
            .tz(moment.tz.guess())
            .format('YYYY-MM-DD')}
        </td>
        <td>
          {moment(subscription.subscriptionTo)
            .tz(moment.tz.guess())
            .format('YYYY-MM-DD')}
        </td>
        <td>{subscription?.comment}</td>
        <td>{subscription.valid ? 'Valid' : 'Not valid'}</td>
        <td>
          {subscription.valid ? (
            <a
              onClick={() => handleModalOpen(subscription)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <RiFileEditFill size={20} />
            </a>
          ) : null}
        </td>
      </tr>
    ));

  useEffect(() => {
    getCompany(id).subscribe(
      (response) => {
        setCompany(response);
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: `Error: Couldn't Fetch Company`,
          })
        );
      }
    );
  }, []);

  useEffect(() => {
    getSubscriptions().subscribe(
      (response) => {
        if (response.length > 0 && response[0].valid) {
          // setCurrentSubscription(response.pop());
        }
        setSubscriptionHistory(response);
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: `Error: Couldn't Fetch Maintenance`,
          })
        );
      }
    );
  }, [render]);

  const updateSubscription = () => {
    if (company) {
      updateCompany({
        ...company,
        checkSubscription: !company.checkSubscription,
      }).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {
          setCompany({
            ...company,
            checkSubscription: !company.checkSubscription,
          });
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't update subscription`,
            })
          );
        }
      );
    } else {
      dispatch(
        notificationActions.setToast({
          severity: 'danger',
          message: 'Not found valid company details',
          title: `Error: Couldn't update subscription`,
        })
      );
    }
  };

  return (
    <div>
      <div>
        <a
          onClick={handleOnClickAddSubscription}
          className="add-new-link-btn ft-size-16 float-right"
        >
          <BiAddToQueue size={18} /> Add Subscription
        </a>
      </div>
      <div className="table_outer_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 header-txt">
              <h5>Company Details</h5>
            </div>
          </div>
          <Table BorderLess>
            <tbody>
              <tr>
                <td>
                  <b>Name</b>
                </td>
                <td>{company?.name || ''}</td>
              </tr>
              <tr>
                <td>
                  <b>Type</b>
                </td>
                <td>
                  {company
                    ? getCompanyTypeDetails(company?.companyTypeId)?.companyName
                    : ''}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Created</b>
                </td>
                <td>
                  {company
                    ? moment(company?.createdAt)
                        .tz(moment.tz.guess())
                        .format('YYYY-MM-DD')
                    : ''}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Subscription</b>
                </td>
                <td>
                  <DualToggleSwitch
                    leftLabel="off"
                    rightLabel="on"
                    name="toggleSubscription"
                    isOn={company?.checkSubscription}
                    handleToggle={debounce(updateSubscription, 1000)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div>
        <div className="table_outer_div">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 header-txt">
                <h5>Subscription List</h5>
              </div>
            </div>
          </div>
          <AppTable
            head={['#', 'Name', 'From', 'To', 'Comment', 'Valid', '']}
            data={getSubscriptionTableData()}
            limit={10}
            pagination={false}
          />
        </div>
      </div>
      <AppModal onClose={toggleModal} show={showModal} title="Subscription">
        <CreateEditSubscriptionForm
          companyId={id}
          subscription={selectedSubscription}
          handleOnSuccess={handleOnSuccess}
        />
      </AppModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getCompanyTypeDetails: (companyTypeId: string) =>
    getCompanyType(state, companyTypeId),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
