/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastMessage, ToastsCollection } from '../../models/notification';
import miscUtils from '../../utils/miscUtils';

interface NotificationState {
  toasts: ToastsCollection;
}

const initialState: NotificationState = {
  toasts: {},
};

const setToast = (
  state: NotificationState,
  action: PayloadAction<ToastMessage>
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const id = miscUtils.generateUuid();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const toastData = { ...action.payload, id };

  state.toasts = { [id]: toastData, ...state.toasts };
};

const dismissToast = (
  state: NotificationState,
  action: PayloadAction<string>
) => {
  delete state.toasts[action.payload];
};

const dismissAllToasts = (state: NotificationState) => {
  state.toasts = {};
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setToast,
    dismissToast,
    dismissAllToasts,
  },
});

export const notificationActions = {
  ...notificationSlice.actions,
};

export default notificationSlice.reducer;
