import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { CreateNewActivity } from '../../../models/activity';
import AppDropdown from '../../../shared/forms/components/AppDropDown/AppDropDown.component';
import {
  AppButton,
  AppInput,
  AppFrequencyInput,
} from '../../../shared/forms/components';
import {
  getActivityTypes,
  getActivityType,
} from '../../dashboard/redux/organizationSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { getAuth } from '../../auth/redux/authSelector';
import { ActivityType } from '../../../models/activityType';
import { activityTypesGroup } from '../../../utils/groupUtils';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { getUserList } from '../../../app/redux/appSelector';
import { User } from '../../../models/user';
import { AppCheckBox } from '../../../shared/components';

const getInitialValues = (
  machineTypeId: string,
  machineId: string,
  companyId: string | undefined
) => ({
  activityTypeId: '',
  frequency: '7',
  name: '',
  machineTypeId,
  machineId,
  companyId: companyId || '',
  responsibleUserId: '',
  nextDueDate: moment().tz(moment.tz.guess()).format('YYYY-MM-DD'),
  on: true,
  createdAt: '',
});

const validationSchema = Yup.object().shape({
  activityTypeId: Yup.string().required(),
  name: Yup.string().when('activityTypeId', {
    is: 'new',
    then: Yup.string().required('This is a required field'),
  }),
  frequency: Yup.string().when('activityTypeId', {
    is: 'new',
    then: Yup.string().required('This is a required field'),
  }),
  responsibleUserId: Yup.string(),
  nextDueDate: Yup.date()
    .min(
      moment().tz(moment.tz.guess()).format('YYYY-MM-DD'),
      'Date cannot be in the past'
    )
    .required(),
});

interface Props {
  companyId: string | undefined;
  activityTypes: Array<ActivityType>;
  machineTypeId: string;
  state: RootState;
  machineId: string;
  users: Array<User>;
  createActivity: (activity: CreateNewActivity) => void;
  onSubmit?: () => void;
}

const CreateActivityForm: React.FC<Props> = ({
  machineTypeId,
  companyId,
  activityTypes,
  state,
  machineId,
  users,
  createActivity,
  onSubmit,
}: Props) => {
  const onSubmitHandler = (values: CreateNewActivity) => {
    onSubmit && onSubmit();
    createActivity(values);
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const groupActivityTypes = activityTypesGroup(activityTypes);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const sortedActivityTypes = groupActivityTypes[
    machineTypeId
  ] as Array<ActivityType>;

  const getUsersOptions = () =>
    users.map((user) => (
      <option key={user.userId} value={user.userId}>
        {user.email}
      </option>
    ));

  const getFormContent = (
    values: CreateNewActivity,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (values.activityTypeId === 'new') {
      return (
        <>
          <AppInput name="name" label="Name*" placeholder="Name*" type="text" />
          <AppFrequencyInput
            name="frequency"
            label="Frequency*"
            placeholder="Frequency"
            setFieldValue={setFieldValue}
            value={String(values.frequency)}
          />
        </>
      );
    }

    const activityType = getActivityType(state, values.activityTypeId);
    if (activityType) {
      // eslint-disable-next-line no-param-reassign
      values.frequency = activityType.frequency;
      return (
        <AppFrequencyInput
          name="frequency"
          label="Frequency"
          placeholder="Frequency"
          setFieldValue={setFieldValue}
          value={String(values.frequency)}
          readOnly
        />
      );
    }
    return null;
  };

  return (
    <Formik
      initialValues={getInitialValues(machineTypeId, machineId, companyId)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <AppDropdown
            name="activityTypeId"
            label="Activity*"
            placeholder="Enter Activity"
          >
            <option defaultChecked value="">
              --Please choose an option--
            </option>
            {sortedActivityTypes &&
              sortedActivityTypes.map((activityType) => (
                <option
                  key={activityType.activityTypeId}
                  value={activityType.activityTypeId}
                >
                  {activityType.name}
                </option>
              ))}
            <option key="new" value="new">
              New
            </option>
          </AppDropdown>
          {values && getFormContent(values, setFieldValue)}
          <AppInput
            name="nextDueDate"
            label="Next Due Date*"
            placeholder="Next Due Date"
            type="date"
          />
          <AppDropdown
            name="responsibleUserId"
            label="Responsible Person"
            placeholder="Responsible Person"
          >
            <option defaultChecked value="">
              Unassigned
            </option>
            {getUsersOptions()}
          </AppDropdown>
          <AppCheckBox
            label="Enable notifications for this activity"
            checked
            onChange={() => setFieldValue('on', values.on)}
          />
          <AppButton type="submit" text="Create" />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  activityTypes: getActivityTypes(state),
  companyId: getAuth(state)?.companyId,
  users: getUserList(state),
  state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createActivity: (activity: CreateNewActivity) => {
    dispatch(organizationActions.createActivity(activity));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateActivityForm);
