import { Field, ErrorMessage } from 'formik';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface AppDropdownProps {
  name: string;
  placeholder: string;
  label?: string;
  readOnly?: boolean;
  loading?: boolean;
  children?: ReactNode;
}

const Label = styled.label`
  font-size: 1rem;
`;
const Input = styled.input`
  padding: 5px 10px;
`;
const ErrorLabel = styled.span`
  fontsize: 0.8rem;
  color: red;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
`;

const AppDropdown = (props: AppDropdownProps): JSX.Element => {
  const { placeholder, label, name, loading, readOnly, children } = props;

  return (
    <Container>
      {label && <p className="input-box-top-name">{label}</p>}
      <Field
        as={Input}
        disabled={loading || readOnly}
        name={name}
        placeholder={placeholder}
        component="select"
        className="input-field"
      >
        {children}
      </Field>
      <ErrorMessage name={name}>
        {(error: string) => <ErrorLabel>{error}</ErrorLabel>}
      </ErrorMessage>
    </Container>
  );
};

AppDropdown.defaultProps = {
  label: '',
  loading: false,
  readOnly: false,
  children: <></>,
};

export default AppDropdown;
