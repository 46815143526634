import CompanyList from '../component/CompanyList';
import CompanyTypeList from '../component/CompanyTypeList';
import CompanyDetails from '../component/CompanyDetails';

export const COMPANIES = '/company';
export const COMPANY = `${COMPANIES}/:id`;
export const COMPANY_TYPES = '/company-types';

export default [
  {
    path: COMPANIES,
    component: CompanyList,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: COMPANY,
    component: CompanyDetails,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: COMPANY_TYPES,
    component: CompanyTypeList,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
