import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import Avatar from 'react-avatar';
import { CgMoreVerticalR } from 'react-icons/cg';
import { Link } from 'react-router-dom';

import { Section } from '../../../../models/section';
import { getSections } from '../../../../services/api/sectionApi';
import { notificationActions } from '../../../../app/redux/notification.slice';
import { RootState } from '../../../../app/redux/rootReducer';
import { Loader } from '../../../../shared/components';
import { getUser } from '../../../../app/redux/appSelector';
import { User } from '../../../../models/user';

interface Props {
  categoryId: string;
  isSectionLoading: boolean;
  getUserDetails: (userId: string) => User | undefined;
}

const SectionList: React.FC<Props> = ({
  categoryId,
  isSectionLoading,
  getUserDetails,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sectionList, setSectionList] = useState<Array<Section>>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    getSections(categoryId).subscribe(
      (response: Array<Section>) => {
        setSectionList(response);
        setIsLoading(false);
      },
      (err: Error) => {
        setIsLoading(false);
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: err.message,
            title: `Error: Couldn't Fetch Section`,
          })
        );
      }
    );
  }, [categoryId, dispatch, isSectionLoading]);

  const getUserAvatar = (userId: string) => {
    const user = getUserDetails(userId);
    return user ? (
      <Link to={'/users/'.concat(user.userId)}>
        <Avatar
          name={
            user.firstName &&
            user.lastName &&
            user.firstName.concat(' ').concat(user.lastName)
          }
          size="30"
          round="50%"
          color="#303f9f"
          fgColor="white"
        />
      </Link>
    ) : (
      ''
    );
  };

  return (
    <div className="table_outer_div">
      <div className="container">
        <div className="row align-items-start">
          <div className="col header-txt">
            <h5>Sections</h5>
          </div>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th># Workshops</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading || isSectionLoading ? (
            <Loader />
          ) : (
            <>
              {sectionList.map((item, index) => (
                <tr key={index}>
                  <td>{getUserAvatar(item.responsibleUserId)}</td>
                  <td>{item.sectionName}</td>
                  <td>-</td>
                  <td className="view-td">
                    <Link
                      to={`/settings/section/${categoryId}/${item.sectionId}`}
                      className="add-new-link-btn-thin-border ft-size-16"
                    >
                      <CgMoreVerticalR size={20} />
                    </Link>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  isSectionLoading: state.organization.sectionLoading,
  getUserDetails: (userId: string) => getUser(state, userId),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SectionList);
