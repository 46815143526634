import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect, useDispatch } from 'react-redux';

import { CreateUserForm as CreateUserFormViewModel } from '../../../models/user';
import { AppButton, AppInput } from '../../../shared/forms/components';
import { RootState } from '../../../app/redux/rootReducer';
import { getUserRole } from '../../auth/redux/authSelector';
import { createUser } from '../../../services/api/userApi';
import { notificationActions } from '../../../app/redux/notification.slice';

const initialValues = {
  email: '',
  roleId: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

interface Props {
  adminRoleId?: string;
  createUserSuccessCallBack?: () => void;
}

const CreateSuperAdminForm: React.FC<Props> = ({
  adminRoleId,
  createUserSuccessCallBack,
}: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (values: CreateUserFormViewModel) => {
    setIsLoading(true);
    createUser({ ...values, roleId: adminRoleId || '' }).subscribe(
      (response) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully created user',
            title: 'Success',
          })
        );
        createUserSuccessCallBack && createUserSuccessCallBack();
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Create User',
          })
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <AppInput
          name="email"
          label="Email*"
          placeholder="Email*"
          type="email"
          loading={isLoading}
        />
        <AppButton type="submit" text="Create" loading={isLoading} />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  adminRoleId: getUserRole(state)?.roleId,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSuperAdminForm);
