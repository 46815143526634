import React from 'react';
import { Table } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { BsFillStopFill } from 'react-icons/bs';

import { Maintenance } from '../../../models/maintenance';
import { RootState } from '../../../app/redux/rootReducer';
import { getUserRole } from '../../auth/redux/authSelector';
import { UserRole } from '../../../models/userRole';
import { roleTypes } from '../../../constants/userRole';
import { getMachineType } from '../../dashboard/redux/organizationSelector';
import { MachineType } from '../../../models/machineType';

interface Props {
  maintenanceDetailsObj: Maintenance;
  currentActivityName: string;
  currentMachineTypeName: string;
  userRole: UserRole | null;
  getMachineTypeDetails: (machineTypeId: string) => MachineType | undefined;
}

const OnGoingMaintenanceView: React.FC<Props> = ({
  maintenanceDetailsObj,
  currentActivityName,
  currentMachineTypeName,
  userRole,
  getMachineTypeDetails,
}) => {
  const history = useHistory();

  function endMaintenanceButtonClick(value: string) {
    history.push(`/maintenances/${value}`);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="table_outer_div table_outer_div-border-none">
            <div className="container">
              <Table BorderLess>
                {maintenanceDetailsObj ? (
                  <tbody>
                    <tr>
                      <td>
                        <b>Machine Name</b>
                      </td>
                      <td>
                        {maintenanceDetailsObj.activity.machine.machineName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Machine Type</b>
                      </td>
                      <td>{currentMachineTypeName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Section</b>
                      </td>
                      <td>
                        {getMachineTypeDetails(
                          maintenanceDetailsObj.activity.machine.machineTypeId
                        )?.machineSection || ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sub Number</b>
                      </td>
                      <td>
                        {
                          maintenanceDetailsObj.activity.machine
                            .machineSubNumber
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Activity</b>
                      </td>
                      <td>{currentActivityName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Due Date</b>
                      </td>
                      <td>
                        {moment(maintenanceDetailsObj.dueDate)
                          .tz(moment.tz.guess())
                          .format('YYYY-MM-DD')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Start Time</b>
                      </td>
                      <td>
                        {moment(maintenanceDetailsObj.startTime)
                          .tz(moment.tz.guess())
                          .format('YYYY-MM-DD h:mm a')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Reason</b>
                      </td>
                      <td>{maintenanceDetailsObj.reason}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Responsible</b>
                      </td>
                      <td>
                        <Link
                          to={'/users/'.concat(
                            maintenanceDetailsObj.user.userId
                          )}
                        >
                          {maintenanceDetailsObj.user.firstName &&
                            maintenanceDetailsObj.user.lastName &&
                            maintenanceDetailsObj.user.firstName
                              ?.concat(' ')
                              .concat(maintenanceDetailsObj.user.lastName)}
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  ''
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
      {userRole &&
        (userRole.roleName === roleTypes.ADMIN ||
          userRole.roleName === roleTypes.MAINTENANCE) && (
          <div className="row">
            <div className="col">
              <a
                onClick={() =>
                  endMaintenanceButtonClick(maintenanceDetailsObj.maintenanceId)
                }
                className="add-new-link-btn ft-size-16 float-right"
              >
                <BsFillStopFill size={18} /> End maintenance
              </a>
            </div>
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userRole: getUserRole(state),
  getMachineTypeDetails: (machineTypeId: string) =>
    getMachineType(state, machineTypeId),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnGoingMaintenanceView);
