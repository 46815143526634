import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Badge } from 'reactstrap';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { MdViewHeadline } from 'react-icons/md';
import { BsFillStopFill, BsPlayFill } from 'react-icons/bs';

import { Overview } from '../../../models/overview';
import { getActivityType } from '../../dashboard/redux/organizationSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { ActivityType } from '../../../models/activityType';
import { AppModal, AppTable } from '../../../shared/components';
import { Activity } from '../../../models/activity';
import DelayedMaintenanceView from '../components/DelayedMaintenanceView';
import { Maintenance } from '../../../models/maintenance';
import OnGoingMaintenanceView from '../components/OnGoingMaintenanceView';
import { MachineType } from '../../../models/machineType';
import { getMachineType } from '../../dashboard/redux/organizationSelector';
import { organizationActions } from '../../dashboard/redux/organizationSlice';

import './OverViewPage.css';
import 'react-accessible-accordion/dist/fancy-example.css';

let activityTypeDetails: ActivityType;
let machineTypeDetails: MachineType;

const OverViewPage: React.FC = () => {
  const [overViewList, setOverViewList] = useState<Overview>({
    delayedMaintenances: [],
    upcomingMaintenances: [],
    ongoingMaintenances: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state: RootState) => state);
  const [showModalDelayed, setShowModalDelayed] = useState(false);
  const [showModalOnGoing, setShowModalOnGoing] = useState(false);
  const [
    currentDelayedMaintenanceItem,
    setCurrentDelayedMaintenanceItem,
  ] = useState<Activity>();
  const [currentActivityName, setCurrentActivityName] = useState<string>();
  const [
    currentMachineTypeName,
    setCurrentMachineTypeName,
  ] = useState<string>();
  const [
    currentOnGoingMaintenanceItem,
    setCurrentOnGoingMaintenanceItem,
  ] = useState<Maintenance>();

  const overview = useSelector(
    (state: RootState) => state.organization.overview
  );
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Overview';

    setIsLoading(true);
    if (overview) {
      setOverViewList(overview);
      setIsLoading(false);
    } else {
      dispatch(organizationActions.fetchOverview());
    }
  }, [dispatch, overview]);

  function handleModalDelayedOpen(
    value: Activity,
    activityName: string,
    machineName: string
  ) {
    setShowModalDelayed(!showModalDelayed);
    setCurrentDelayedMaintenanceItem(value);
    setCurrentActivityName(activityName);
    setCurrentMachineTypeName(machineName);
  }

  function handleModalDelayedClose() {
    setShowModalDelayed(!showModalDelayed);
  }

  function handleModalOnGoingOpen(
    value: Maintenance,
    activityName: string,
    machineName: string
  ) {
    setShowModalOnGoing(!showModalOnGoing);
    setCurrentOnGoingMaintenanceItem(value);
    setCurrentActivityName(activityName);
    setCurrentMachineTypeName(machineName);
  }

  function handleModalOnGoingClose() {
    setShowModalOnGoing(!showModalOnGoing);
  }

  const getTableDelayedData = () => {
    const array: Array<JSX.Element> = [];
    overViewList?.delayedMaintenances.map((item, i = 1) => {
      activityTypeDetails = getActivityType(state, item.activityTypeId)!;
      machineTypeDetails = getMachineType(state, item.machine.machineTypeId)!;
      array.push(
        <tr key={i}>
          <th scope="row">{i + 1}</th>
          <td>{activityTypeDetails ? activityTypeDetails.name : ''}</td>
          <td>
            {item.machine.machineName === null ? '-' : item.machine.machineName}
          </td>
          <td>{machineTypeDetails ? machineTypeDetails.machineSection : ''}</td>
          <td>
            {item.machine.machineSubNumber === null
              ? '-'
              : item.machine.machineSubNumber}
          </td>
          <td>
            {item.nextDueDate === null
              ? '-'
              : moment(item.nextDueDate)
                  .tz(moment.tz.guess())
                  .format('YYYY-MM-DD')}
          </td>
          <td>
            <a
              onClick={() =>
                handleModalDelayedOpen(
                  item,
                  activityTypeDetails.name,
                  machineTypeDetails.name
                )
              }
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} /> / <BsPlayFill size={20} />
            </a>
          </td>
        </tr>
      );
    });
    return array;
  };

  const getTableOnGoingData = () => {
    const array: Array<JSX.Element> = [];
    overViewList?.ongoingMaintenances.map((item, i = 1) => {
      activityTypeDetails = getActivityType(
        state,
        item.activity.activityTypeId
      )!;
      machineTypeDetails = getMachineType(
        state,
        item.activity.machine.machineTypeId
      )!;
      array.push(
        <tr key={i}>
          <th scope="row">{i + 1}</th>
          <td>{activityTypeDetails ? activityTypeDetails.name : ''}</td>
          <td>
            {item.activity.machine === null
              ? '-'
              : item.activity.machine.machineName}
          </td>
          <td>{machineTypeDetails ? machineTypeDetails.machineSection : ''}</td>
          <td>
            {item.activity.machine === null
              ? '-'
              : item.activity.machine.machineSubNumber}
          </td>
          <td>
            {moment(item.startTime)
              .tz(moment.tz.guess())
              .format('YYYY-MM-DD h:mm a')}
          </td>
          <td>
            <a
              onClick={() =>
                handleModalOnGoingOpen(
                  item,
                  activityTypeDetails.name,
                  machineTypeDetails.name
                )
              }
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} /> / <BsFillStopFill size={20} />
            </a>
          </td>
        </tr>
      );
    });
    return array;
  };

  const getTableUpComingData = () => {
    const array: Array<JSX.Element> = [];
    overViewList?.upcomingMaintenances.map((item, i = 1) => {
      activityTypeDetails = getActivityType(state, item.activityTypeId)!;
      machineTypeDetails = getMachineType(state, item.machine.machineTypeId)!;
      array.push(
        <tr key={i}>
          <th scope="row">{i + 1}</th>
          <td>{activityTypeDetails ? activityTypeDetails.name : ''}</td>
          <td>
            {item.machine.machineName === null ? '-' : item.machine.machineName}
          </td>
          <td>{machineTypeDetails ? machineTypeDetails.machineSection : ''}</td>
          <td>
            {item.machine.machineSubNumber === null
              ? '-'
              : item.machine.machineSubNumber}
          </td>
          <td>
            {item.nextDueDate === null
              ? '-'
              : moment(item.nextDueDate)
                  .tz(moment.tz.guess())
                  .format('YYYY-MM-DD')}
          </td>
          <td>
            <a
              onClick={() =>
                handleModalDelayedOpen(
                  item,
                  activityTypeDetails.name,
                  machineTypeDetails.name
                )
              }
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} /> / <BsPlayFill size={20} />
            </a>
          </td>
        </tr>
      );
    });
    return array;
  };

  return (
    <div className="container main-container-div">
      <div className="row accordion-tab p-3">
        <Accordion preExpanded={['0']}>
          <AccordionItem uuid="0">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion-btn delayed-maintenance-btn">
                <h5>DELAYED MAINTENANCES</h5>
                <Badge className="maintenances-number" color="danger">
                  {overViewList?.delayedMaintenances.length}
                </Badge>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="delayed-maintenance-content">
              <div className="table_outer_div table_outer_div-border-none">
                {!isLoading && (
                  <AppTable
                    head={[
                      '',
                      'Activity',
                      'Machine Name',
                      'Section',
                      'Sub Number',
                      'Last Due Date',
                      '',
                    ]}
                    data={getTableDelayedData()}
                    limit={5}
                    pagination={true}
                  />
                )}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="1">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion-btn on-going-maintenance-btn">
                <h5>ON GOING MAINTENANCES</h5>
                <Badge className="maintenances-number" color="info">
                  {overViewList?.ongoingMaintenances.length}
                </Badge>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="on-going-maintenance-content">
              <div className="table_outer_div table_outer_div-border-none">
                {!isLoading && (
                  <AppTable
                    head={[
                      '',
                      'Activity',
                      'Machine Name',
                      'Section',
                      'Sub Number',
                      'Start Time',
                      '',
                    ]}
                    data={getTableOnGoingData()}
                    limit={5}
                    pagination={true}
                  />
                )}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="2">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion-btn up-coming-maintenance-btn">
                <h5>UP COMING MAINTENANCES</h5>
                <Badge className="maintenances-number" color="warning">
                  {overViewList?.upcomingMaintenances.length}
                </Badge>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="up-coming-maintenance-content">
              <div className="table_outer_div table_outer_div-border-none">
                {!isLoading && (
                  <AppTable
                    head={[
                      '',
                      'Activity',
                      'Machine Name',
                      'Section',
                      'Sub Number',
                      'Next Due Date',
                      '',
                    ]}
                    data={getTableUpComingData()}
                    limit={5}
                    pagination={true}
                  />
                )}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <AppModal
        onClose={() => handleModalDelayedClose()}
        show={showModalDelayed}
        title="View Maintenance"
      >
        <DelayedMaintenanceView
          maintenanceDetailsObj={currentDelayedMaintenanceItem!}
          currentActivityName={currentActivityName!}
          currentMachineTypeName={currentMachineTypeName!}
          modalToggle={handleModalDelayedClose}
        />
      </AppModal>
      <AppModal
        onClose={() => handleModalOnGoingClose()}
        show={showModalOnGoing}
        title="View Maintenance"
      >
        <OnGoingMaintenanceView
          maintenanceDetailsObj={currentOnGoingMaintenanceItem!}
          currentActivityName={currentActivityName!}
          currentMachineTypeName={currentMachineTypeName!}
        />
      </AppModal>
    </div>
  );
};

export default OverViewPage;
