import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';
import { useSelector, connect } from 'react-redux';
import { ActivityType } from '../../../models/activityType';
import { MachineType } from '../../../models/machineType';

import { Maintenance } from '../../../models/maintenance';
import {
  getActivityType,
  getMachineType,
} from '../../dashboard/redux/organizationSelector';
import EndMaintenanceForm from '../forms/EndMaintenanceForm';
import { RootState } from '../../../app/redux/rootReducer';

interface Props {
  maintenance: Maintenance;
  getActivityTypeDetails: (activityTypeId: string) => ActivityType | undefined;
  getMachineTypeDetails: (machineTypeId: string) => MachineType | undefined;
}

const MaintenanceFinishPage: React.FC<Props> = ({
  maintenance,
  getActivityTypeDetails,
  getMachineTypeDetails,
}: Props) => {
  const state = useSelector((state: RootState) => state);
  const machineType = getMachineType(
    state,
    maintenance.activity.machine.machineTypeId
  );

  useEffect(() => {
    document.title = 'End Maintenance';
  }, []);

  const activityTypeDetails = getActivityTypeDetails(
    maintenance.activity.activityTypeId
  );
  const machineTypeDetails = getMachineTypeDetails(
    maintenance.activity.machine.machineTypeId
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h3 className="title-h5-style">End maintenance</h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="table_outer_div">
            <div className="container">
              <Table borderless>
                {maintenance ? (
                  <tbody>
                    <tr>
                      <td>
                        <b>Machine</b>
                      </td>
                      <td>{maintenance.activity.machine.machineName}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Machine Type</b>
                      </td>
                      <td>{machineTypeDetails?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Section</b>
                      </td>
                      <td>{machineType ? machineType.machineSection : ''}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sub Number</b>
                      </td>
                      <td>{maintenance.activity.machine.machineSubNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Activity</b>
                      </td>
                      <td>{activityTypeDetails?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Due Date</b>
                      </td>
                      <td>
                        {moment(maintenance.dueDate)
                          .tz(moment.tz.guess())
                          .format('YYYY-MM-DD h:mm:ss a')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Start Time</b>
                      </td>
                      <td>
                        {moment(maintenance.startTime)
                          .tz(moment.tz.guess())
                          .format('YYYY-MM-DD h:mm:ss a')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Responsible</b>
                      </td>
                      <td>
                        <Link to={'/users/'.concat(maintenance.user.userId)}>
                          {maintenance.user.firstName &&
                            maintenance.user.lastName &&
                            maintenance.user.firstName
                              ?.concat(' ')
                              .concat(maintenance.user.lastName)}
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  ''
                )}
              </Table>
            </div>
          </div>
        </div>
        <div className="col">
          <EndMaintenanceForm startedMaintenance={maintenance} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getActivityTypeDetails: (activityTypeId: string) =>
    getActivityType(state, activityTypeId),
  getMachineTypeDetails: (machineTypeId: string) =>
    getMachineType(state, machineTypeId),
});

export default connect(mapStateToProps)(MaintenanceFinishPage);
