import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Table, Row, Col } from 'reactstrap';

import { RootState } from '../../../app/redux/rootReducer';
import {
  getActivityTypes,
  getMachineType,
} from '../../dashboard/redux/organizationSelector';
import { MachineType } from '../../../models/machineType';
import { ActivityType } from '../../../models/activityType';
import { getActivityFrequencyOptionValue } from '../../../utils/organizationUtils';

interface Props {
  onClose: () => void;
  show: boolean;
  machineTypeId: string;
  activityTypeList: Array<ActivityType>;
  getMachineTypeDetails: (machineTypeId: string) => MachineType | undefined;
}

const MachineInfoModal: React.FC<Props> = ({
  onClose,
  show,
  machineTypeId,
  activityTypeList,
  getMachineTypeDetails,
}: Props) => {
  const machineType = getMachineTypeDetails(machineTypeId);
  let machineActivityTypes: Array<ActivityType> = [];
  if (machineType) {
    machineActivityTypes = activityTypeList.filter(
      (activityType) => activityType.machineTypeId === machineType.id
    );
  }

  return (
    <>
      <Modal
        show={show}
        size={'lg'}
        onHide={onClose}
        backdrop="static"
        keyboard={true}
        centered
      >
        {' '}
        {machineType ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{machineType.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table_outer_div">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 header-txt">
                      <h5>MACHINE TYPE DETAILS</h5>
                    </div>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Type Name</th>
                      <th>Horse Power</th>
                      <th>Section</th>
                      <th>Specification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{machineType.name}</td>
                      <td>{machineType.horsePower}</td>
                      <td>{machineType.machineSection}</td>
                      <td>{machineType.specification}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="table_outer_div">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 header-txt">
                      <h5>ACTIVITY LIST</h5>
                    </div>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Activity</th>
                      <th>Frequency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {machineActivityTypes.map((activityType, index) => (
                      <tr key={activityType.activityTypeId}>
                        <td>{index + 1}</td>
                        <td>{activityType.name}</td>
                        <td>
                          {getActivityFrequencyOptionValue(
                            String(activityType.frequency)
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </>
        ) : (
          'Something went wrong, please refresh the page.'
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  getMachineTypeDetails: (machineTypeId: string) =>
    getMachineType(state, machineTypeId),
  activityTypeList: getActivityTypes(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MachineInfoModal);
