import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BiAddToQueue } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

import { AppModal, Loader, WarningModal } from '../../../shared/components';
import { RootState } from '../../../app/redux/rootReducer';
import { isAdmin as checkIsAdmin } from '../../auth/redux/authSelector';
import CreateWorkshopForm from '../../workshop/forms/CreateWorkshopForm';
import SectionView from '../../section/components/SectionView/SectionView';
import { Section } from '../../../models/section';
import { deleteSection, getSection } from '../../../services/api/sectionApi';
import { notificationActions } from '../../../app/redux/notification.slice';

interface ParamsProps {
  categoryId: string;
  sectionId: string;
}

const SectionViewPage: React.FC = () => {
  const { categoryId, sectionId } = useParams<ParamsProps>();
  const state = useSelector((state: RootState) => state);
  const isAdmin = checkIsAdmin(state);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [sectionDetails, setSectionDetails] = useState<Section>();

  useEffect(() => {
    setIsLoading(true);
    if (sectionId) {
      getSection(categoryId, sectionId).subscribe(
        (response: Section) => {
          setSectionDetails(response);
          setIsLoading(false);
          console.log(response);
        },
        (error: Error) => {
          setIsLoading(false);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Fetch Section Details`,
            })
          );
        }
      );
    }
  }, []);

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const toggleWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const handleOnClickWarning = () => {
    if (sectionDetails) {
      deleteSection(categoryId, sectionDetails.sectionId).subscribe(
        () => {
          console.log('success');
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Delete Section`,
            })
          );
        }
      );
    }

    toggleWarningModal();
  };

  return (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          <button
            onClick={toggleWarningModal}
            className="add-new-link-btn add-new-link-btn-red ft-size-16"
          >
            <AiOutlineDelete size={18} /> Delete Section
          </button>
          <button
            onClick={handleModalToggle}
            className="add-new-link-btn ft-size-16"
          >
            <BiAddToQueue size={18} /> Add Workshop
          </button>
          <AppModal
            onClose={handleModalToggle}
            show={showModal}
            title="Add Workshop"
          >
            <CreateWorkshopForm
              categoryId={categoryId}
              sectionId={sectionId}
              onSubmit={handleModalToggle}
            />
          </AppModal>
        </div>
      </div>
      {sectionDetails && (
        <SectionView
          categoryId={categoryId}
          sectionDetailsObj={sectionDetails}
        />
      )}
      {isAdmin && (
        <>
          <WarningModal
            show={showWarningModal}
            onClose={toggleWarningModal}
            onClickOk={handleOnClickWarning}
          />
        </>
      )}
    </div>
  );
};

export default SectionViewPage;
