const { REACT_APP_BASE_URL: BASE_URL } = process.env;

export const emptyMeta = {
  length: 0,
  from: 0,
  to: 0,
};

export const emptyState = {
  items: null,
  meta: { ...emptyMeta },
  loading: false,
  error: null,
};

export { BASE_URL };
