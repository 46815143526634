import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { CgMoreVerticalR } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { MdViewHeadline } from 'react-icons/md';

import { RootState } from '../../../app/redux/rootReducer';
import { AppModal, AppTable } from '../../../shared/components';
import { getActivityType, getMachineType } from '../redux/organizationSelector';
import { Activity } from '../../../models/activity';
import { ActivityType } from '../../../models/activityType';
import { MachineType } from '../../../models/machineType';
import { getId } from '../../auth/redux/authSelector';
import DelayedMaintenanceView from '../../overview/components/DelayedMaintenanceView';
import { Maintenance } from '../../../models/maintenance';
import OnGoingMaintenanceView from '../../overview/components/OnGoingMaintenanceView';
import HomeTopDashboard from './HomeTopDashboard/HomeTopDashboard';
import { getUserList } from '../../../app/redux/appSelector';
import { User } from '../../../models/user';

const filterType = {
  Assigned: 'Assigned To Me',
  Unassigned: 'All',
};

interface Props {
  userId?: string;
  getActivityTypeDetails: (activityTypeId: string) => ActivityType | undefined;
  getMachineTypeDetails: (machineTypeId: string) => MachineType | undefined;
  users: Array<User>;
}

interface TodoStatePros {
  activity: Activity;
  activityTypeName: string;
  machineTypeName: string;
}

interface InProgressStateProps {
  maintenance: Maintenance;
  activityTypeName: string;
  machineTypeName: string;
}

const MaintenanceDashboard: React.FC<Props> = ({
  userId,
  getActivityTypeDetails,
  getMachineTypeDetails,
  users,
}: Props) => {
  const [todoFilter, setTodoFilter] = useState(filterType.Assigned);
  const [todoList, setTodoList] = useState<Array<JSX.Element>>([]);
  const [inProgressFilter, setInProgressFilter] = useState(filterType.Assigned);
  const [inProgressList, setInProgressList] = useState<Array<JSX.Element>>([]);
  const [showModalTodo, setShowModalTodo] = useState(false);
  const [showModalInProgress, setShowModalInProgress] = useState(false);
  const [selectedTodo, setSelectedTodo] = useState<TodoStatePros>();
  const [
    selectedInProgress,
    setSelectedInProgress,
  ] = useState<InProgressStateProps>();

  const overview = useSelector(
    (state: RootState) => state.organization.overview
  );

  const toggleTodoModal = () => {
    setShowModalTodo(!showModalTodo);
  };

  const toggleInProgressModal = () => {
    setShowModalInProgress(!showModalInProgress);
  };

  const handleOnTodoClick = (
    activity: Activity,
    activityTypeName: string,
    machineTypeName: string
  ) => {
    setSelectedTodo({
      activity,
      activityTypeName,
      machineTypeName,
    });
    toggleTodoModal();
  };

  const handleOnInProgressClick = (
    maintenance: Maintenance,
    activityTypeName: string,
    machineTypeName: string
  ) => {
    setSelectedInProgress({ maintenance, activityTypeName, machineTypeName });
    toggleInProgressModal();
  };

  const getTableDelayedDataItem = (item: Activity, i: number) => {
    const activityTypeDetails = getActivityTypeDetails(item.activityTypeId);
    const machineTypeDetails = getMachineTypeDetails(
      item.machine.machineTypeId
    );
    return (
      <tr key={item.activityId}>
        <th scope="row">{i + 1}</th>
        <td>{activityTypeDetails ? activityTypeDetails.name : ''}</td>
        <td>{item.machine ? item.machine.machineName : ''}</td>
        <td>{machineTypeDetails ? machineTypeDetails.machineSection : ''}</td>
        <td>{item.machine ? item.machine.machineSubNumber : ''}</td>
        <td>
          {moment(item.nextDueDate).tz(moment.tz.guess()).format('YYYY-MM-DD')}
        </td>
        {activityTypeDetails && machineTypeDetails && (
          <td>
            <a
              onClick={() =>
                handleOnTodoClick(
                  item,
                  activityTypeDetails.name,
                  machineTypeDetails.name
                )
              }
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} />
            </a>
          </td>
        )}
      </tr>
    );
  };

  const getTableDelayedData = (todoActivities: Array<Activity>) => {
    const array: Array<JSX.Element> = [];
    let count = 0;
    let i = 0;
    if (userId && todoFilter === filterType.Assigned) {
      while (i < todoActivities.length && count < 5) {
        const item = todoActivities[i];
        if (item.responsibleUserId === userId) {
          array.push(getTableDelayedDataItem(item, i));
          count = count + 1;
        }
        i = i + 1;
      }
    } else {
      while (i < todoActivities.length && count < 5) {
        const item = todoActivities[i];
        array.push(getTableDelayedDataItem(item, i));
        count = count + 1;
        i = i + 1;
      }
    }
    return array;
  };

  const getInProgressItem = (item: Maintenance, i: number) => {
    const activityTypeDetails = getActivityTypeDetails(
      item.activity.activityTypeId
    )!;
    const machineTypeDetails = getMachineTypeDetails(
      item.activity.machine.machineTypeId
    )!;
    return (
      <tr key={item.maintenanceId}>
        <th scope="row">{i + 1}</th>
        <td>{activityTypeDetails ? activityTypeDetails.name : ''}</td>
        <td>{item.activity.machine.machineName || ''}</td>
        <td>{machineTypeDetails.machineSection || ''}</td>
        <td>{item.activity.machine.machineSubNumber || ''}</td>
        <td>
          {moment(item.startTime).tz(moment.tz.guess()).format('MMM D h:mm a')}
        </td>
        <td>
          <a
            onClick={() =>
              handleOnInProgressClick(
                item,
                activityTypeDetails.name,
                machineTypeDetails.name
              )
            }
            className="add-new-link-btn-thin-border ft-size-16"
          >
            <MdViewHeadline size={20} />
          </a>
        </td>
      </tr>
    );
  };

  const getTableOnGoingData = (inProgressMaintenances: Array<Maintenance>) => {
    const array: JSX.Element[] = [];
    let count = 0;
    let i = 0;
    if (userId && inProgressFilter === filterType.Assigned) {
      while (i < inProgressMaintenances.length && count < 5) {
        const item = inProgressMaintenances[i];
        if (
          item.user.userId === userId ||
          item.startedByUser.userId === userId
        ) {
          array.push(getInProgressItem(item, i));
          count = count + 1;
        }
        i = i + 1;
      }
    } else {
      while (i < inProgressMaintenances.length && count < 5) {
        const item = inProgressMaintenances[i];
        array.push(getInProgressItem(item, i));
        count = count + 1;
        i = i + 1;
      }
    }
    return array;
  };

  useEffect(() => {
    if (overview && overview?.delayedMaintenances.length > 0) {
      setTodoList(getTableDelayedData(overview.delayedMaintenances));
    } else if (overview && overview.upcomingMaintenances.length > 0) {
      setTodoList(getTableDelayedData(overview.upcomingMaintenances));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview, todoFilter]);

  useEffect(() => {
    if (overview && overview.ongoingMaintenances.length > 0) {
      setInProgressList(getTableOnGoingData(overview.ongoingMaintenances));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgressFilter, overview]);

  return (
    <div className="container pt-3">
      <HomeTopDashboard
        delayed={overview?.delayedMaintenances.length}
        onGoing={overview?.ongoingMaintenances.length}
        upComing={overview?.upcomingMaintenances.length}
        users={users.length}
      />
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end pb-2">
          <UncontrolledDropdown>
            <DropdownToggle caret>{inProgressFilter}</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => setInProgressFilter(filterType.Assigned)}
              >
                Assigned
              </DropdownItem>
              <DropdownItem
                onClick={() => setInProgressFilter(filterType.Unassigned)}
              >
                Unassigned
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="table_outer_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 header-txt">
              <h5>IN PROGRESS MAINTENANCES</h5>
            </div>
          </div>
        </div>
        <AppTable
          head={[
            '',
            'Activity',
            'Machine',
            'Section',
            'Sub Number',
            'Start Time',
            '',
          ]}
          data={inProgressList}
          pagination={true}
        />
      </div>
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end pt-2 pb-2">
          <UncontrolledDropdown>
            <DropdownToggle caret>{todoFilter}</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => setTodoFilter(filterType.Assigned)}>
                {filterType.Assigned}
              </DropdownItem>
              <DropdownItem
                onClick={() => setTodoFilter(filterType.Unassigned)}
              >
                {filterType.Unassigned}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="table_outer_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 header-txt">
              <h5>TODO MAINTENANCES</h5>
            </div>
          </div>
        </div>
        <AppTable
          head={[
            '',
            'Activity',
            'Machine',
            'Section',
            'Sub Number',
            'Due Date',
            '',
          ]}
          data={todoList}
          pagination={true}
        />
      </div>
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end pt-2 pb-2">
          <Link
            to={{
              pathname: '/overview',
            }}
          >
            <CgMoreVerticalR size={18} /> More
          </Link>
        </div>
      </div>
      {selectedTodo && (
        <AppModal
          onClose={() => toggleTodoModal()}
          show={showModalTodo}
          title="View Maintenance"
        >
          <DelayedMaintenanceView
            maintenanceDetailsObj={selectedTodo.activity}
            currentActivityName={selectedTodo.activityTypeName}
            currentMachineTypeName={selectedTodo.machineTypeName}
            modalToggle={toggleTodoModal}
          />
        </AppModal>
      )}
      {selectedInProgress && (
        <AppModal
          onClose={() => toggleInProgressModal()}
          show={showModalInProgress}
          title="View Maintenance"
        >
          <OnGoingMaintenanceView
            maintenanceDetailsObj={selectedInProgress.maintenance}
            currentActivityName={selectedInProgress.activityTypeName}
            currentMachineTypeName={selectedInProgress.machineTypeName}
          />
        </AppModal>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getActivityTypeDetails: (activityTypeId: string) =>
    getActivityType(state, activityTypeId),
  getMachineTypeDetails: (machineTypeId: string) =>
    getMachineType(state, machineTypeId),
  userId: getId(state),
  users: getUserList(state),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceDashboard);
