import { combineReducers } from '@reduxjs/toolkit';

import authSlice from '../../modules/auth/redux/authSlice';
import appSlice from './appSlice';
import organizationSlice from '../../modules/dashboard/redux/organizationSlice';
import navigationReducer from './navigationSlice';
import NotificationReducer from './notification.slice';

const rootReducer = combineReducers({
  auth: authSlice,
  app: appSlice,
  organization: organizationSlice,
  navigation: navigationReducer,
  notification: NotificationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
