import React from 'react';

import MaintenanceList from '../../../maintenance/component/MaintenanceList/MaintenanceList';

const AdminMaintenanceDetails: React.FC = () => (
  <div className="container main-container-div">
    <div className="row">
      <div className="col-lg-12">
        <MaintenanceList />
      </div>
    </div>
  </div>
);

export default AdminMaintenanceDetails;
