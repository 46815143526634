import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { RootState } from '../../../app/redux/rootReducer';
import { MachineType } from '../../../models/machineType';
import { getSchema, ItemList } from '../../../app/redux/schema';
import { ActivityType } from '../../../models/activityType';
import { Maintenance } from '../../../models/maintenance';
import { Machine } from '../../../models/machine';
import { Category } from '../../../models/category';
import { Workshop } from '../../../models/workshop';

const machineTypesSelector = (state: RootState) =>
  state.organization.machineType;

const workshopsSelector = (state: RootState) => state.organization.workshop;

const activityTypesSelector = (state: RootState) =>
  state.organization.activityType;

const maintenanceSelector = (state: RootState) =>
  state.organization.maintenance;

const machinesSelector = (state: RootState) => state.organization.machine;

const categorySelector = (state: RootState) => state.organization.category;

const getMachineTypes = createSelector(
  machineTypesSelector,
  (machineTypes): Array<MachineType> => {
    if (machineTypes) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<MachineType> = denormalize(
        machineTypes.result,
        getSchema('id'),
        machineTypes.entities
      );
      return array;
    }
    return [];
  }
);

const getWorkshops = createSelector(
  workshopsSelector,
  (workshops): Array<Workshop> => {
    if (workshops) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<Workshop> = denormalize(
        workshops.result,
        getSchema('workshopId'),
        workshops.entities
      );
      return array;
    }
    return [];
  }
);

const getActivityTypes = createSelector(
  activityTypesSelector,
  (activityTypes): Array<ActivityType> => {
    if (activityTypes) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<ActivityType> = denormalize(
        activityTypes.result,
        getSchema('activityTypeId'),
        activityTypes.entities
      );
      return array;
    }
    return [];
  }
);

const machineTypeSelector = (state: RootState, key: string) => ({
  machineTypes: state.organization.machineType,
  key,
});

const workshopSelector = (state: RootState, key: string) => ({
  workshops: state.organization.workshop,
  key,
});

const activityTypeSelector = (state: RootState, key: string) => ({
  activityTypes: state.organization.activityType,
  key,
});

const machineSelector = (state: RootState, key: string) => ({
  machines: state.organization.machine,
  key,
});

const getMachineType = createSelector(
  machineTypeSelector,
  ({ machineTypes, key }) => {
    if (machineTypes?.entities) {
      const list = machineTypes?.entities as ItemList<MachineType>;
      return list.items[key];
    }
    return undefined;
  }
);

const getWorkshop = createSelector(workshopSelector, ({ workshops, key }) => {
  if (workshops?.entities) {
    const list = workshops?.entities as ItemList<Workshop>;
    return list.items[key];
  }
  return undefined;
});

const getActivityType = createSelector(
  activityTypeSelector,
  ({ activityTypes, key }) => {
    if (activityTypes?.entities) {
      const list = activityTypes?.entities as ItemList<ActivityType>;
      return list.items[key];
    }
    return undefined;
  }
);

const getMaintenanceList = createSelector(
  maintenanceSelector,
  ({ items }): Array<Maintenance> => {
    if (items) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array = denormalize(
        items.result,
        getSchema('maintenanceId'),
        items.entities
      );
      return array as Array<Maintenance>;
    }
    return [] as Array<Maintenance>;
  }
);

const getMachineList = createSelector(machinesSelector, ({ list }) => {
  if (list) {
    const array = denormalize(
      list.result,
      getSchema('machineId'),
      list.entities
    ) as Array<Machine>;
    return array;
  }
  return [] as Array<Machine>;
});

const getMachine = createSelector(machineSelector, ({ machines, key }) => {
  if (machines.list?.entities) {
    const list = machines.list.entities;
    return list.items[key];
  }
  return undefined;
});

const getCategoryList = createSelector(categorySelector, ({ list }) => {
  if (list) {
    const array = denormalize(
      list.result,
      getSchema('categoryId'),
      list.entities
    ) as Array<Category>;
    return array;
  }
  return [] as Array<Category>;
});

//Simple Selectors
const selectedCategorySelector = (state: RootState): string | null =>
  state.organization.category.selectedCategory;

export {
  getMachineTypes,
  getMachineType,
  getWorkshops,
  getWorkshop,
  getActivityTypes,
  getActivityType,
  getMaintenanceList,
  getMachineList,
  getMachine,
  getCategoryList,
  selectedCategorySelector,
};
