import React from 'react';
import { connect } from 'react-redux';

import { getCompanyTypeList } from '../../../app/redux/appSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { CompanyType } from '../../../models/companyType';
import { Link } from 'react-router-dom';

interface Props {
  companyTypes: Array<CompanyType>;
}

const CompanyTypeList: React.FC<Props> = ({ companyTypes }: Props) => {
  return (
    <div className="container">
      <div className="table_outer_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 header-txt">
              <h5>Company Types</h5>
            </div>
          </div>
        </div>
      </div>
      <div>
        {companyTypes.map((companyType) => (
          <div key={companyType.companyTypeId}>
            <Link to={'#'}>{companyType.companyName}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyTypes: getCompanyTypeList(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTypeList);
