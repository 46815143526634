import React, { useState, useEffect } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import moment from 'moment-timezone';

import { periodTypes } from '../../../constants/maintenance';
import { AppCheckBox } from '../../../shared/components';
import { DashboardFilter } from '../../../models/maintenance';

import './dashboard.css';

interface Props {
  compare?: boolean;
  onChange: (filter: DashboardFilter, compared: boolean) => void;
}

const DashboardFilterBar: React.FC<Props> = ({
  compare = true,
  onChange,
}: Props) => {
  const today = moment().tz(moment.tz.guess());

  const [isCompared, setIsCompared] = useState(true);
  const [period, setPeriod] = useState<DashboardFilter>({
    periodType: periodTypes.THIS_MONTH,
    date: today.clone().startOf('month').format('YYYY-MM-DD'),
    period: today.date(),
    compareDate: today
      .clone()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD'),
    comparePeriod: today.clone().subtract(1, 'months').daysInMonth(),
  });

  useEffect(() => {
    onChange(period, isCompared);
  }, [period]);

  const onPeriodChangeHandler = (periodType: number) => {
    setIsCompared(false);
    switch (periodType) {
      case periodTypes.THIS_WEEK:
        setPeriod({
          periodType: periodTypes.THIS_WEEK,
          date: today.clone().isoWeekday(1).format('YYYY-MM-DD'),
          period: today.diff(today.clone().isoWeekday(1), 'days') + 1,
        });
        break;
      case periodTypes.LAST_WEEK:
        setPeriod({
          periodType: periodTypes.LAST_WEEK,
          date: today
            .clone()
            .isoWeekday(1)
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD'),
          period: 7,
        });
        break;
      case periodTypes.THIS_MONTH:
        setPeriod({
          periodType: periodTypes.THIS_MONTH,
          date: today.clone().startOf('month').format('YYYY-MM-DD'),
          period: today.date(),
        });
        break;
      case periodTypes.LAST_MONTH:
        setPeriod({
          periodType: periodTypes.LAST_MONTH,
          date: today
            .clone()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
          period: today.clone().subtract(1, 'months').daysInMonth(),
        });
        break;
    }
  };

  const onCheckBoxChangeHandler = (compare: boolean) => {
    if (compare) {
      setPeriod({
        ...period,
        compareDate:
          period.periodType === periodTypes.THIS_WEEK
            ? today
                .clone()
                .isoWeekday(1)
                .subtract(1, 'weeks')
                .format('YYYY-MM-DD')
            : today
                .clone()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD'),
        comparePeriod:
          period.periodType === periodTypes.THIS_WEEK
            ? 7
            : today.clone().subtract(1, 'months').daysInMonth(),
      });
    } else {
      setPeriod({
        periodType: period.periodType,
        date: period.date,
        period: period.period,
      });
    }
    setIsCompared(!isCompared);
  };

  return (
    <div className="row dashboard-filter-bar">
      <div className="col pb-3 pt-2">
        {compare &&
          (period.periodType === periodTypes.THIS_WEEK ||
            period.periodType === periodTypes.THIS_MONTH) && (
            <AppCheckBox
              label={`Compare with previous ${
                period.periodType === periodTypes.THIS_WEEK ? 'week' : 'month'
              }`}
              onChange={onCheckBoxChangeHandler}
              checked={isCompared}
            />
          )}
      </div>
      <div className="col pb-3">
        <UncontrolledDropdown>
          <DropdownToggle caret className="drop-down-toggle">
            {period &&
              `${moment(period.date)
                .tz(moment.tz.guess())
                .format('MMM D')} - ${moment(period.date)
                .tz(moment.tz.guess())
                .add(period.period - 1, 'd')
                .format('MMM D')}`}
          </DropdownToggle>
          <DropdownMenu className="drop-down-menu">
            <DropdownItem
              onClick={() => onPeriodChangeHandler(periodTypes.THIS_WEEK)}
            >
              This week
            </DropdownItem>
            <DropdownItem
              onClick={() => onPeriodChangeHandler(periodTypes.LAST_WEEK)}
            >
              Last week
            </DropdownItem>
            <DropdownItem
              onClick={() => onPeriodChangeHandler(periodTypes.THIS_MONTH)}
            >
              This Month
            </DropdownItem>
            <DropdownItem
              onClick={() => onPeriodChangeHandler(periodTypes.LAST_MONTH)}
            >
              Last Month
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
};

export default DashboardFilterBar;
