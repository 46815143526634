import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import { Category, CreateCategory } from '../../models/category';

const CATEGORY_ENDPOINT = '/category/';

export const getCategory = (categoryId: string): Observable<Category> =>
  GET(`${CATEGORY_ENDPOINT}${categoryId}`);

export const getCategories = (): Observable<Array<Category>> =>
  GET(CATEGORY_ENDPOINT, {});

export const createCategory = (
  category: CreateCategory
): Observable<Category> => POST(CATEGORY_ENDPOINT, { ...category });

export const updateCategory = (category: Category): Observable<Category> =>
  PUT(`${CATEGORY_ENDPOINT}${category.categoryId}`, { ...category });

export const deleteCategory = (categoryId: string): Observable<null> =>
  DELETE(`${CATEGORY_ENDPOINT}${categoryId}`);
