import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { AppButton, AppInput } from '../../../shared/forms/components';
import {
  getUserByResetToken,
  resetPassword,
} from '../../../services/api/userApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { ResetPasswordViewModel } from '../../../models/auth';

interface Props {
  confirmationToken: string;
}

const getInitialValues = (email: string) => ({
  email,
  newPassword: '',
});

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  newPassword: Yup.string().required(),
});

const ResetPasswordForm: React.FC<Props> = ({ confirmationToken }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getUserByResetToken(confirmationToken).subscribe(
      (response) => {
        setEmail(response.email);
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Invalid Confirmation Token',
          })
        );
      }
    );
  }, []);

  const onSubmitHandler = (values: ResetPasswordViewModel) => {
    setIsLoading(true);
    resetPassword(confirmationToken, values).subscribe(
      () => {
        history.push('/login');
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully Updated Password',
            title: 'Success',
          })
        );
      },
      (error: Error) => {
        setIsLoading(false);
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Update Password',
          })
        );
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues(email)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="d-flex justify-content-center mb-5 text-center" />
        <h2 className="title-tag-style my-2 text-left mb-4">Update Password</h2>
        <AppInput
          name="email"
          label="Email*"
          placeholder="Email*"
          type="email"
          loading
        />
        <AppInput
          name="newPassword"
          label="New Password*"
          placeholder="New Password*"
          type="password"
          loading={isLoading}
        />
        <AppButton type="submit" text="Update" loading={isLoading} />
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
