/* eslint-disable import/no-unresolved */
import React from 'react';

import { HiStatusOnline } from 'react-icons/hi';
import { RiUserSettingsLine } from 'react-icons/ri';
import { AiFillTool } from 'react-icons/ai';
import { AiFillSetting } from 'react-icons/ai';
import { Badge } from 'reactstrap';

interface HomeDashboardProps {
  delayed?: number;
  onGoing?: number;
  upComing?: number;
  users?: number;
}

const HomeTopDashboard: React.FC<HomeDashboardProps> = ({
  delayed,
  onGoing,
  upComing,
  users,
}) => (
  <div className="row">
    <div className="col-lg-3 col-md-6">
      <div className="card-maintenance dashboard-maintenance">
        <span>Delayed</span>
        <h2 className="dashboard-ongoing-maintenance">{delayed}</h2>
        <Badge color="danger">Start quickly</Badge> Maintenances
        <div className="side-box">
          <HiStatusOnline className="text-delayed-color" />
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6">
      <div className="card-maintenance dashboard-maintenance">
        <span>OnGoing</span>
        <h2 className="dashboard-ongoing-maintenance">{onGoing}</h2>
        <Badge color="info">Need to finish</Badge> Maintenances
        <div className="side-box">
          <AiFillSetting className="text-ongoing-color" />
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6">
      <div className="card-maintenance dashboard-maintenance">
        <span>Up Coming</span>
        <h2 className="dashboard-ongoing-maintenance">{upComing}</h2>
        <Badge color="success">Need to start</Badge> Maintenances
        <div className="side-box">
          <AiFillTool className="text-completed-color" />
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6">
      <div className="card-maintenance dashboard-maintenance">
        <span>Users</span>
        <h2 className="dashboard-ongoing-maintenance">{users}</h2>
        <Badge color="warning">Active</Badge> Users
        <div className="side-box">
          <RiUserSettingsLine className="text-users-color" />
        </div>
      </div>
    </div>
  </div>
);

export default HomeTopDashboard;
