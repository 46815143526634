import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { Dispatch } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import { CgMoreVerticalR } from 'react-icons/cg';
import { MdViewHeadline } from 'react-icons/md';
import moment from 'moment-timezone';
import Avatar from 'react-avatar';

import { RootState } from '../../../../app/redux/rootReducer';
import { getActivityType } from '../../../dashboard/redux/organizationSelector';
import { ActivityType } from '../../../../models/activityType';
import { getMaintenances } from '../../../../services/api/maintenanceApi';
import {
  Maintenance,
  MaintenancesRequest,
  MaintenancesResponse,
} from '../../../../models/maintenance';
import { AppModal, Loader } from '../../../../shared/components';
import MaintenanceDetails from '../MaintenanceDetails';
import { organizationActions } from '../../../dashboard/redux/organizationSlice';
import { MAINTENANCES } from '../../routes';
import { notificationActions } from '../../../../app/redux/notification.slice';
import { DATE_FORMAT } from '../../../../constants/dateTime';

interface Props {
  state: RootState;
  machineId: string;
  fetchMaintenance: (param?: MaintenancesRequest) => void;
}

let activityTypeDetails: ActivityType;

const MachineMaintenanceList: React.FC<Props> = ({
  state,
  machineId,
  fetchMaintenance,
}: Props) => {
  const [maintenanceList, setMachineMaintenanceList] = useState<
    Array<Maintenance>
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [
    currentMaintenanceItem,
    setCurrentMaintenanceItem,
  ] = useState<Maintenance>();
  const history = useHistory();
  const dispatch = useDispatch();

  function handleModalOpen(value: Maintenance) {
    setShowModal(!showModal);
    setCurrentMaintenanceItem(value);
  }

  function handleModalClose() {
    setShowModal(!showModal);
  }

  useEffect(() => {
    setIsLoading(true);
    getMaintenances({ machineId, limit: 4 }).subscribe(
      (response: MaintenancesResponse) => {
        setMachineMaintenanceList(response.items);
        setIsLoading(false);
      },
      (err: Error) => {
        setIsLoading(false);
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: err.message,
            title: `Error: Couldn't Fetch Maintenance`,
          })
        );
      }
    );
  }, []);

  const onClickHandler = () => {
    fetchMaintenance({
      machineId,
      dataRange: `${moment()
        .tz(moment.tz.guess())
        .add(-1, 'months')
        .format(DATE_FORMAT)},${moment()
        .tz(moment.tz.guess())
        .format(DATE_FORMAT)}`,
    });
    history.push(MAINTENANCES);
  };

  return (
    <div className="table_outer_div">
      <div className="container">
        <div className="row align-items-start">
          <div className="col header-txt">
            <h5>Maintenance Details</h5>
          </div>
        </div>
      </div>
      <Table borderless>
        <thead>
          <tr>
            <th></th>
            <th>Activity</th>
            <th>Start date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <Loader />
          ) : (
            maintenanceList.map((item) => {
              activityTypeDetails = getActivityType(
                state,
                item.activity.activityTypeId
              )!;

              return (
                <tr key={item.maintenanceId}>
                  <td>
                    <Link to={'/users/'.concat(item.user.userId)}>
                      <Avatar
                        name={
                          item.user.firstName &&
                          item.user.lastName &&
                          item.user.firstName
                            ?.concat(' ')
                            .concat(item.user.lastName)
                        }
                        size="30"
                        round="50%"
                        color="#303f9f"
                        fgColor="white"
                      />
                    </Link>
                  </td>
                  <td>{activityTypeDetails?.name}</td>
                  <td>
                    {moment(item.startTime)
                      .tz(moment.tz.guess())
                      .format('YYYY-MM-DD h:mm a')}
                  </td>
                  <td className="view-td">
                    <a
                      onClick={() => handleModalOpen(item)}
                      className="add-new-link-btn-thin-border ft-size-16"
                    >
                      <MdViewHeadline size={20} />
                    </a>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <div className="container">
        <div className="row">
          <div className="col">
            <a
              onClick={onClickHandler}
              className="add-new-link-btn-thin-border ft-size-16 float-right"
            >
              <CgMoreVerticalR size={18} /> More
            </a>
          </div>
        </div>
      </div>
      <AppModal
        onClose={() => handleModalClose()}
        show={showModal}
        title="Maintenance details"
        size="lg"
      >
        <MaintenanceDetails maintenanceDetailsObj={currentMaintenanceItem!} />
      </AppModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMaintenance: (param?: MaintenancesRequest) => {
    dispatch(
      organizationActions.fetchMaintenanceList(param ? { ...param } : {})
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MachineMaintenanceList);
