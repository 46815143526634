import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import moment from 'moment-timezone';
import { MdViewHeadline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Maintenance } from '../../../models/maintenance';
import {
  maintenanceGroupByActivityType,
  maintenanceGroupByMachine,
  maintenanceGroupByUser,
} from '../../../utils/groupUtils';
import { AppModal, AppTable } from '../../../shared/components';
import { RootState } from '../../../app/redux/rootReducer';
import { getActivityType } from '../../dashboard/redux/organizationSelector';
import { maintenanceGroupTypes } from '../../../constants/maintenance';
import MaintenanceDetails from './MaintenanceDetails';
import { MachineType } from '../../../models/machineType';
import { getMachineType } from '../../dashboard/redux/organizationSelector';

interface StateProps {
  [key: string]: Maintenance[];
}

interface Props {
  maintenanceArray: Array<Maintenance>;
}

const maintenancesStateList = ['STARTED', 'DELAYED', 'NOT_DELAYED'];
let machineTypeDetails: MachineType;

const MaintenanceGroup: React.FC<Props> = ({ maintenanceArray }: Props) => {
  const [maintenance, setMaintenance] = useState<StateProps>(
    maintenanceGroupByMachine(maintenanceArray)
  );
  const [group, setGroup] = useState(maintenanceGroupTypes.MACHINE);
  const [showModal, setShowModal] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = useState<Maintenance>();

  const state = useSelector((state: RootState) => state);

  const { loading, meta, items, filters } = state.organization.maintenance;

  useEffect(() => {
    if (maintenanceArray) {
      switch (group) {
        case maintenanceGroupTypes.MACHINE:
          setMaintenance(maintenanceGroupByMachine(maintenanceArray));
          break;
        case maintenanceGroupTypes.ACTIVITY_TYPE:
          setMaintenance(maintenanceGroupByActivityType(maintenanceArray));
          break;
        case maintenanceGroupTypes.USER:
          setMaintenance(maintenanceGroupByUser(maintenanceArray));
          break;
      }
    }
  }, [group, maintenanceArray]);

  const getAccordionTitle = (maintenance: Maintenance) => {
    switch (group) {
      case maintenanceGroupTypes.MACHINE:
        return maintenance.activity.machine.machineName;
      case maintenanceGroupTypes.ACTIVITY_TYPE:
        return getActivityType(state, maintenance.activity.activityTypeId)
          ?.name;
      case maintenanceGroupTypes.USER:
        return maintenance.user.email;
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleMaintenanceClick = (item: Maintenance) => {
    setSelectedMaintenance(item);
    toggleModal();
  };

  function isDelayedMaintenance(dueDate: any, endDate: any) {
    const dueValue = moment(dueDate).tz(moment.tz.guess()); //todays date
    const startValue = moment(endDate).tz(moment.tz.guess()); // another date
    const duration = moment.duration(dueValue.diff(startValue));
    const days = duration.asDays();

    // eslint-disable-next-line no-negated-condition
    if (!isNaN(days)) {
      if (days >= 0) {
        return maintenancesStateList[2];
      } else {
        return maintenancesStateList[1];
      }
    } else {
      return maintenancesStateList[0];
    }
  }

  const getMaintenanceTableData = (maintenanceArray: Array<Maintenance>) => {
    const array: Array<JSX.Element> = [];
    maintenanceArray.map((item, i) => {
      const activityTypeDetails = getActivityType(
        state,
        item.activity.activityTypeId
      )!;
      machineTypeDetails = getMachineType(
        state,
        item.activity.machine.machineTypeId
      )!;

      const maintenancesState = isDelayedMaintenance(
        item.dueDate,
        item.startTime
      );

      array.push(
        <tr key={item.maintenanceId}>
          {maintenancesState == maintenancesStateList[0] && (
            <td>
              <Badge color="info">On Going</Badge>
            </td>
          )}
          {maintenancesState == maintenancesStateList[1] && (
            <td>
              <Badge color="danger">Delayed</Badge>
            </td>
          )}
          {maintenancesState == maintenancesStateList[2] && (
            <td>
              <Badge color="success">On Time</Badge>
            </td>
          )}
          <th scope="row">{(meta.from ? Number(meta.from) : 1) + i}</th>
          <td>{activityTypeDetails?.name || ''}</td>
          <td>{item.activity.machine.machineName}</td>
          <td>{item.activity.machine.machineSubNumber}</td>
          <td>{machineTypeDetails ? machineTypeDetails.name : ''}</td>
          <td>
            <Link to={'/users/'.concat(item.user.userId)}>
              {item.user.firstName &&
                item.user.lastName &&
                item.user.firstName?.concat(' ').concat(item.user.lastName)}
            </Link>
          </td>
          <td>
            <a
              onClick={() => handleMaintenanceClick(item)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} />
            </a>
          </td>
        </tr>
      );
    });
    return array;
  };

  return (
    <div className="table_outer_div">
      <div className="container">
        <div className="row align-items-start">
          <div className="col header-txt">
            <h5>Maintenance Group By</h5>
          </div>
        </div>
        <div className="row align-items-start p-2 home-maintenance-group-div">
          <div className="col-xm-12 col-sm-12 col-md-4">
            <p
              className={
                group === 0
                  ? 'maintenance-list-top-button maintenance-list-top-button-active'
                  : 'maintenance-list-top-button'
              }
              onClick={() => setGroup(maintenanceGroupTypes.MACHINE)}
            >
              Machines
            </p>
          </div>
          <div className="col-xm-12 col-sm-12 col-md-4">
            <p
              className={
                group === 2
                  ? 'maintenance-list-top-button maintenance-list-top-button-active'
                  : 'maintenance-list-top-button'
              }
              onClick={() => setGroup(maintenanceGroupTypes.ACTIVITY_TYPE)}
            >
              Activity
            </p>
          </div>
          <div className="col-xm-12 col-sm-12 col-md-4">
            <p
              className={
                group === 3
                  ? 'maintenance-list-top-button maintenance-list-top-button-active'
                  : 'maintenance-list-top-button'
              }
              onClick={() => setGroup(maintenanceGroupTypes.USER)}
            >
              Users
            </p>
          </div>
        </div>
        <div className="row align-items-start p-2">
          <div className="col">
            <Accordion>
              {maintenance && Object.keys(maintenance).length > 0 ? (
                Object.keys(maintenance).map((key) => (
                  <AccordionItem uuid={key} key={key}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {getAccordionTitle(maintenance[key][0])}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="table_outer_div">
                        <AppTable
                          head={[
                            '',
                            '',
                            'Activity',
                            'Machine Name',
                            'Sub No',
                            'Machine Type',
                            'Responsible',
                            '',
                          ]}
                          data={getMaintenanceTableData(maintenance[key])}
                          pagination={false}
                        />
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))
              ) : (
                <div>No data</div>
              )}
            </Accordion>
          </div>
        </div>
        {selectedMaintenance && (
          <AppModal
            onClose={toggleModal}
            show={showModal}
            title="Maintenance details"
            size="lg"
          >
            <MaintenanceDetails maintenanceDetailsObj={selectedMaintenance} />
          </AppModal>
        )}
      </div>
    </div>
  );
};

export default MaintenanceGroup;
