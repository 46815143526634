import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import {
  MachineType,
  CreateMachineTypeResponse,
  CreateMachineType,
} from '../../models/machineType';

const MACHINE_TYPE_ENDPOINT = '/machinetype/';

export const getMachineTypes = (): Observable<Array<MachineType>> =>
  GET(MACHINE_TYPE_ENDPOINT);

export const getMachineType = (id: string): Observable<MachineType> =>
  GET(`${MACHINE_TYPE_ENDPOINT}${id}`);

export const createMachineType = (
  machineType: CreateMachineType
): Observable<CreateMachineTypeResponse> =>
  POST(MACHINE_TYPE_ENDPOINT, { ...machineType });

export const updateMachineType = (
  machineType: MachineType
): Observable<MachineType> =>
  PUT(`${MACHINE_TYPE_ENDPOINT}${machineType.id}`, { ...machineType });
