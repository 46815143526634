/* eslint-disable no-negated-condition */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Badge } from 'reactstrap';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

import { RootState } from '../../../../app/redux/rootReducer';
import {
  Maintenance,
  MaintenancesRequest,
} from '../../../../models/maintenance';
import {
  getActivityType,
  getMaintenanceList,
} from '../../../dashboard/redux/organizationSelector';
import {
  AppModal,
  AppPagination,
  AppTable,
  Loader,
} from '../../../../shared/components';
import { organizationActions } from '../../../dashboard/redux/organizationSlice';
import MaintenanceDetails from '../MaintenanceDetails';
import SearchBox, { FilterValues } from '../SearchBox/SearchBox';
import { DATE_FORMAT } from '../../../../constants/dateTime';
import { getMachineType } from '../../../dashboard/redux/organizationSelector';
import { MachineType } from '../../../../models/machineType';
import { ActivityType } from '../../../../models/activityType';
import { MdViewHeadline } from 'react-icons/md';

interface Props {
  getMaintenanceList: () => Array<Maintenance>;
  fetchMaintenance: (param?: MaintenancesRequest) => void;
}

let activityTypeDetails: ActivityType;
let machineTypeDetails: MachineType;
const maintenancesStateList = ['STARTED', 'DELAYED', 'NOT_DELAYED'];

const MaintenanceList: React.FC<Props> = ({
  getMaintenanceList,
  fetchMaintenance,
}: Props) => {
  const [maintenanceList, setMaintenanceList] = useState<Array<Maintenance>>(
    []
  );

  const state = useSelector((state: RootState) => state);
  const { loading, meta, items, filters } = state.organization.maintenance;

  const [showModal, setShowModal] = useState(false);
  const [
    currentMaintenanceItem,
    setCurrentMaintenanceItem,
  ] = useState<Maintenance>();

  const dateRange = filters?.dataRange
    ? filters.dataRange.split(',')
    : [
        moment().tz(moment.tz.guess()).add(-1, 'months').format(DATE_FORMAT),
        moment().tz(moment.tz.guess()).format(DATE_FORMAT),
      ];

  useEffect(() => {
    document.title = 'Maintenances';

    if (items) {
      setMaintenanceList(getMaintenanceList());
    } else if (!loading) {
      fetchMaintenance({
        dataRange: `${dateRange[0]},${dateRange[1]}`,
      });
    }
  }, [items]);

  const handleCallBack = (pages: number, from: number, to: number) => {
    fetchMaintenance({
      ...state.organization.maintenance.filters,
      from,
      to: to + 1,
    });
  };

  function handleModalOpen(value: Maintenance) {
    setShowModal(!showModal);
    setCurrentMaintenanceItem(value);
  }

  function handleModalClose() {
    setShowModal(!showModal);
  }

  function isDelayedMaintenance(dueDate: any, endDate: any) {
    const dueValue = moment(dueDate).tz(moment.tz.guess()); //todays date
    const startValue = moment(endDate).tz(moment.tz.guess()); // another date
    const duration = moment.duration(dueValue.diff(startValue));
    const days = duration.asDays();

    if (!isNaN(days)) {
      if (days >= 0) {
        return maintenancesStateList[2];
      } else {
        return maintenancesStateList[1];
      }
    } else {
      return maintenancesStateList[0];
    }
  }

  const getMaintenanceTableData = () => {
    const array: Array<JSX.Element> = [];
    maintenanceList.map((item, i) => {
      activityTypeDetails = getActivityType(
        state,
        item.activity.activityTypeId
      )!;
      machineTypeDetails = getMachineType(
        state,
        item.activity.machine.machineTypeId
      )!;

      const maintenancesState = isDelayedMaintenance(
        item.dueDate,
        item.startTime
      );

      array.push(
        <tr key={item.maintenanceId}>
          {maintenancesState == maintenancesStateList[0] && (
            <td>
              <Badge color="info">On Going</Badge>
            </td>
          )}
          {maintenancesState == maintenancesStateList[1] && (
            <td>
              <Badge color="danger">Delayed</Badge>
            </td>
          )}
          {maintenancesState == maintenancesStateList[2] && (
            <td>
              <Badge color="success">On Time</Badge>
            </td>
          )}
          <th scope="row">{(meta.from ? Number(meta.from) : 1) + i}</th>
          <td>{activityTypeDetails?.name || ''}</td>
          <td>{item.activity.machine.machineName}</td>
          <td>{item.activity.machine.machineSubNumber}</td>
          <td>{machineTypeDetails ? machineTypeDetails.name : ''}</td>
          <td>
            <Link to={'/users/'.concat(item.user.userId)}>
              <Avatar
                name={
                  item.user.firstName &&
                  item.user.lastName &&
                  item.user.firstName?.concat(' ').concat(item.user.lastName)
                }
                size="30"
                round="50%"
                color="#303f9f"
                fgColor="white"
              />
            </Link>
          </td>
          <td>
            <a
              onClick={() => handleModalOpen(item)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} />
            </a>
          </td>
        </tr>
      );
    });
    return array;
  };

  function handleFilterChange(value: FilterValues) {
    fetchMaintenance({
      dataRange: `${value.dateRange[0]},${value.dateRange[1]}`,
      machineId: value.machineId,
      activityId: value.activityId,
      userId: value.responsiblePersonId,
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <SearchBox
            filterValueObj={{
              machineId: filters?.machineId || '',
              machineTypeId: '',
              activityId: filters?.activityId || '',
              dateRange: dateRange,
              responsiblePersonId: filters?.userId || '',
            }}
            selectedFilter={(values) => handleFilterChange(values)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table_outer_div">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 header-txt">
                  <h5>MAINTENANCE LIST</h5>
                </div>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <>
                <AppTable
                  head={[
                    '',
                    '',
                    'Activity',
                    'Machine',
                    'Sub No',
                    'Type',
                    '',
                    '',
                  ]}
                  data={getMaintenanceTableData()}
                  limit={10}
                  pagination={false}
                />
                {meta.length > 10 && (
                  <AppPagination
                    dataLength={meta.length}
                    callBack={handleCallBack}
                    currentPage={~~(meta.from / 10) + 1}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <AppModal
        onClose={() => handleModalClose()}
        show={showModal}
        title="Maintenance details"
        size="lg"
      >
        <MaintenanceDetails maintenanceDetailsObj={currentMaintenanceItem!} />
      </AppModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  getMaintenanceList: () => getMaintenanceList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMaintenance: (param?: MaintenancesRequest) => {
    dispatch(
      organizationActions.fetchMaintenanceList(param ? { ...param } : {})
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceList);
