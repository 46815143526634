import { createSelector } from 'reselect';

import { AuthenticationResponse } from '../../../models/auth';
import { RootState } from '../../../app/redux/rootReducer';
import { AuthReducerState } from './authSlice';
import { UserRole } from '../../../models/userRole';
import { roleTypes } from '../../../constants/userRole';

const authSelector = (state: RootState) => state.auth;

const getToken = createSelector(
  authSelector,
  ({ auth }: AuthReducerState): string | undefined => auth?.token
);

const getName = createSelector<RootState, AuthReducerState, string | undefined>(
  authSelector,
  ({ auth }: AuthReducerState): string | undefined => auth?.firstName
);

const getId = createSelector(
  authSelector,
  ({ auth }: AuthReducerState): string | undefined => auth?.userId
);

const getAuth = createSelector<
  RootState,
  AuthReducerState,
  AuthenticationResponse | null
>(
  authSelector,
  (auth: AuthReducerState): AuthenticationResponse | null => auth.auth
);

const userAuthenticatedSelector = createSelector<
  RootState,
  AuthReducerState,
  boolean
>(authSelector, (auth) => auth.auth !== null);

const getUserRole = createSelector(
  authSelector,
  ({ userRole }: AuthReducerState): UserRole | null => userRole
);

const isAdmin = createSelector(
  authSelector,
  ({ userRole }: AuthReducerState): boolean =>
    userRole?.roleName === roleTypes.ADMIN
);

const isSuperAdmin = createSelector(
  authSelector,
  ({ userRole }: AuthReducerState): boolean =>
    userRole?.roleName === roleTypes.SUPER_ADMIN
);

export {
  getToken,
  getAuth,
  getName,
  userAuthenticatedSelector,
  getId,
  getUserRole,
  isAdmin,
  isSuperAdmin,
};
