import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { MachineType } from '../../../models/machineType';
import { ActivityType } from '../../../models/activityType';
import { getSchema } from '../../../app/redux/schema';
import {
  CreateNewMachine,
  Machine,
  MachineState,
} from '../../../models/machine';
import { CreateNewActivity } from '../../../models/activity';
import {
  MaintenanceListState,
  MaintenancesRequest,
  MaintenancesResponse,
} from '../../../models/maintenance';
import { emptyMeta, emptyState } from '../../../constants/api';
import { Overview } from '../../../models/overview';
import {
  Category,
  CategoryState,
  CreateCategory,
} from '../../../models/category';
import { CreateSection } from '../../../models/section';
import { CreateWorkshop, Workshop } from '../../../models/workshop';

export interface OrganizationReducerState {
  machineType: ReturnType<typeof normalize> | null;
  workshop: ReturnType<typeof normalize> | null;
  activityType: ReturnType<typeof normalize> | null;
  machine: MachineState;
  maintenance: MaintenanceListState;
  overview: Overview | null;
  activityLoading: boolean;
  category: CategoryState;
  categoryLoading: boolean;
  sectionLoading: boolean;
  workshopLoading: boolean;
}

export const initialState: OrganizationReducerState = {
  machineType: null,
  workshop: null,
  activityType: null,
  machine: { list: null, loading: false, error: null, currentPage: 1 },
  maintenance: { ...emptyState, filters: undefined },
  overview: null,
  activityLoading: false,
  category: {
    list: null,
    selectedCategory: null,
    loading: false,
  },
  categoryLoading: false,
  sectionLoading: false,
  workshopLoading: false,
};

const fetchMachineTypeListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<MachineType>>
): void => {
  state.machineType = normalize(action.payload, getSchema('id'));
};

const fetchWorkshopListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<Workshop>>
): void => {
  state.workshop = normalize(action.payload, getSchema('workshopId'));
};

const fetchActivityTypeListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<ActivityType>>
): void => {
  state.activityType = normalize(action.payload, getSchema('activityTypeId'));
};

const fetchMachineList = (state: OrganizationReducerState): void => {
  state.machine = { list: null, loading: true, error: null, currentPage: 1 };
};

const fetchMachineListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<Machine>>
): void => {
  state.machine = {
    list: normalize(action.payload, getSchema('machineId')),
    loading: false,
    error: null,
    currentPage: 1,
  };
};

const updateMachineCurrentPage = (
  state: OrganizationReducerState,
  action: PayloadAction<number>
): void => {
  state.machine.currentPage = action.payload;
};

const fetchMaintenanceList = (
  state: OrganizationReducerState,
  action: PayloadAction<MaintenancesRequest>
): void => {
  state.maintenance = {
    items: null,
    meta: emptyMeta,
    loading: true,
    error: null,
    filters: {
      machineId: action.payload.machineId,
      activityId: action.payload.activityId,
      dataRange: action.payload?.dataRange,
      userId: action.payload.userId,
      from: action.payload.from,
      to: action.payload.to,
    },
  };
};

const fetchMaintenanceListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<MaintenancesResponse>
): void => {
  const maintenance = normalize(
    action.payload.items,
    getSchema('maintenanceId')
  );
  state.maintenance = {
    ...state.maintenance,
    items: maintenance,
    meta: { ...action.payload.meta },
    loading: false,
    error: null,
  };
};

const fetchOverviewSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Overview>
): void => {
  state.overview = action.payload;
};

const createActivity = (
  state: OrganizationReducerState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<CreateNewActivity>
): void => {
  state.activityLoading = true;
};

const createActivitySuccess = (state: OrganizationReducerState): void => {
  state.activityLoading = false;
};

const fetCategoryList = (state: OrganizationReducerState): void => {
  state.category.loading = true;
};

const fetCategoryListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<Category>>
): void => {
  state.category.list = normalize(action.payload, getSchema('categoryId'));
  state.category.loading = false;
};

const setSelectedCategory = (
  state: OrganizationReducerState,
  action: PayloadAction<string>
): void => {
  state.category.selectedCategory = action.payload;
};
const createCategory = (
  state: OrganizationReducerState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<CreateCategory>
): void => {
  state.categoryLoading = true;
};

const createCategorySuccess = (state: OrganizationReducerState): void => {
  state.categoryLoading = false;
};

const createSection = (
  state: OrganizationReducerState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<CreateSection>
): void => {
  state.sectionLoading = true;
};

const createSectionSuccess = (state: OrganizationReducerState): void => {
  state.sectionLoading = false;
};

const createWorkshop = (
  state: OrganizationReducerState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<CreateWorkshop>
): void => {
  state.workshopLoading = true;
};

const createWorkshopSuccess = (state: OrganizationReducerState): void => {
  state.workshopLoading = false;
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    fetchMachineTypeListSuccess,
    fetchWorkshopListSuccess,
    fetchActivityTypeListSuccess,
    fetchMachineList,
    fetchMachineListSuccess,
    fetchMaintenanceList,
    fetchMaintenanceListSuccess,
    fetchOverviewSuccess,
    updateMachineCurrentPage,
    createActivity,
    createActivitySuccess,
    fetCategoryList,
    fetCategoryListSuccess,
    setSelectedCategory,
    createCategory,
    createCategorySuccess,
    createSection,
    createSectionSuccess,
    createWorkshop,
    createWorkshopSuccess,
  },
});

const epicActions = {
  fetchMachineTypeList: createAction('fetchMachineType'),
  fetchWorkshopList: createAction<string>('fetchWorkshop'),
  fetchActivityTypeList: createAction('fetchActivityType'),
  createMachine: createAction<CreateNewMachine>('createMachine'),
  // createActivity: createAction<CreateNewActivity>('createActivity'),
  fetchOverview: createAction('fetchOverview'),
  deleteMachine: createAction<{ machineId: string }>('deleteMachine'),
  deleteCategory: createAction<{ categoryId: string }>('deleteCategory'),
};

export const organizationActions = {
  ...organizationSlice.actions,
  ...epicActions,
};

export default organizationSlice.reducer;
