import React from 'react';

const LandingSideComponent: React.FC = () => (
  <div className="col-sm-6 login-img-background-div">
    <div className="p-5 margin-top-set-20vh text-align-center">
      <h2 className="title-tag-style mt-5 font-color-white">
        Effective way to increase your workflow
      </h2>
      <hr />
      <p className="title-tag-style font-color-white">
        We are giving solution to note down your day to day maintenance in our
        platform.
      </p>
      <p className="normal-p-tag-style font-color-white">
        Go to our website and make a meeting schedule with our agent and get
        more details about our solution how to compatible with your company.
      </p>
      <p className="normal-p-tag-style mt-5 font-color-white">
        <a className="button-for-webpage" href="https://www.orizel.com">
          Try it now
        </a>
      </p>
    </div>
  </div>
);

export default LandingSideComponent;
