import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AppButton, AppDropdown } from '../../../../shared/forms/components';
import { useSelector, useDispatch } from 'react-redux';
import { getCategoryList } from '../../redux/organizationSelector';
import { useHistory } from 'react-router';
import { DASHBOARD } from '../../routes/index';
import { organizationActions } from '../../redux/organizationSlice';
import storage from '../../../../services/storage/localStorage';
import authConstants from '../../../../constants/storage';

interface Settings {
  category: string;
}

const validationSchema = Yup.object().shape({
  category: Yup.string().required(),
});

const CompanyHome: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const categoryList = useSelector(getCategoryList);

  const handleOnSubmit = (values: Settings) => {
    dispatch(organizationActions.setSelectedCategory(values.category));
    storage.set(authConstants.SELECTED_CATEGORY, values.category);
    history.push(DASHBOARD);
  };

  return (
    <div className="container container-width-set-none">
      <div className="row justify-content-center">
        <div className="col-sm-6 margin-top-set-5vh">
          <div className="row justify-content-md-center">
            <Formik
              initialValues={{ category: '' }}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              <Form className="p-4 my-2">
                <div className="d-flex justify-content-center mb-5 text-center" />
                <h2 className="title-tag-style my-2 text-left mb-4">Home</h2>
                <AppDropdown
                  name="category"
                  label="Select Category*"
                  placeholder="Enter Machine Type"
                >
                  <option defaultChecked value="">
                    --Please choose an option--
                  </option>
                  {categoryList.map((category) => (
                    <option
                      key={category.categoryId}
                      value={category.categoryId}
                    >
                      {category.categoryName}
                    </option>
                  ))}
                </AppDropdown>
                <AppButton type="submit" text="Go To Dashboard" />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyHome;
