import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import {
  Machine,
  CreateMachineResponse,
  CreateMachine,
  ConfigMachines,
} from '../../models/machine';

const MACHINE_ENDPOINT = '/machine/';
const MACHINE_CONFIG_ENDPOINT = `${MACHINE_ENDPOINT}config`;

export const getMachines = (): Observable<Array<Machine>> =>
  GET(MACHINE_ENDPOINT);

export const getMachine = (machineId: string): Observable<Machine> =>
  GET(`${MACHINE_ENDPOINT}${machineId}`);

export const createMachine = (
  machine: CreateMachine
): Observable<CreateMachineResponse> => POST(MACHINE_ENDPOINT, { ...machine });

export const updateMachine = (machine: Machine): Observable<Machine> =>
  PUT(`${MACHINE_ENDPOINT}${machine.machineId}`, { ...machine });

export const machinesConfig = (
  configMachines: ConfigMachines
): Observable<ConfigMachines> =>
  POST(MACHINE_CONFIG_ENDPOINT, { ...configMachines });

export const deleteMachine = (machineId: string): Observable<null> =>
  DELETE(`${MACHINE_ENDPOINT}${machineId}`);

export const getMachineQRCode = (machineId: string): Observable<string> =>
  GET(`${MACHINE_ENDPOINT}${machineId}/QRCode`, undefined, {
    responseType: 'blob',
  });
