import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppButton, AppInput } from '../../../shared/forms/components';
import AppDropdown from '../../../shared/forms/components/AppDropDown/AppDropDown.component';
import { User } from '../../../models/user';
import { getUserList } from '../../../app/redux/appSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { CreateSection } from '../../../models/section';
import { organizationActions } from '../../dashboard/redux/organizationSlice';

const getInitialValues = (section: CreateSection) => ({
  ...section,
});

const validationSchema = Yup.object().shape({
  sectionName: Yup.string().required(),
  responsibleUserId: Yup.string().required(),
});

interface Props {
  categoryId: string;
  state: RootState;
  users: Array<User>;
  createSection: (section: CreateSection) => void;
  onSubmit?: () => void;
}

const CreateSectionForm: React.FC<Props> = ({
  categoryId,
  users,
  createSection,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const getUsersOptions = () =>
    users.map((user) => (
      <option key={user.userId} value={user.userId}>
        {user.email}
      </option>
    ));

  const onSubmitHandler = (values: CreateSection) => {
    onSubmit && onSubmit();
    createSection(values);
  };

  return (
    <Formik
      initialValues={getInitialValues({
        categoryId: categoryId,
        sectionName: '',
        responsibleUserId: '',
      })}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <AppInput
            name="sectionName"
            label="Section Name*"
            placeholder="Enter Section Name"
            type="text"
            loading={isLoading}
          />
          <AppDropdown
            name="responsibleUserId"
            label="Responsible User"
            placeholder="Responsible User"
            loading={isLoading}
          >
            <option defaultChecked value="">
              Unassigned
            </option>
            {getUsersOptions()}
          </AppDropdown>
          <AppButton type="submit" text="Create" loading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  users: getUserList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createSection: (section: CreateSection) => {
    dispatch(organizationActions.createSection(section));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSectionForm);
