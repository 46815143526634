import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { getUser } from '../../../services/api/userApi';
import { User } from '../../../models/user';
import { Maintenance } from '../../../models/maintenance';
import { getMaintenance } from '../../../services/api/maintenanceApi';
import MaintenanceDetails from '../component/MaintenanceDetails';
import MaintenanceFinishPage from './MaintenanceFinishPage';

interface ParamsProps {
  id: string;
}
const MaintenancesView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<ParamsProps>();
  const [maintenanceDetails, setMaintenanceDetails] = useState<Maintenance>();
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    getMaintenance(id).subscribe(
      (response: Maintenance) => {
        setMaintenanceDetails(response);
        setIsLoading(false);
      },
      (err: Error) => {
        setError(err.message);
        setIsLoading(false);
      }
    );
  }, []);

  if (!isLoading && !error && maintenanceDetails) {
    return <MaintenanceFinishPage maintenance={maintenanceDetails} />;
  }
  return <h1>Loading</h1>;
};
export default MaintenancesView;
