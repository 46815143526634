import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { authActions } from '../redux/authSlice';
import { getInitialValues, getValidationSchema } from '../forms/login';
import LandingSideComponent from '../components/LandingSideComponent';
import TopBar from '../../../app/components/TopBar/TopBar';

import './login.css';

const Login: React.FC<Props> = (props) => {
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState('');
  const history = useHistory();

  const handleOnLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setAuthLoading(true);
    setAuthError('');
    props.login({ email, password });
  };

  const navigateToForgotPassword = () => {
    history.push('/reset-password');
  };

  useEffect(() => {
    document.title = 'Orizel | Login';
  }, []);

  return (
    <div className="login">
      {/* <TopBar /> */}
      <div className="container container-width-set-none">
        <div className="row justify-content-center">
          <div className="col-sm-6 margin-top-set-5vh">
            <div className="row justify-content-md-center">
              <div className="col-sm-8">
                <Formik
                  initialValues={getInitialValues()}
                  onSubmit={handleOnLogin}
                  validationSchema={getValidationSchema()}
                >
                  {() => (
                    <Form className="p-4 my-2">
                      <div className="d-flex justify-content-center mb-5 text-center" />
                      <div className="text-center mt-3">
                        <img
                          className="logo-login"
                          src="images/orizel_logo/orizel_logo.jpg"
                          alt="logo"
                        />
                      </div>
                      <h2 className="title-tag-style my-2 text-center mb-4">
                        LOG IN
                      </h2>
                      <AppInput
                        label="Email"
                        loading={authLoading}
                        name="email"
                        placeholder="Enter Email"
                        type="email"
                      />
                      <AppInput
                        label="Password"
                        loading={authLoading}
                        name="password"
                        placeholder="Enter Password"
                        type="password"
                      />
                      <AppButton
                        loading={authLoading}
                        type="submit"
                        text="Login"
                      />
                      {authError && (
                        <span className="d-block text-danger text-center mt-2">
                          {authError}
                        </span>
                      )}
                      <div className="text-center mt-3">
                        <p className="normal-p-tag-style">
                          <AppButton
                            text="Forgot password?"
                            link
                            onClick={navigateToForgotPassword}
                          />
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <LandingSideComponent />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = () => ({});

const dispatchProps = {
  login: authActions.login,
};
type DispatchProps = typeof dispatchProps;

type Props = DispatchProps;
export default withRouter(connect(mapStateToProps, dispatchProps)(Login));
