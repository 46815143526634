import React, { useEffect, useState } from 'react';
import Select from 'react-select';

interface DropDownElement {
  value: string;
  label: string;
}

interface Props {
  dataList: Array<DropDownElement>;
  placeholder: string;
  nameTop: string;
  selectedObjectChanged?: (value: DropDownElement | any) => void;
  selectedValueElement: string;
}

const SearchDropDown: React.FC<Props> = ({
  dataList,
  placeholder,
  nameTop,
  selectedObjectChanged,
  selectedValueElement,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    selectedValueElement
  );
  function handleChange(option: DropDownElement | null) {
    selectedObjectChanged && selectedObjectChanged(option);

    if (option !== null) {
      setSelectedValue(option.value);
    }
  }

  return (
    <div>
      <p>{nameTop}</p>
      <Select
        value={dataList.filter((option) => option.value === selectedValue)}
        options={dataList}
        placeholder={placeholder}
        cacheOptions={true}
        onChange={(value) => handleChange(value)}
      />
    </div>
  );
};

export default SearchDropDown;
