import React, { useState } from 'react';
import { Label } from 'reactstrap';

import './switch.css';

interface Props {
  name: string;
  isOn?: boolean;
  leftLabel: string;
  rightLabel: string;
  label?: string;
  disable?: boolean;
  handleToggle?: (name: string, value: boolean) => void;
}

export const DualToggleSwitch: React.FC<Props> = ({
  name,
  isOn,
  leftLabel,
  rightLabel,
  label,
  disable = false,
  handleToggle,
}: Props) => {
  const [checked, setChecked] = useState(isOn || false);

  const onChangeHandler = () => {
    handleToggle && handleToggle(name, !checked);
    setChecked(!checked);
  };

  return (
    <div className="dual-toggle-switch">
      {label && <Label>{label}</Label>}
      <Label for={name} className="dual-toggle-switch-text">
        {leftLabel}
      </Label>
      <input
        className="dual-toggle-switch-checkbox"
        id={name}
        type="checkbox"
        checked={checked}
        onChange={onChangeHandler}
        disabled={disable}
      />
      <label className="dual-toggle-switch-label" htmlFor={name}>
        <span className="dual-toggle-switch-button" />
      </label>
      <Label for={name} className="dual-toggle-switch-text">
        {rightLabel}
      </Label>
    </div>
  );
};
