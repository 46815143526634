export const maintenanceGroupTypes = {
  MACHINE: 0,
  MACHINE_TYPE: 1,
  ACTIVITY_TYPE: 2,
  USER: 3,
};

export const periodTypes = {
  THIS_WEEK: 1,
  LAST_WEEK: 2,
  THIS_MONTH: 3,
  LAST_MONTH: 4,
};
