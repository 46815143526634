import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment-timezone';
import { BiAddToQueue } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdViewHeadline } from 'react-icons/md';

import {
  AppModal,
  AppPagination,
  AppTable,
  WarningModal,
} from '../../../shared/components';
import CreateCompanyForm from '../forms/CreateCompanyForm';
import { appAction } from '../../../app/redux/appSlice';
import { ListRequest, ListResponseState } from '../../../models/api';
import { RootState } from '../../../app/redux/rootReducer';
import {
  companiesSelector,
  getCompanyList,
  getCompanyType,
} from '../../../app/redux/appSelector';
import { Company } from '../../../models/company';
import { CompanyType } from '../../../models/companyType';
import { deleteCompany } from '../../../services/api/companyApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { Link } from 'react-router-dom';

interface Props {
  getCompanyList: () => Array<Company>;
  companiesData: () => ListResponseState;
  fetchCompanies: (param?: ListRequest) => void;
  getCompanyTypeDetails: (companyTypeId: string) => CompanyType | undefined;
  addNotification: (
    title: string,
    message: string,
    severity: 'success' | 'danger'
  ) => void;
}

const CompanyList: React.FC<Props> = ({
  getCompanyList,
  companiesData,
  fetchCompanies,
  getCompanyTypeDetails,
  addNotification,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [companyList, setCompanyList] = useState<Array<Company>>([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company>();

  const { items, loading, meta } = companiesData();

  useEffect(() => {
    if (items) {
      setCompanyList(getCompanyList());
    } else if (!loading) {
      fetchCompanies();
    }
  }, [meta]);

  const handleCallBack = (pages: number, from: number, to: number) => {
    fetchCompanies({ from, to: to + 1 });
  };

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const toggleWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const handleOnClickDelete = (company: Company) => {
    setSelectedCompany(company);
    toggleWarningModal();
  };

  const handleOnClickWarning = () => {
    if (selectedCompany) {
      deleteCompany(selectedCompany.companyId).subscribe(
        () => {
          addNotification(`Success`, 'Successfully deleted company', 'success');
          fetchCompanies();
          toggleWarningModal();
        },
        (error: Error) => {
          addNotification(
            `Error: Couldn't Delete Company`,
            error.message,
            'danger'
          );
        }
      );
    }
  };

  const getCompanyTableData = () => {
    const array: Array<JSX.Element> = [];
    companyList.map((company, i) => {
      array.push(
        <tr key={company.companyId}>
          <th scope="row">{(meta.from ? meta.from : 1) + i}</th>
          <td>{company.name}</td>
          <td>
            {getCompanyTypeDetails(company.companyTypeId)?.companyName || ''}
          </td>
          <td>
            {moment(company.createdAt)
              .tz(moment.tz.guess())
              .format('YYYY-MM-DD')}
          </td>
          <td className="view-td">
            <a
              onClick={() => handleOnClickDelete(company)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <AiOutlineDelete size={20} />
            </a>
          </td>
          <td>
            <Link
              to={'company/'.concat(company.companyId)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} />
            </Link>
          </td>
        </tr>
      );
    });
    return array;
  };

  return (
    <div className="container main-container-div">
      <div>
        <a
          onClick={handleModalToggle}
          className="add-new-link-btn ft-size-16 float-right"
        >
          <BiAddToQueue size={18} /> Add Company
        </a>
        <AppModal
          onClose={handleModalToggle}
          show={showModal}
          title="Add Company"
        >
          <CreateCompanyForm onSubmitCallBack={handleModalToggle} />
        </AppModal>
      </div>
      <div className="table_outer_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 header-txt">
              <h5>Company List</h5>
            </div>
          </div>
        </div>
        {loading ? (
          <div>Loading</div>
        ) : (
          <>
            <AppTable
              head={[
                '#',
                'Company Name',
                'Company Type',
                'Created Date',
                '',
                '',
              ]}
              data={getCompanyTableData()}
              limit={10}
              pagination={false}
            />
            <AppPagination
              dataLength={meta.length}
              callBack={handleCallBack}
              currentPage={~~(meta.from / 10) + 1}
            />
            <WarningModal
              show={showWarningModal}
              onClose={toggleWarningModal}
              onClickOk={handleOnClickWarning}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getCompanyList: () => getCompanyList(state),
  companiesData: () => companiesSelector(state),
  getCompanyTypeDetails: (companyTypeId: string) =>
    getCompanyType(state, companyTypeId),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanies: (param?: ListRequest) => {
    dispatch(appAction.fetchCompanyList(param ? { ...param } : {}));
  },
  addNotification: (
    title: string,
    message: string,
    severity: 'success' | 'danger'
  ) => {
    dispatch(
      notificationActions.setToast({
        severity,
        message,
        title,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
