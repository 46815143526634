import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { RootState } from './rootReducer';
import { AppReducerState } from './appSlice';
import { UserRole } from '../../models/userRole';
import { getSchema, ItemList } from './schema';
import { Company } from '../../models/company';
import { CompanyType } from '../../models/companyType';
import { User } from '../../models/user';

const appSelector = (state: RootState) => state.app;

const getAppLoading = createSelector(
  appSelector,
  ({ appLoading }: AppReducerState): boolean => appLoading
);

const userRolesSelector = (state: RootState) => state.app.userRoles;

const companiesSelector = (state: RootState) => state.app.companies;

const companyTypesSelector = (state: RootState) => state.app.companyTypes;

const companyTypeSelector = (state: RootState, key: string) => ({
  companyTypes: state.app.companyTypes,
  key,
});

const usersSelector = (state: RootState) => state.app.users;

const userSelector = (state: RootState, key: string) => ({
  users: state.app.users,
  key,
});

const getUserRoles = createSelector(
  userRolesSelector,
  (userRoles): Array<UserRole> => {
    if (userRoles) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<UserRole> = denormalize(
        userRoles.result,
        getSchema('id'),
        userRoles.entities
      );
      return array;
    }
    return [];
  }
);

const userRoleSelector = (state: RootState, key: string) => ({
  userRoles: state.app.userRoles,
  key,
});

const getUserRole = createSelector(userRoleSelector, ({ userRoles, key }) => {
  if (userRoles?.entities) {
    const list = userRoles?.entities as ItemList<UserRole>;
    return list.items[key];
  }
  return undefined;
});

const getCompanyList = createSelector(
  companiesSelector,
  ({ items }): Array<Company> => {
    if (items) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<Company> = denormalize(
        items.result,
        getSchema('companyId'),
        items.entities
      );
      return array;
    }
    return [];
  }
);

const getCompanyTypeList = createSelector(
  companyTypesSelector,
  (companyTypes): Array<CompanyType> => {
    if (companyTypes) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<CompanyType> = denormalize(
        companyTypes.result,
        getSchema('companyTypeId'),
        companyTypes.entities
      );
      return array;
    }
    return [];
  }
);

const getCompanyType = createSelector(
  companyTypeSelector,
  ({ companyTypes, key }) => {
    if (companyTypes?.entities) {
      const list = companyTypes?.entities as ItemList<CompanyType>;
      return list.items[key];
    }
    return undefined;
  }
);

const getUserList = createSelector(
  usersSelector,
  (users): Array<User> => {
    if (users) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<User> = denormalize(
        users.result,
        getSchema('userId'),
        users.entities
      );
      return array;
    }
    return [];
  }
);

const getUser = createSelector(userSelector, ({ users, key }) => {
  if (users?.entities) {
    const list = users?.entities as ItemList<User>;
    return list.items[key];
  }
  return undefined;
});

export {
  getAppLoading,
  getUserRole,
  getUserRoles,
  getCompanyList,
  companiesSelector,
  getCompanyTypeList,
  getCompanyType,
  getUserList,
  getUser,
};
