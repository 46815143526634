import { ActivityType } from '../models/activityType';
import { Maintenance } from '../models/maintenance';
import { Machine } from '../models/machine';
import { User } from '../models/user';

/*eslint-disable */
export const activityTypesGroup = (activityTypes: Array<ActivityType>) =>
  activityTypes.reduce(function (r, a) {
    r[a.machineTypeId] = r[a.machineTypeId] || [];
    r[a.machineTypeId].push(a);
    return r;
  }, Object.create(null));

export const maintenanceGroupByActivityType = (
  maintenanceList: Array<Maintenance>
) =>
  maintenanceList.reduce(function (r, a) {
    r[a.activity.activityTypeId] = r[a.activity.activityTypeId] || [];
    r[a.activity.activityTypeId].push(a);
    return r;
  }, Object.create(null));

export const maintenanceGroupByMachine = (
  maintenanceList: Array<Maintenance>
) =>
  maintenanceList.reduce(function (r, a) {
    r[a.activity.machine.machineId] = r[a.activity.machine.machineId] || [];
    r[a.activity.machine.machineId].push(a);
    return r;
  }, Object.create(null));

export const maintenanceGroupByUser = (maintenanceList: Array<Maintenance>) =>
  maintenanceList.reduce(function (r, a) {
    r[a.user.userId] = r[a.user.userId] || [];
    r[a.user.userId].push(a);
    return r;
  }, Object.create(null));

export const machinesGroupByType = (machines: Array<Machine>) =>
  machines.reduce(function (r, a) {
    r[a.machineTypeId] = r[a.machineTypeId] || [];
    r[a.machineTypeId].push(a);
    return r;
  }, Object.create(null));

export const usersGroupByUserRole = (users: Array<User>) =>
  users.reduce(function (r, a) {
    r[a.roleId] = r[a.roleId] || [];
    r[a.roleId].push(a);
    return r;
  }, Object.create(null));
/* eslint-enable */
