import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import moment from 'moment-timezone';

import { chartTypes } from '../../../constants/chart';
import {
  getCompareCostOverPeriod,
  getCostOverPeriod,
  getMaintenances,
} from '../../../services/api/maintenanceApi';
import AppChart from '../../../shared/components/AppChart/AppChart';
import MaintenanceGroup from '../../maintenance/component/MaintenanceGroup';
import { notificationActions } from '../../../app/redux/notification.slice';
import { periodTypes } from '../../../constants/maintenance';
import {
  CostOverTimeRequest,
  DashboardFilter,
  Maintenance,
} from '../../../models/maintenance';
import {
  maintenanceGroupByUser,
  usersGroupByUserRole,
} from '../../../utils/groupUtils';
import { RootState } from '../../../app/redux/rootReducer';
import DashboardFilterBar from '../../dashboard/component/DashboardFilterBar';
import { getUserList, getUserRole } from '../../../app/redux/appSelector';
import { Overview } from '../../../models/overview';
import { UserRole } from '../../../models/userRole';
import { User } from '../../../models/user';
import HomeTopDashboard from '../../dashboard/component/HomeTopDashboard/HomeTopDashboard';

interface KeyValueMap<T> {
  [key: string]: T[];
}

interface ChartData {
  Date: string;
  'Current Cost': number | null;
  'Previous Cost'?: number | null;
}

interface periodProps extends CostOverTimeRequest {
  periodType: number;
}

interface UserGraphItem {
  userId: string;
  email: string;
  me: number;
  others: number;
  ongoing: number;
}

interface Props {
  overview: Overview | null;
  users: Array<User>;
  getRole: (id: string) => UserRole | undefined;
}

const CompanyOverview: React.FC<Props> = ({
  overview,
  users,
  getRole,
}: Props) => {
  const today = moment().tz(moment.tz.guess());

  const [costOverTime, setCostOverTime] = useState<Array<ChartData>>([]);
  const [period, setPeriod] = useState<periodProps>({
    periodType: periodTypes.THIS_MONTH,
    date: today.clone().startOf('month').format('YYYY-MM-DD'),
    period: today.date(),
    compareDate: today
      .clone()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD'),
    comparePeriod: today.clone().subtract(1, 'months').daysInMonth(),
  });
  const [isCompared, setIsCompared] = useState(true);
  const [maintenanceArray, setMaintenanceArray] = useState<Array<Maintenance>>(
    []
  );
  const [userGraphData, setUserGraphData] = useState<Array<UserGraphItem>>([]);
  const [completedMaintenanceCount, setCompletedMaintenanceCount] = useState(0);

  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const usersTypes = usersGroupByUserRole(users) as KeyValueMap<User>;

  const getUserTypeGraphData = () =>
    Object.keys(usersTypes).map((key) => ({
      type: getRole(key)?.roleName || '',
      count: usersTypes[key].length,
    }));

  const getOverviewPieChartData = () => {
    const graphData = [];
    if (overview) {
      graphData.push({
        key: 'Delayed',
        data: overview.delayedMaintenances.length,
      });
      graphData.push({
        key: 'Ongoing',
        data: overview.ongoingMaintenances.length,
      });
      graphData.push({
        key: 'Completed',
        data: completedMaintenanceCount,
      });
    }
    return graphData;
  };

  const getOverviewPercentage = () => {
    if (overview) {
      const sum =
        completedMaintenanceCount +
        overview.ongoingMaintenances.length +
        overview.delayedMaintenances.length;
      return `${String(Math.round((completedMaintenanceCount * 100) / sum))} %`;
    }
    return '';
  };

  const errorDispatch = (error: Error) => {
    dispatch(
      notificationActions.setToast({
        severity: 'danger',
        message: error.message,
        title: 'Error: Failed To Load Graph',
      })
    );
  };

  useEffect(() => {
    if (isCompared) {
      getCompareCostOverPeriod(period).subscribe(
        (response) => {
          setCostOverTime(
            response.compareGraphDataList.map((item, index) => ({
              Date: item.date,
              'Current Cost':
                response.graphDataList.length <= index + 1 &&
                response.graphDataList[index]
                  ? response.graphDataList[index].cost
                  : null,
              'Previous Cost': item.cost,
            }))
          );
        },
        (error: Error) => {
          errorDispatch(error);
        }
      );
    } else {
      getCostOverPeriod(period).subscribe(
        (response) => {
          setCostOverTime(
            response.map((item) => ({
              Date: item.date,
              'Current Cost': item.cost,
            }))
          );
        },
        (error: Error) => {
          errorDispatch(error);
        }
      );
    }
  }, [period, dispatch]);

  const getResponsibleGraphData = (maintenanceArray: Array<Maintenance>) => {
    let completedSum = 0;
    const orderByUser = maintenanceGroupByUser(
      maintenanceArray
    ) as KeyValueMap<Maintenance>;
    setUserGraphData(
      Object.keys(orderByUser).map((key) => {
        let me = 0;
        let others = 0;
        let ongoing = 0;
        for (let i = 0; i < orderByUser[key].length; i = i + 1) {
          if (orderByUser[key][i].endedByUser) {
            if (
              key === orderByUser[key][i].startedByUser.userId &&
              key === orderByUser[key][i].endedByUser?.userId
            ) {
              me = me + 1;
            } else {
              others = others - 1;
            }
          } else {
            ongoing = ongoing + 1;
          }
        }
        completedSum = completedSum + me - others;
        return {
          userId: key,
          email: orderByUser[key][0].user.email,
          me,
          others,
          ongoing,
        };
      })
    );
    setCompletedMaintenanceCount(completedSum);
  };

  useEffect(() => {
    getMaintenances({
      dataRange: `${period.date},${moment(period.date)
        .tz(moment.tz.guess())
        .add(period.period - 1, 'days')
        .format('YYYY-MM-DD')}`,
    }).subscribe(
      (response) => {
        getResponsibleGraphData(response.items);
        setMaintenanceArray(response.items);
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: `Error: Couldn't Fetch Maintenance`,
          })
        );
      }
    );
  }, [dispatch, period]);

  const onChangeHandler = (filter: DashboardFilter, compared: boolean) => {
    setIsCompared(compared);
    setPeriod(filter);
  };

  return (
    <div className="container pt-1 main-container-div">
      <DashboardFilterBar onChange={onChangeHandler} />
      <HomeTopDashboard
        delayed={overview?.delayedMaintenances.length}
        onGoing={overview?.ongoingMaintenances.length}
        upComing={overview?.upcomingMaintenances.length}
        users={users.length}
      />
      <Row>
        <Col md={8}>
          <AppChart
            label="Cost Over Day"
            chartType={chartTypes.LINE}
            data={costOverTime}
            dataKey={['Date', 'Current Cost', 'Previous Cost']}
            compare={isCompared}
          />
        </Col>
        <Col md={4}>
          <AppChart
            label="Overview"
            width={300}
            height={200}
            chartType={chartTypes.PIE}
            data={getOverviewPieChartData()}
            innerText={getOverviewPercentage()}
            dataKey={[]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <AppChart
            label="User Responsibility"
            chartType={chartTypes.POSITIVE_NEGATIVE}
            data={userGraphData}
            dataKey={['email', 'others', 'me', 'ongoing']}
          />
        </Col>
        <Col md={4}>
          <AppChart
            label="Users"
            width={300}
            height={200}
            chartType={chartTypes.BAR}
            data={getUserTypeGraphData()}
            dataKey={['type', 'count']}
          />
        </Col>
      </Row>
      <div>
        <MaintenanceGroup maintenanceArray={maintenanceArray} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  overview: state.organization.overview,
  users: getUserList(state),
  getRole: (id: string) => getUserRole(state, id),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyOverview);
