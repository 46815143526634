import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import {
  Activity,
  CreateActivity,
  ActivityGetRequest,
} from '../../models/activity';

const ACTIVITY_ENDPOINT = '/activity/';

export const getActivities = (
  payload?: ActivityGetRequest
): Observable<Array<Activity>> => GET(ACTIVITY_ENDPOINT, { ...payload });

export const getActivity = (id: string): Observable<Activity> =>
  GET(`${ACTIVITY_ENDPOINT}${id}`);

export const createActivity = (
  activity: CreateActivity
): Observable<Activity> => POST(ACTIVITY_ENDPOINT, { ...activity });

export const updateActivity = (activity: Activity): Observable<Activity> =>
  PUT(`${ACTIVITY_ENDPOINT}${activity.activityId}`, { ...activity });

export const deleteActivity = (activityId: string): Observable<null> =>
  DELETE(`${ACTIVITY_ENDPOINT}${activityId}`);
