import React, { useState, useEffect } from 'react';
import { Badge } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';

import { RootState } from '../../../../app/redux/rootReducer';
import { NewAuth } from '../../../../models/auth';
import { getNewAuths } from '../../../../services/api/userApi';
import { getUser, getUserRole } from '../../../../app/redux/appSelector';
import { UserRole } from '../../../../models/userRole';
import { AppTable, Loader } from '../../../../shared/components';
import { User } from '../../../../models/user';
import { notificationActions } from '../../../../app/redux/notification.slice';

interface Props {
  getRole: (id: string) => UserRole | undefined;
  refresh?: boolean;
  getUserDetails: (userId: string) => User | undefined;
}

const PendingUserList: React.FC<Props> = ({
  getRole,
  getUserDetails,
  refresh,
}: Props) => {
  const [pendingUsers, setPendingUsers] = useState<Array<NewAuth>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    getNewAuths().subscribe(
      (response) => {
        setPendingUsers(response);
        setIsLoading(false);
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: `Error: Couldn't Fetch Pending Users`,
          })
        );
        setIsLoading(false);
      }
    );
  }, [dispatch, refresh]);

  function renderRoleTd(value: string | undefined) {
    switch (value) {
      case 'SUPER_ADMIN':
        return (
          <th>
            <Badge color="danger">Super Admin</Badge>
          </th>
        );
      case 'ADMIN':
        return (
          <th>
            <Badge color="primary">Admin</Badge>
          </th>
        );
      case 'MAINTENANCE':
        return (
          <th>
            <Badge color="success">Maintenance</Badge>
          </th>
        );
      case 'VIEWERS':
        return (
          <th>
            <Badge color="warning">Viewer</Badge>
          </th>
        );
    }
  }

  const getPendingUserData = () => {
    const array: Array<JSX.Element> = [];
    {
      pendingUsers.map((pendingUser, i = 1) =>
        array.push(
          <tr key={pendingUser.email}>
            <th scope="row">{i + 1}</th>
            <th>{pendingUser.email}</th>
            {renderRoleTd(getRole(pendingUser.roleId)?.roleName)}
            <th>{getUserDetails(pendingUser.createdUser)?.email || ''}</th>
          </tr>
        )
      );
    }

    return array;
  };

  return (
    <div className="table_outer_div">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 header-txt">
            <h5>PENDING USER LIST</h5>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <AppTable
          head={['#', 'Email', 'Role', 'Invited By']}
          data={getPendingUserData()}
          limit={5}
          pagination={true}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getRole: (id: string) => getUserRole(state, id),
  getUserDetails: (userId: string) => getUser(state, userId),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PendingUserList);
