import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { Maintenance } from '../../../models/maintenance';
import { updateMaintenance } from '../../../services/api/maintenanceApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { Badge } from 'reactstrap';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { RootState } from '../../../app/redux/rootReducer';
import { DATE_FORMAT } from '../../../constants/dateTime';

const getInitialValues = (values: Maintenance) => ({
  reason: '',
  replacementNote: '',
  replacementCost: 0,
  maintenanceCost: 0,
  ...values,
});

const validationSchema = Yup.object().shape({
  reason: Yup.string().nullable(),
  replacementNote: Yup.string().nullable(),
  replacementCost: Yup.number().nullable(),
  maintenanceCost: Yup.number().nullable(),
});

interface Props {
  startedMaintenance: Maintenance;
}

const EndMaintenanceForm: React.FC<Props> = ({ startedMaintenance }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(
    startedMaintenance.endTime ? true : false
  );
  const dispatch = useDispatch();
  const maintenanceFilters = useSelector(
    (state: RootState) => state.organization.maintenance.filters
  );

  const onSubmitHandler = (values: Maintenance) => {
    setIsLoading(true);
    updateMaintenance({
      ...values,
      maintenanceCost: Number(values.maintenanceCost),
      replacementCost: Number(values.replacementCost),
      endTime: moment().tz(moment.tz.guess()).format(),
    }).subscribe(
      (response: Maintenance) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Maintenance marked as completed',
            title: 'Success',
          })
        );
        dispatch(
          organizationActions.fetchMaintenanceList(
            maintenanceFilters || {
              dataRange: `${moment()
                .tz(moment.tz.guess())
                .add(-1, 'months')
                .format(DATE_FORMAT)},${moment()
                .tz(moment.tz.guess())
                .format(DATE_FORMAT)}`,
            }
          )
        );
        dispatch(organizationActions.fetchOverview());
        setIsDone(true);
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To End Maintenance',
          })
        );
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  return (
    <div className="table_outer_div">
      <div className="container pr-5 pl-5 pt-2 pb-2">
        <div>
          {isDone && (
            <h4>
              <Badge color="success">Completed</Badge>
            </h4>
          )}
          {!isDone && (
            <h4>
              <Badge color="info">In Progress</Badge>
            </h4>
          )}
        </div>
        <div>
          <Formik
            initialValues={getInitialValues(startedMaintenance)}
            onSubmit={onSubmitHandler}
            validationSchema={validationSchema}
          >
            <Form>
              <AppInput
                name="replacementNote"
                label="Replacement Note"
                placeholder="Replacement Note"
                type="text"
                loading={isLoading || isDone}
              />
              <AppInput
                name="replacementCost"
                label="Replacement Cost"
                placeholder="Replacement Cost"
                type="number"
                loading={isLoading || isDone}
              />
              <AppInput
                name="maintenanceCost"
                label="Maintenance Cost"
                placeholder="Maintenance Cost"
                type="number"
                loading={isLoading || isDone}
              />
              <AppInput
                name="reason"
                label="Reason"
                placeholder="Reason"
                type="text"
                loading={isLoading || isDone}
              />
              <AppButton
                type="submit"
                text="Finish Activity"
                loading={isLoading || isDone}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EndMaintenanceForm;
