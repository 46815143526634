import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect, useDispatch } from 'react-redux';

import { UpdateUserViewModel, User } from '../../../models/user';
import { UserRole } from '../../../models/userRole';
import {
  AppButton,
  AppDropdown,
  AppInput,
} from '../../../shared/forms/components';
import { RootState } from '../../../app/redux/rootReducer';
import { getUserRoles } from '../../../app/redux/appSelector';
import { updateUser } from '../../../services/api/userApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { roleTypes } from '../../../constants/userRole';

const getInitialValues = (user: User) => ({
  ...user,
  firstName: user.firstName || '',
  lastName: user.lastName || '',
  companyId: user.companyId || '',
});

const validationSchema = Yup.object().shape({
  roleId: Yup.string().required('This is a required field'),
  firstName: Yup.string().required('This is a required field'),
  lastName: Yup.string().required('This is a required field'),
});

interface Props {
  userRoles: Array<UserRole>;
  user: User;
  isSelfUpdate?: boolean;
  userUpdateSuccessCallBack?: (user: User) => void;
}

const UpdateUserForm: React.FC<Props> = ({
  userRoles,
  user,
  isSelfUpdate = true,
  userUpdateSuccessCallBack,
}: Props) => {
  const dispatch = useDispatch();

  const onSubmitHandler = (values: UpdateUserViewModel) => {
    updateUser({
      ...user,
      ...values,
    }).subscribe(
      (response) => {
        userUpdateSuccessCallBack && userUpdateSuccessCallBack(response);
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully updated details',
            title: 'Success',
          })
        );
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Update Details',
          })
        );
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues(user)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <AppInput
          name="email"
          label="Email*"
          placeholder="Email*"
          type="email"
          readOnly
        />
        <AppInput
          name="firstName"
          label="First Name*"
          placeholder="First Name*"
          type="text"
          readOnly={!isSelfUpdate}
        />
        <AppInput
          name="lastName"
          label="Last Name*"
          placeholder="Last Name*"
          type="text"
          readOnly={!isSelfUpdate}
        />
        <AppDropdown
          name="roleId"
          label="Role*"
          placeholder="Enter Role"
          readOnly={isSelfUpdate}
        >
          <option defaultChecked value="">
            --Please choose an option--
          </option>
          {userRoles &&
            userRoles.map((userRole) => (
              <option key={userRole.roleId} value={userRole.roleId}>
                {userRole.roleName}
              </option>
            ))}
        </AppDropdown>
        <AppButton type="submit" text="Update" />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  userRoles: getUserRoles(state).filter(
    (userRole) => userRole.roleName !== roleTypes.SUPER_ADMIN
  ),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserForm);
