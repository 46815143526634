import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';

import { Activity, UpdateActivityViewModel } from '../../../models/activity';
import DualToggleSwitch from '../../../shared/forms/components/ToggleSwitch';
import {
  AppButton,
  AppInput,
  AppFrequencyInput,
  AppDropdown,
} from '../../../shared/forms/components';
import { updateActivity } from '../../../services/api/activityApi';
import { RootState } from '../../../app/redux/rootReducer';
import { getActivityType } from '../../dashboard/redux/organizationSelector';
import { notificationActions } from '../../../app/redux/notification.slice';
import { updateActivityType } from '../../../services/api/activityTypeApi';
import { ActivityType } from '../../../models/activityType';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { getUserList } from '../../../app/redux/appSelector';
import { AppCheckBox } from '../../../shared/components';

const getInitialValues = (activity: Activity, activityType?: ActivityType) => ({
  ...activity,
  nextDueDate: moment(activity.nextDueDate)
    .tz(moment.tz.guess())
    .format('YYYY-MM-DD'),
  frequency: activityType ? activityType.frequency : '',
  applySameActivity: false,
});

const validationSchema = Yup.object().shape({
  nextDueDate: Yup.date().min(
    moment().tz(moment.tz.guess()).format('YYYY-MM-DD'),
    'Date cannot be in the past'
  ),
  frequency: Yup.number().min(1).required('This is a required field'),
});

interface Props {
  activity: Activity;
  isMachineOn?: boolean;
  handleOnSubmit?: () => void;
}

const EditActivityForm: React.FC<Props> = ({
  activity,
  isMachineOn,
  handleOnSubmit,
}: Props) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const activityType = getActivityType(state, activity.activityTypeId);
  const users = getUserList(state);

  const getUsersOptions = () =>
    users.map((user) => (
      <option key={user.userId} value={user.userId}>
        {user.email}
      </option>
    ));

  const onSubmitHandler = (values: UpdateActivityViewModel) => {
    updateActivity(values).subscribe(
      (response) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully Updated Activity',
            title: 'Success',
          })
        );
        handleOnSubmit && handleOnSubmit();
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: `Error: Couldn't Edit Activity`,
          })
        );
      }
    );

    activityType &&
      !activityType.defaultValue &&
      updateActivityType({
        ...activityType,
        frequency: Number(values.frequency),
      }).subscribe(
        (response) => {
          dispatch(
            notificationActions.setToast({
              severity: 'success',
              message: 'Successfully Updated Activity Type',
              title: 'Success',
            })
          );
          dispatch(organizationActions.fetchActivityTypeList());
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Edit Activity Type`,
            })
          );
        }
      );
  };

  return (
    <Formik
      initialValues={getInitialValues(activity, activityType)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <DualToggleSwitch
            leftLabel="Off"
            rightLabel="On"
            name="on"
            label="Enable notifications for this activity"
            handleToggle={setFieldValue}
            isOn={values.on}
            disable={isMachineOn ? false : true}
          />
          {activityType && (
            <AppFrequencyInput
              name="frequency"
              label="Frequency"
              placeholder="Frequency"
              setFieldValue={setFieldValue}
              value={String(activityType.frequency)}
              readOnly={activityType.defaultValue ? true : false}
            />
          )}
          <AppInput
            name="nextDueDate"
            label="Next Due Date*"
            placeholder="Next Due Date"
            type="date"
          />
          <AppDropdown
            name="responsibleUserId"
            label="Responsible User"
            placeholder="Responsible User"
          >
            <option defaultChecked value="">
              Unassigned
            </option>
            {getUsersOptions()}
          </AppDropdown>
          <AppCheckBox
            label={`Apply to all ${activityType?.name || ''} activities`}
            checked={false}
            onChange={() =>
              setFieldValue('applySameActivity', !values.applySameActivity)
            }
          />
          <AppButton type="submit" text="Update" />
        </Form>
      )}
    </Formik>
  );
};

export default EditActivityForm;
