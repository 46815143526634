import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import {
  Company,
  CreateCompany,
  CreateCompanyResponse,
} from '../../models/company';
import { ListRequest } from '../../models/api';
import { CompanyListResponse } from '../../models/company';

const COMPANY_ENDPOINT = '/company/';

export const getCompanies = (
  payload?: ListRequest
): Observable<CompanyListResponse> => GET(COMPANY_ENDPOINT, { ...payload });

export const getCompany = (companyId: string): Observable<Company> =>
  GET(`${COMPANY_ENDPOINT}${companyId}`);

export const createCompany = (
  company: CreateCompany
): Observable<CreateCompanyResponse> => POST(COMPANY_ENDPOINT, { ...company });

export const updateCompany = (company: Company): Observable<Company> =>
  PUT(`${COMPANY_ENDPOINT}${company.companyId}`, { ...company });

export const deleteCompany = (companyId: string): Observable<null> =>
  DELETE(`${COMPANY_ENDPOINT}${companyId}`);
