import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { RootState } from '../../../app/redux/rootReducer';
import { getMachineType } from '../../dashboard/redux/organizationSelector';
import { MachineType } from '../../../models/machineType';
import { AppButton, AppInput } from '../../../shared/forms/components';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import {
  createMachineType,
  updateMachineType,
} from '../../../services/api/machineTypeApi';
import { getAuth } from '../../auth/redux/authSelector';
import { notificationActions } from '../../../app/redux/notification.slice';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This is a required field'),
  horsePower: Yup.string(),
  specification: Yup.string(),
  machineSection: Yup.string().required('This is a required field'),
});

interface Props {
  machineTypeId?: string;
  companyId?: string;
  getMachineTypeDetails: (machineTypeId: string) => MachineType | undefined;
  fetchMachineTypes: () => void;
  handleOnSuccess?: () => void;
}

const CreateEditMachineTypeForm: React.FC<Props> = ({
  machineTypeId,
  companyId,
  getMachineTypeDetails,
  fetchMachineTypes,
  handleOnSuccess,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getInitialValues = (machineType?: MachineType) => ({
    id: machineType ? machineType.id : '',
    name: machineType ? machineType.name : '',
    horsePower: machineType ? machineType.horsePower : '',
    specification: machineType ? machineType.specification : '',
    machineSection: machineType ? machineType.machineSection : '',
    companyType: machineType ? machineType.companyType : '',
  });

  const onSubmitHandler = (values: MachineType) => {
    setIsLoading(true);
    if (machineTypeId) {
      updateMachineType(values).subscribe(
        () => {
          fetchMachineTypes();
          handleOnSuccess && handleOnSuccess();
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: 'Error: Failed To Update Machine',
            })
          );
        }
      );
    } else {
      createMachineType({ ...values, companyId }).subscribe(
        (response) => {
          fetchMachineTypes();
          handleOnSuccess && handleOnSuccess();
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: 'Error: Failed To Create Machine Type',
            })
          );
        }
      );
    }
    setIsLoading(false);
  };
  return (
    <Formik
      initialValues={getInitialValues(
        machineTypeId ? getMachineTypeDetails(machineTypeId) : undefined
      )}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <div>
          <p className="font-color-red text-center">
            These changes will be applied to all machines of this type.
          </p>
        </div>
        <AppInput
          name="name"
          label="Machine Type*"
          placeholder="Enter Machine Type"
          type="text"
          loading={isLoading}
        />
        <AppInput
          name="machineSection"
          label="Section*"
          placeholder="Enter Section"
          type="text"
          loading={isLoading}
        />
        <AppInput
          name="horsePower"
          label="Horse Power"
          placeholder="Enter Horse Power"
          type="text"
          loading={isLoading}
        />
        <AppInput
          name="specification"
          label="Specification"
          placeholder="Enter Specification"
          type="text"
          loading={isLoading}
        />
        <AppButton
          type="submit"
          text={machineTypeId ? 'Update' : 'Create'}
          loading={isLoading}
        />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyId: getAuth(state)?.companyId,
  getMachineTypeDetails: (machineTypeId: string) =>
    getMachineType(state, machineTypeId),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMachineTypes: () => dispatch(organizationActions.fetchMachineTypeList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditMachineTypeForm);
