import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Category } from '../../../../models/category';

import './CategoryCard.css';

interface Props {
  categoryData: Category;
}

const CategoryCard: React.FC<Props> = ({ categoryData }: Props) => (
  <Link
    to={`/settings/category/${categoryData.categoryId}`}
    className="category-card"
  >
    <div className="row">
      <div className="col">
        <h5 className="">{categoryData.categoryName}</h5>
        {categoryData.numOfSections && (
          <p>
            <Badge className="badge-section" color="danger">
              {categoryData.numOfSections}
            </Badge>
            Sections
          </p>
        )}
        {categoryData.numOfWorkshops && (
          <p>
            <Badge className="badge-section" color="info">
              {categoryData.numOfWorkshops}
            </Badge>
            Workshops
          </p>
        )}
      </div>
    </div>
  </Link>
);

export default CategoryCard;
