import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';

import { organizationEpic } from '../../modules/dashboard/redux/organizationEpic';
import { authEpic } from '../../modules/auth/redux/authEpic';
import { appEpic } from './appEpic';

/* eslint-disable */
const rootEpic = (action$: any, store$: any, dependencies$: any): any =>
  combineEpics(...appEpic, ...organizationEpic, ...authEpic)(
    action$,
    store$,
    dependencies$
  ).pipe(
    catchError((error, source) => {
      console.error('rootEpic', error);
      return source;
    })
  );

export default rootEpic;
