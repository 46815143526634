import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { userAuthenticatedSelector } from '../../../modules/auth/redux/authSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { DASHBOARD } from '../../../modules/dashboard/routes';

interface UnauthenticatedRouteProps {
  isAuthenticated: boolean;
  component: React.ComponentType;
}

const UnauthenticatedRoute = <T extends UnauthenticatedRouteProps>({
  isAuthenticated,
  component: Component,
  ...rest
}: T): JSX.Element =>
  isAuthenticated ? <Redirect to={DASHBOARD} /> : <Component {...rest} />;

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: userAuthenticatedSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthenticatedRoute);
