import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import TopBar from '../../../app/components/TopBar/TopBar';
import { AiOutlineWarning } from 'react-icons/ai';
import { notificationActions } from '../../../app/redux/notification.slice';

import { NewAuth } from '../../../models/auth';
import { getNewAuthByToken } from '../../../services/api/userApi';
import SignUpUserForm from '../../user/forms/SignUpUserForm';

import './login.css';
import { Alert } from 'reactstrap';

interface ParamsProps {
  confirmationToken: string;
}

const SignUp: React.FC = () => {
  const { confirmationToken } = useParams<ParamsProps>();

  const [newAuth, setNewAuth] = useState<NewAuth>();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    setError('');
    if (confirmationToken) {
      getNewAuthByToken(confirmationToken).subscribe(
        (response) => {
          setNewAuth(response);
          setIsLoading(false);
        },
        (error: Error) => {
          setError('Error: Invalid Confirmation Token');
          setIsLoading(false);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: 'Error: Invalid Confirmation Token',
            })
          );
        }
      );
    }
  }, []);

  return (
    <div>
      <div className="container container-width-set-none">
        <div className="row justify-content-center">
          <div className="col-sm-6 margin-top-set-5vh">
            <div className="row justify-content-md-center">
              <div className="col-sm-8">
                {isLoading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div className="p-5 margin-top-set-10vh text-align-center">
                    <AiOutlineWarning size={100} color="red" />
                    <h2 className="title-tag-style mt-3 font-color-red">
                      {error}
                    </h2>
                    <Alert color="danger">
                      There may happen when you enter your URL manually and
                      missed some words in the URL or some connection problems
                      with our servers. Please contact our 24*7 support team for
                      more information.
                    </Alert>
                  </div>
                ) : (
                  <div>{newAuth && <SignUpUserForm auth={newAuth} />}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-6 login-img-background-div">
            <div className="p-5 margin-top-set-20vh text-align-center">
              <h2 className="title-tag-style mt-5 font-color-white">
                Effective way to increase your workflow
              </h2>
              <hr />
              <p className="title-tag-style font-color-white">
                We are giving solution to note down your day to day maintenance
                in our platform.
              </p>
              <p className="normal-p-tag-style font-color-white">
                Go to our website and make a meeting schedule with our agent and
                get more details about our solution how to compatible with your
                company.
              </p>
              <p className="normal-p-tag-style mt-5 font-color-white">
                <a className="button-for-webpage" href="https://www.orizel.com">
                  Try it now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
