import React from 'react';
import { roleTypes } from '../../constants/userRole';
import { UserRole } from '../../models/userRole';

import AuthRoutes from '../../modules/auth/routes';
import DashboardRoutes from '../../modules/dashboard/routes';
import NotFoundPage from '../pages/NotFoundPage';
import CompanyRoutes from '../../modules/company/routes';
import MachineRoutes from '../../modules/machine/routes';
import MaintenanceRoutes from '../../modules/maintenance/routes';
import UserRoutes from '../../modules/user/routes';
import OverviewRoutes from '../../modules/overview/routes';
import SettingsRoutes from '../../modules/settings/routes';

export interface OrizelRoute {
  path: string;
  component: React.ComponentType;
  authenticated?: boolean;
  permissions?: string[];
  exact?: boolean;
  common?: boolean;
}

export const NOT_FOUND = '404-not-found';

const AppRoutes: OrizelRoute[] = [
  {
    path: NOT_FOUND,
    component: NotFoundPage,
    authenticated: false,
    permissions: [],
    exact: false,
    common: true,
  },
];

const getRoutes = (userRole: UserRole | null): Array<OrizelRoute> => {
  const commonRoutes = [
    ...AuthRoutes,
    ...AppRoutes,
    ...DashboardRoutes,
    ...UserRoutes,
  ];
  if (userRole) {
    switch (userRole.roleName) {
      case roleTypes.SUPER_ADMIN:
        return [
          ...CompanyRoutes,
          ...UserRoutes,
          ...DashboardRoutes,
          ...commonRoutes,
        ];
      case roleTypes.ADMIN:
        return [
          ...MachineRoutes,
          ...MaintenanceRoutes,
          ...OverviewRoutes,
          ...SettingsRoutes,
          ...commonRoutes,
        ];
      case roleTypes.MAINTENANCE:
        return [
          ...MachineRoutes,
          ...MaintenanceRoutes,
          ...OverviewRoutes,
          ...commonRoutes,
        ];
      case roleTypes.VIEWERS:
        return [
          ...MachineRoutes,
          ...MaintenanceRoutes,
          ...OverviewRoutes,
          ...commonRoutes,
        ];
      default:
        return commonRoutes;
    }
  }
  return commonRoutes;
};

export default getRoutes;
