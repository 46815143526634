import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { Subscription } from '../../../models/subscription';
import { AppButton, AppInput } from '../../../shared/forms/components';
import { AppCheckBox } from '../../../shared/components';
import { updateSubscription } from '../../../services/api/subscriptionApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { createSubscription } from '../../../services/api/subscriptionApi';

interface Props {
  companyId: string;
  subscription?: Subscription;
  handleOnSuccess?: () => void;
}

const getInitialValues = (subscription?: Subscription) => ({
  subscriptionId: subscription?.subscriptionId || '',
  companyId: subscription?.companyId || '',
  name: subscription?.name || '',
  subscriptionFrom: subscription?.subscriptionFrom
    ? moment(subscription.subscriptionFrom)
        .tz(moment.tz.guess())
        .format('YYYY-MM-DD')
    : '',
  subscriptionTo: subscription?.subscriptionTo
    ? moment(subscription.subscriptionTo)
        .tz(moment.tz.guess())
        .format('YYYY-MM-DD')
    : '',
  createdAt: subscription?.createdAt || '',
  comment: subscription?.comment || '',
  valid: subscription?.valid || true,
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This is a required field'),
  subscriptionFrom: Yup.date().required('This is a required field'),
  subscriptionTo: Yup.date().required('This is a required field'),
  comment: Yup.string(),
});

const CreateEditSubscriptionForm: React.FC<Props> = ({
  companyId,
  subscription,
  handleOnSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmitHandler = (values: Subscription) => {
    setIsLoading(true);
    if (subscription) {
      updateSubscription(values).subscribe(
        () => {
          dispatch(
            notificationActions.setToast({
              severity: 'success',
              message: 'Successfully Updated Subscription',
              title: 'Success',
            })
          );
          handleOnSuccess && handleOnSuccess();
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Edit Subscription`,
            })
          );
          setIsLoading(false);
        }
      );
    } else {
      createSubscription({
        ...values,
        companyId,
        createdAt: moment().tz(moment.tz.guess()).format(),
      }).subscribe(
        () => {
          dispatch(
            notificationActions.setToast({
              severity: 'success',
              message: 'Successfully Created Subscription',
              title: 'Success',
            })
          );
          handleOnSuccess && handleOnSuccess();
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Create Subscription`,
            })
          );
          setIsLoading(false);
        }
      );
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(subscription)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <AppInput
            name="name"
            label="Subscription Type*"
            placeholder="Enter Subscription Type"
            type="text"
            loading={isLoading}
          />
          <AppInput
            name="subscriptionFrom"
            label="Subscription From*"
            placeholder="Enter Subscription From"
            type="date"
            loading={isLoading}
          />
          <AppInput
            name="subscriptionTo"
            label="Subscription To*"
            placeholder="Enter Subscription To"
            type="date"
            loading={isLoading}
          />
          <AppInput
            name="comment"
            label="Comment"
            placeholder="Enter Comment"
            type="text"
            loading={isLoading}
          />
          {subscription && (
            <AppCheckBox
              label="Valid"
              checked={true}
              onChange={() => setFieldValue('valid', !values.valid)}
            />
          )}

          <AppButton
            type="submit"
            text={subscription ? 'Update' : 'Create'}
            loading={isLoading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateEditSubscriptionForm;
