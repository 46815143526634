import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { connect, useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';

import { AppModal } from '../../../../shared/components';
import { isAdmin } from '../../../auth/redux/authSelector';
import { RootState } from '../../../../app/redux/rootReducer';
import { Category } from '../../../../models/category';
import SectionList from '../../../section/components/SectionList/SectionList';
import { getUser } from '../../../../app/redux/appSelector';
import { User } from '../../../../models/user';
import { Link } from 'react-router-dom';
import EditCategoryForm from '../../forms/EditCategoryForm';

import './CategoryView.css';

interface Props {
  categoryDetailsObj: Category;
  getUserDetails: (userId: string) => User | undefined;
}

const CategoryView: React.FC<Props> = ({
  categoryDetailsObj,
  getUserDetails,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [categoryObj, setCategoryObj] = useState<Category>(categoryDetailsObj);

  const state = useSelector((state: RootState) => state);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    document.title = 'Category: '.concat(categoryObj.categoryName);
  }, []);

  const getUserAvatar = (userId: string) => {
    const user = getUserDetails(userId);
    return user ? (
      <Link to={'/users/'.concat(user.userId)}>
        <Avatar
          name={
            user.firstName &&
            user.lastName &&
            user.firstName.concat(' ').concat(user.lastName)
          }
          size="30"
          round="50%"
          color="#303f9f"
          fgColor="white"
        />
      </Link>
    ) : (
      ''
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="table_outer_div">
            <div className="container">
              <div className="row align-items-start">
                <div className="col header-txt">
                  <h5>Category Details</h5>
                </div>
              </div>
              <Table BorderLess>
                <tbody>
                  <tr>
                    <td>
                      <b>Category Name</b>
                    </td>
                    <td>{categoryObj.categoryName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Number of Sections</b>
                    </td>
                    <td>{categoryObj.numOfSections}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Number of Workshop</b>
                    </td>
                    <td>{categoryObj.numOfWorkshops}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Responsible User</b>
                    </td>
                    <td>
                      {categoryObj?.responsibleUserId &&
                        getUserAvatar(categoryObj?.responsibleUserId)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Created Date:</b>
                    </td>
                    <td>
                      {/* {categoryObj.createdAt
                        ? moment(categoryObj.createdAt)
                            .tz(moment.tz.guess())
                            .format('YYYY-MM-DD')
                        : ''} */}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="row">
                <div className="col">
                  {isAdmin(state) && (
                    <a
                      onClick={handleModalToggle}
                      className="add-new-link-btn-thin-border ft-size-16 float-right"
                    >
                      <FiEdit size={18} /> Edit
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <SectionList categoryId={categoryObj.categoryId} />
          {/* <UserList categoryId={categoryObj.categoryId} sectionId="" /> */}
        </div>
      </div>
      {isAdmin(state) && (
        <>
          <AppModal
            onClose={handleModalToggle}
            show={showModal}
            title="Edit Details"
          >
            <EditCategoryForm
              category={categoryObj}
              handleModalToggle={handleModalToggle}
              setCategory={setCategoryObj}
            />
          </AppModal>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  getUserDetails: (userId: string) => getUser(state, userId),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryView);
