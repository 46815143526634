import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BiAddToQueue } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

import { AppModal, Loader, WarningModal } from '../../../shared/components';
import { RootState } from '../../../app/redux/rootReducer';
import CategoryView from '../../category/components/CategoryView/CategoryView';
import { Category } from '../../../models/category';
import CreateSectionForm from '../../section/forms/CreateSectionForm';
import { deleteCategory, getCategory } from '../../../services/api/categoryApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { isAdmin as checkIsAdmin } from '../../auth/redux/authSelector';

interface ParamsProps {
  id: string;
}

const CategoryViewPage: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const state = useSelector((state: RootState) => state);
  const isAdmin = checkIsAdmin(state);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState<Category>();

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      getCategory(id).subscribe(
        (response: Category) => {
          setCategoryDetails(response);
          setIsLoading(false);
        },
        (error: Error) => {
          setIsLoading(false);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Fetch Category Details`,
            })
          );
        }
      );
    }
  }, []);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const toggleWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const handleOnClickWarning = () => {
    if (categoryDetails) {
      deleteCategory(categoryDetails.categoryId).subscribe(
        () => {
          console.log('success');
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Delete Category`,
            })
          );
        }
      );
    }

    toggleWarningModal();
  };

  return (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          <button
            onClick={toggleWarningModal}
            className="add-new-link-btn add-new-link-btn-red ft-size-16"
          >
            <AiOutlineDelete size={18} /> Delete Category
          </button>
          <button
            onClick={handleModalToggle}
            className="add-new-link-btn ft-size-16"
          >
            <BiAddToQueue size={18} /> Add Section
          </button>
          <AppModal
            onClose={handleModalToggle}
            show={showModal}
            title="Add Section"
          >
            <CreateSectionForm categoryId={id} onSubmit={handleModalToggle} />
          </AppModal>
        </div>
      </div>
      {categoryDetails && <CategoryView categoryDetailsObj={categoryDetails} />}
      {isAdmin && (
        <>
          <WarningModal
            show={showWarningModal}
            onClose={toggleWarningModal}
            onClickOk={handleOnClickWarning}
          />
        </>
      )}
    </div>
  );
};

export default CategoryViewPage;
