import { Observable, concat, from, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { map, filter, mergeMap, catchError } from 'rxjs/operators';

import { appAction } from './appSlice';
import { getUserRoles } from '../../services/api/userRoleApi';
import { getCompanies } from '../../services/api/companyApi';
import { getCompanyTypes } from '../../services/api/companyTypeApi';
import { notificationActions } from './notification.slice';
import { getUsers } from '../../services/api/userApi';

const fetchUserRolesEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(appAction.fetchUserRoleList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(appAction.fetchUserRoleList.match),
    mergeMap((action) =>
      concat(
        from(getUserRoles()).pipe(
          map((response) => appAction.fetchUserRoleListSuccess(response))
        )
      )
    )
  );

const fetchCompanyListEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(appAction.fetchCompanyList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(appAction.fetchCompanyList.match),
    mergeMap((action) =>
      concat(
        from(getCompanies(action.payload)).pipe(
          map((response) => appAction.fetchCompanyListSuccess(response))
        )
      )
    )
  );

const fetchCompanyTypeListEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(appAction.fetchCompanyTypeList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(appAction.fetchCompanyTypeList.match),
    mergeMap((action) =>
      concat(
        from(getCompanyTypes()).pipe(
          map((response) => appAction.fetchCompanyTypeListSuccess(response))
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Company Type`,
        })
      )
    )
  );

const fetchUserListEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(appAction.fetchUserList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(appAction.fetchUserList.match),
    mergeMap((action) =>
      concat(
        from(getUsers()).pipe(
          map((response) => appAction.fetchUserListSuccess(response))
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Users`,
        })
      )
    )
  );

export const appEpic = [
  fetchUserRolesEpic,
  fetchCompanyListEpic,
  fetchCompanyTypeListEpic,
  fetchUserListEpic,
];
