import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BsCircleFill } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import moment from 'moment-timezone';

import { Machine } from '../../../models/machine';
import MachineActivityList from '../../activity/component/MachineActivityList/MachineActivityList';
import MachineMaintenanceList from '../../maintenance/component/MachineMaintenanceList/MachineMaintenanceList';
import { AppModal } from '../../../shared/components';
import EditMachineForm from '../forms/EditMachineForm';
import { isAdmin } from '../../auth/redux/authSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { WarningModal } from '../../../shared/components';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import {
  getMachineType,
  getWorkshop,
} from '../../dashboard/redux/organizationSelector';
import CreateEditMachineTypeForm from '../forms/CreateEditMachineTypeForm';
import { getMachineQRCode } from '../../../services/api/machineApi';

interface Props {
  machineDetailsObj: Machine;
}

const MachineView: React.FC<Props> = ({ machineDetailsObj }) => {
  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showMachineTypeModal, setShowMachineTypeModal] = useState(false);
  const [machineObj, setMachineObj] = useState<Machine>(machineDetailsObj);

  const state = useSelector((state: RootState) => state);
  const machineType = getMachineType(state, machineDetailsObj.machineTypeId);
  const workshop = machineDetailsObj.workshopId
    ? getWorkshop(state, machineDetailsObj.workshopId)
    : null;
  const dispatch = useDispatch();

  const handleMachineTypeModal = () => {
    setShowMachineTypeModal(!showMachineTypeModal);
  };

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const toggleWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const handleOnClickWarning = () => {
    dispatch(
      organizationActions.deleteMachine({
        machineId: machineDetailsObj.machineId,
      })
    );
    toggleWarningModal();
  };

  useEffect(() => {
    document.title = 'Machine: '.concat(machineObj.machineName);
  }, []);

  const handleOnClickQR = () => {
    getMachineQRCode(machineDetailsObj.machineId).subscribe(
      (response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'image/png' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${machineDetailsObj.machineName}.png`);
        document.body.appendChild(link);
        link.click();
      },
      (error: Error) => {
        // TODO: Handle error
      }
    );
  };
  console.log(machineDetailsObj);
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="table_outer_div">
            <div className="container">
              <div className="row align-items-start">
                <div className="col header-txt">
                  <h5>Machine Details</h5>
                </div>
                {machineObj.on && (
                  <div className="col header-txt">
                    <BsCircleFill className="float-right machine-state-on" />
                  </div>
                )}
                {!machineObj.on && (
                  <div className="col header-txt">
                    <BsCircleFill className="float-right machine-state-off" />
                  </div>
                )}
              </div>
              <Table BorderLess>
                <tbody>
                  <tr>
                    <td>
                      <b>Machine Name</b>
                    </td>
                    <td>{machineObj.machineName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Sub Number:</b>
                    </td>
                    <td>{String(machineObj.machineSubNumber || '')}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Workshop:</b>
                    </td>
                    <td>{String(workshop?.workshopName || '')}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Created Date:</b>
                    </td>
                    <td>
                      {machineObj.createdAt
                        ? moment(machineObj.createdAt)
                            .tz(moment.tz.guess())
                            .format('YYYY-MM-DD')
                        : ''}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="row">
                <div className="col">
                  {isAdmin(state) && (
                    <a
                      onClick={handleModalToggle}
                      className="add-new-link-btn-thin-border ft-size-16 float-right"
                    >
                      <FiEdit size={18} /> Edit
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="table_outer_div">
            <div className="container">
              <div className="row align-items-start">
                <div className="col header-txt">
                  <h5>Machine Type</h5>
                </div>
              </div>
              <Table BorderLess>
                <tbody>
                  {machineType && (
                    <>
                      <tr>
                        <td>
                          <b>Machine Type:</b>
                        </td>
                        <td>{machineType.name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Horse Power:</b>
                        </td>
                        <td>{machineType.horsePower}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Specification:</b>
                        </td>
                        <td>{machineType.specification}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Section:</b>
                        </td>
                        <td>{machineType ? machineType.machineSection : ''}</td>
                      </tr>
                      <tr>
                        <td>-</td>
                        <td>
                          <Button onClick={handleOnClickQR}>QR</Button>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <div className="row">
                <div className="col">
                  {isAdmin(state) && (
                    <a
                      onClick={handleMachineTypeModal}
                      className="add-new-link-btn-thin-border ft-size-16 float-right"
                    >
                      <FiEdit size={18} /> Edit
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <MachineActivityList machineId={machineObj.machineId} />
          <MachineMaintenanceList machineId={machineObj.machineId} />
        </div>
      </div>
      {isAdmin(state) && (
        <>
          <AppModal
            onClose={handleModalToggle}
            show={showModal}
            title="Edit Details"
          >
            <EditMachineForm
              machine={machineObj}
              handleModalToggle={handleModalToggle}
              setMachine={setMachineObj}
            />
          </AppModal>
          <WarningModal
            show={showWarningModal}
            onClose={toggleWarningModal}
            onClickOk={handleOnClickWarning}
          />
          <AppModal
            onClose={handleMachineTypeModal}
            show={showMachineTypeModal}
            title="Edit Details"
          >
            <CreateEditMachineTypeForm
              machineTypeId={machineType?.id}
              handleOnSuccess={handleMachineTypeModal}
            />
          </AppModal>
        </>
      )}
    </div>
  );
};

export default MachineView;
