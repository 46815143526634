import { createStore, applyMiddleware } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './rootEpic';
import rootReducer from './rootReducer';

const epicMiddleware = createEpicMiddleware();

const middlewareEnhancer = applyMiddleware(epicMiddleware);

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(middlewareEnhancer)
    : middlewareEnhancer
);

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;

export default store;
