import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UserList from '../../../user/component/UserList/UserList';
import { RootState } from '../../../../app/redux/rootReducer';
import {
  isAdmin as checkIsAdmin,
  isSuperAdmin as checkIsSuperAdmin,
} from '../../../auth/redux/authSelector';
import CreateUserForm from '../../../user/forms/CreateUserForm';
import { AppModal } from '../../../../shared/components';
import CreateSuperAdminForm from '../../../user/forms/CreateSuperAdminForm';
import PendingUserList from '../../../user/component/PendingUserList/PendingUserList';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { appAction } from '../../../../app/redux/appSlice';

const AdminUserDetails: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [refreshState, setRefreshState] = useState(false);

  const state = useSelector((state: RootState) => state);
  const isAdmin = checkIsAdmin(state);
  const isSuperAdmin = checkIsSuperAdmin(state);
  const dispatch = useDispatch();

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleOnSuccessCreateUser = () => {
    handleModalToggle();
    setRefreshState(!refreshState);
    dispatch(appAction.fetchUserList());
  };

  useEffect(() => {
    document.title = 'Users';
  }, []);

  const getAddUserModal = () => {
    if (isAdmin) {
      return (
        <>
          <a
            onClick={handleModalToggle}
            className="add-new-link-btn ft-size-16"
          >
            <AiOutlineUserAdd size={18} /> Add New User
          </a>
          <AppModal
            onClose={handleModalToggle}
            show={showModal}
            title="Add User"
          >
            <CreateUserForm
              createUserSuccessCallBack={handleOnSuccessCreateUser}
            />
          </AppModal>
        </>
      );
    } else if (isSuperAdmin) {
      return (
        <>
          <a
            onClick={handleModalToggle}
            className="add-new-link-btn ft-size-16"
          >
            <AiOutlineUserAdd size={18} /> Add Super Admin
          </a>
          <AppModal
            onClose={handleModalToggle}
            show={showModal}
            title="Add Super Admin"
          >
            <CreateSuperAdminForm
              createUserSuccessCallBack={handleOnSuccessCreateUser}
            />
          </AppModal>
        </>
      );
    }
  };

  return (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          {getAddUserModal()}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <UserList />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <PendingUserList refresh={refreshState} />
        </div>
      </div>
    </div>
  );
};

export default AdminUserDetails;
