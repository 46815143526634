import { Observable } from 'rxjs';
import {
  CompanyType,
  CreateCompanyTypeRequest,
} from '../../models/companyType';

import { GET, POST, PUT } from './baseApi';

const COMPANY_TYPE_ENDPOINT = '/companytype/';

export const getCompanyTypes = (): Observable<Array<CompanyType>> =>
  GET(COMPANY_TYPE_ENDPOINT);

export const getCompanyType = (
  companyTypeId: string
): Observable<CompanyType> => GET(`${COMPANY_TYPE_ENDPOINT}${companyTypeId}`);

export const createCompanyType = (
  companyType: CreateCompanyTypeRequest
): Observable<CompanyType> => POST(COMPANY_TYPE_ENDPOINT, { ...companyType });

export const updateCompanyType = (
  companyType: CompanyType
): Observable<CompanyType> =>
  PUT(`${COMPANY_TYPE_ENDPOINT}${companyType.companyTypeId}`, {
    ...companyType,
  });
