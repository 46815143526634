import OverViewPage from '../page/OverViewPage';

export const OVERVIEW = '/overview';

export default [
  {
    path: OVERVIEW,
    component: OverViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
