import { Observable } from 'rxjs';

import { GET } from './baseApi';
import { Overview } from '../../models/overview';
import { Maintenance } from '../../models/maintenance';

const OVERVIEW_ENDPOINT = '/overview/';
const OVERVIEW_COMPLETED_ENDPOINT = `${OVERVIEW_ENDPOINT}getCompleted`;

export const getOverview = (): Observable<Overview> => GET(OVERVIEW_ENDPOINT);

export const getCompletedOverview = (
  dataRange: string
): Observable<Array<Maintenance>> =>
  GET(OVERVIEW_COMPLETED_ENDPOINT, { dataRange });
