import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { Dispatch } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import { BsCircleFill } from 'react-icons/bs';
import moment from 'moment-timezone';
import { AiOutlineDelete } from 'react-icons/ai';
import Avatar from 'react-avatar';
import { RiFileEditFill } from 'react-icons/ri';

import { RootState } from '../../../../app/redux/rootReducer';
import {
  getActivityType,
  getMachine,
} from '../../../dashboard/redux/organizationSelector';
import { getActivities } from '../../../../services/api/activityApi';
import { Activity } from '../../../../models/activity';
import { AppModal, Loader, WarningModal } from '../../../../shared/components';
import EditActivityForm from '../../forms/EditActivityForm';
import { MaintenancesRequest } from '../../../../models/maintenance';
import { organizationActions } from '../../../dashboard/redux/organizationSlice';
import { MAINTENANCES } from '../../../maintenance/routes';
import { notificationActions } from '../../../../app/redux/notification.slice';
import { getUserRole } from '../../../auth/redux/authSelector';
import { UserRole } from '../../../../models/userRole';
import { roleTypes } from '../../../../constants/userRole';
import { DATE_FORMAT } from '../../../../constants/dateTime';
import { getActivityFrequencyOptionValue } from '../../../../utils/organizationUtils';
import { deleteActivity } from '../../../../services/api/activityApi';
import { CgMoreVerticalR } from 'react-icons/cg';
import { Machine } from '../../../../models/machine';
import { getUser } from '../../../../app/redux/appSelector';
import { User } from '../../../../models/user';

interface Props {
  state: RootState;
  machineId: string;
  userRole: UserRole | null;
  isActivityLoading: boolean;
  fetchMaintenance: (param?: MaintenancesRequest) => void;
  getMachineDetails: (machineId: string) => Machine | undefined;
  getUserDetails: (userId: string) => User | undefined;
}

const MachineActivityList: React.FC<Props> = ({
  state,
  machineId,
  userRole,
  isActivityLoading,
  fetchMaintenance,
  getMachineDetails,
  getUserDetails,
}: Props) => {
  const [activityList, setMachineActivityList] = useState<Array<Activity>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [currentActivityItem, setCurrentActivityItem] = useState<Activity>();
  const [renderState, setRenderState] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const machineObj = getMachineDetails(machineId);

  useEffect(() => {
    setIsLoading(true);
    getActivities({ machineId }).subscribe(
      (response: Array<Activity>) => {
        setMachineActivityList(response);
        setIsLoading(false);
      },
      (err: Error) => {
        setIsLoading(false);
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: err.message,
            title: `Error: Couldn't Fetch Activities`,
          })
        );
      }
    );
  }, [machineId, renderState, dispatch, isActivityLoading]);

  function handleModalOpen(value: Activity) {
    setShowModal(!showModal);
    setCurrentActivityItem(value);
  }

  function handleModalClose() {
    setShowModal(!showModal);
  }

  const toggleWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const handleOnClickWarning = () => {
    if (currentActivityItem) {
      deleteActivity(currentActivityItem.activityId).subscribe(
        () => {
          setRenderState(!renderState);
        },
        (error: Error) => {
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Delete Activity`,
            })
          );
        }
      );
    }

    toggleWarningModal();
  };

  const handleOnClickDelete = (activity: Activity) => {
    setCurrentActivityItem(activity);
    toggleWarningModal();
  };

  const handleOnClickMore = (activityId: string) => {
    fetchMaintenance({
      machineId,
      activityId,
      dataRange: `${moment()
        .add(-3, 'months')
        .tz(moment.tz.guess())
        .format(DATE_FORMAT)},${moment()
        .tz(moment.tz.guess())
        .format(DATE_FORMAT)}`,
    });
    history.push(MAINTENANCES);
  };

  const handleActivityUpdate = () => {
    setShowModal(!showModal);
    setRenderState(!renderState);
  };

  const getUserAvatar = (userId: string) => {
    const user = getUserDetails(userId);
    return user ? (
      <Link to={'/users/'.concat(user.userId)}>
        <Avatar
          name={
            user.firstName &&
            user.lastName &&
            user.firstName.concat(' ').concat(user.lastName)
          }
          size="30"
          round="50%"
          color="#303f9f"
          fgColor="white"
        />
      </Link>
    ) : (
      ''
    );
  };

  return (
    <div className="table_outer_div">
      <div className="container">
        <div className="row align-items-start">
          <div className="col header-txt">
            <h5>Activity Details</h5>
          </div>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Activity</th>
            <th>Frequency</th>
            <th>Due Date</th>
            <th></th>
            {userRole?.roleName === roleTypes.ADMIN && (
              <>
                <th></th>
                <th></th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading || isActivityLoading ? (
            <Loader />
          ) : (
            activityList.map((item) => {
              const activityTypeDetails = getActivityType(
                state,
                item.activityTypeId
              );
              return (
                <tr key={item.activityId}>
                  <td>{getUserAvatar(item.responsibleUserId)}</td>
                  <td>
                    {machineObj && machineObj.on && item.on ? (
                      <BsCircleFill className="float-center machine-state-on" />
                    ) : (
                      <BsCircleFill className="float-center machine-state-off" />
                    )}
                  </td>
                  <td>{activityTypeDetails?.name || ''}</td>
                  <td>
                    {activityTypeDetails
                      ? getActivityFrequencyOptionValue(
                          String(activityTypeDetails.frequency)
                        )
                      : ''}
                  </td>
                  <td>
                    {moment(item.nextDueDate)
                      .tz(moment.tz.guess())
                      .format('YYYY-MM-DD')}
                  </td>

                  {userRole?.roleName === roleTypes.ADMIN && (
                    <>
                      <td className="view-td">
                        <a
                          onClick={() => handleModalOpen(item)}
                          className="add-new-link-btn-thin-border ft-size-16"
                        >
                          <RiFileEditFill size={20} />
                        </a>
                      </td>
                      <td className="view-td">
                        <a
                          onClick={() => handleOnClickDelete(item)}
                          className="add-new-link-btn-thin-border ft-size-16"
                        >
                          <AiOutlineDelete size={20} />
                        </a>
                      </td>
                    </>
                  )}
                  <td className="view-td">
                    <a
                      onClick={() => handleOnClickMore(item.activityId)}
                      className="add-new-link-btn-thin-border ft-size-16"
                    >
                      <CgMoreVerticalR size={20} />
                    </a>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      {userRole?.roleName === roleTypes.ADMIN && (
        <>
          <AppModal
            onClose={handleModalClose}
            show={showModal}
            title="Edit Activity Details"
          >
            <EditActivityForm
              activity={currentActivityItem!}
              handleOnSubmit={handleActivityUpdate}
              isMachineOn={machineObj?.on}
            />
          </AppModal>
          <WarningModal
            show={showWarningModal}
            onClose={toggleWarningModal}
            onClickOk={handleOnClickWarning}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  userRole: getUserRole(state),
  getMachineDetails: (machineId: string) => getMachine(state, machineId),
  getUserDetails: (userId: string) => getUser(state, userId),
  isActivityLoading: state.organization.activityLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMaintenance: (param?: MaintenancesRequest) => {
    dispatch(
      organizationActions.fetchMaintenanceList(param ? { ...param } : {})
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MachineActivityList);
