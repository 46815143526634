import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import { CreateSection, Section } from '../../models/section';

const CATEGORY_ENDPOINT = '/category/';
const SECTION_ENDPOINT = '/section/';

export const getSection = (
  categoryId: string,
  sectionId: string
): Observable<Section> =>
  GET(`${CATEGORY_ENDPOINT}${categoryId}${SECTION_ENDPOINT}${sectionId}`);

export const getSections = (categoryId: string): Observable<Array<Section>> =>
  GET(`${CATEGORY_ENDPOINT}${categoryId}${SECTION_ENDPOINT}`);

export const createSection = (section: CreateSection): Observable<Section> =>
  POST(`${CATEGORY_ENDPOINT}${section.categoryId}${SECTION_ENDPOINT}`, {
    ...section,
  });

export const updateSection = (
  categoryId: string,
  section: Section
): Observable<Section> =>
  PUT(
    `${CATEGORY_ENDPOINT}${categoryId}${SECTION_ENDPOINT}${section.sectionId}`,
    {
      ...section,
    }
  );

export const deleteSection = (
  categoryId: string,
  sectionId: string
): Observable<null> =>
  DELETE(`${CATEGORY_ENDPOINT}${categoryId}${SECTION_ENDPOINT}${sectionId}`);
