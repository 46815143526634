import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import ResetPasswordApply from '../pages/ResetPasswordApply';
import ResetPassword from '../pages/ResetPassword';

export const LOGIN = '/login';
export const SIGNUP = '/signup/:confirmationToken';
export const RESET_PASSWORD_APPLY = '/reset-password';
export const RESET_PASSWORD = '/reset-password/:confirmationToken';

export default [
  {
    path: LOGIN,
    component: Login,
    authenticated: false,
    permissions: [],
    exact: true,
  },
  {
    path: SIGNUP,
    component: SignUp,
    authenticated: false,
    permissions: [],
    exact: true,
  },
  {
    path: RESET_PASSWORD_APPLY,
    component: ResetPasswordApply,
    authenticated: false,
    permissions: [],
    exact: true,
  },
  {
    path: RESET_PASSWORD,
    component: ResetPassword,
    authenticated: false,
    permissions: [],
    exact: true,
  },
];
