import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Col, Button, Table, Alert } from 'reactstrap';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { AiFillCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiAddToQueue } from 'react-icons/bi';
import { IoAddCircleOutline } from 'react-icons/io5';
import moment from 'moment-timezone';

import { RootState } from '../../../app/redux/rootReducer';
import {
  getMachineType,
  getMachineTypes,
  getWorkshops,
} from '../../dashboard/redux/organizationSelector';
import { MachineType } from '../../../models/machineType';
import { ConfigMachines } from '../../../models/machine';
import { AppDropdown, AppInput } from '../../../shared/forms/components';
import { machinesConfig } from '../../../services/api/machineApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { AppCheckBox, AppModal } from '../../../shared/components';
import { getUserList } from '../../../app/redux/appSelector';
import { User } from '../../../models/user';
import CreateEditMachineTypeForm from './CreateEditMachineTypeForm';
import { organizationActions } from '../../dashboard/redux/organizationSlice';

import './MachineConfig.css';
import MachineInfoModal from '../component/MachineInfoModal';
import { Workshop } from '../../../models/workshop';

const ButtonContainer = styled(Col)`
  text-align: center;
  button {
    width: 200px;
  }
`;

interface Props {
  users: Array<User>;
  machineTypes: Array<MachineType>;
  workshops: Array<Workshop>;
  getCurrentMachineType: (id: string) => MachineType | undefined;
}

const getInitialValues = (machineTypes: Array<MachineType>) => ({
  machines: [
    {
      machineTypeId: (machineTypes.length > 0 && machineTypes[0].id) || '',
      machineList: [],
    },
  ],
  createdAt: '',
});

const validationSchema = Yup.object().shape({
  machines: Yup.array()
    .of(
      Yup.object().shape({
        machineTypeId: Yup.string().required(),
        machineList: Yup.array()
          .of(
            Yup.object().shape({
              machineName: Yup.string().required('Machine name required'),
              machineSubNumber: Yup.string().required('Sub number required'),
              responsibleUserId: Yup.string(),
              on: Yup.boolean().required(),
            })
          )
          .min(1),
      })
    )
    .min(1),
});

const MachinesFirstTimeConfigForm: React.FC<Props> = ({
  users,
  machineTypes,
  workshops,
  getCurrentMachineType,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [machineTypeId, setMachineTypeId] = useState<string>();

  const history = useHistory();
  const dispatch = useDispatch();

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleInfoModalClose = () => {
    setMachineTypeId(undefined);
  };

  const usersOptions = users.map((user) => (
    <option key={user.userId} value={user.userId}>
      {user.email}
    </option>
  ));

  const machineTypeOptions = machineTypes.map((machineType) => (
    <option key={machineType.id} value={machineType.id}>
      {machineType.name}
    </option>
  ));

  const workshopOptions = workshops.map((workshop) => (
    <option key={workshop.workshopId} value={workshop.workshopId}>
      {workshop.workshopName}
    </option>
  ));

  const onSubmitHandler = (configMachines: ConfigMachines) => {
    setIsLoading(true);
    machinesConfig({
      ...configMachines,
      createdAt: moment().tz(moment.tz.guess()).format(),
    }).subscribe(
      (response) => {
        history.push('/machines');
        dispatch(organizationActions.fetchMachineList());
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully added machines to the system',
            title: 'Success',
          })
        );
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Create Machines',
          })
        );
      }
    );
  };

  useEffect(() => {
    document.title = 'Add New Machines';
  }, []);

  return (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-5 pt-3 header-txt">
          <h3 className="ml-3 header-title-style">SETUP MACHINES</h3>
        </div>
        <div className="col-lg-7 d-flex justify-content-end">
          <>
            <a
              onClick={handleModalToggle}
              className="add-new-link-btn ft-size-16"
            >
              <BiAddToQueue size={18} /> Add New Machine Type
            </a>
            <AppModal
              onClose={handleModalToggle}
              show={showModal}
              title="Add Machine Type"
            >
              <CreateEditMachineTypeForm handleOnSuccess={handleModalToggle} />
            </AppModal>
          </>
        </div>
      </div>
      {machineTypeOptions.length > 0 ? (
        <Formik
          initialValues={getInitialValues(machineTypes)}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
          validateOnMount
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FieldArray name="machines">
                {({ remove, push }) => (
                  <div>
                    {values.machines.length > 0 &&
                      values.machines.map((configMachineObj, index) => (
                        <Col key={index}>
                          <div className="table_outer_div machine-config-div">
                            <div className="container">
                              <div className="row">
                                <div className="col pd-2">
                                  <AppDropdown
                                    name={`machines.${index}.machineTypeId`}
                                    label=""
                                    placeholder="Enter Machine Type"
                                    loading={
                                      isLoading ||
                                      configMachineObj.machineList.length > 0
                                    }
                                  >
                                    {machineTypeOptions}
                                  </AppDropdown>
                                </div>
                                {/* <div>
                                  <AiOutlineInfoCircle
                                    size={25}
                                    onClick={() =>
                                      setMachineTypeId(
                                        values.machines[index].machineTypeId
                                      )
                                    }
                                  />
                                </div> */}
                                <div className="col header-txt">
                                  <a
                                    onClick={() =>
                                      setMachineTypeId(
                                        values.machines[index].machineTypeId
                                      )
                                    }
                                    className="add-new-link-btn-thin-border background-color-set-blue ft-size-16"
                                  >
                                    <AiOutlineInfoCircle size={25} />
                                  </a>
                                </div>
                                <div className="col header-txt">
                                  <a
                                    onClick={() => remove(index)}
                                    className={
                                      isLoading
                                        ? 'a-tag-disable add-new-link-btn-thin-border background-color-set-red ft-size-16 float-right'
                                        : 'add-new-link-btn-thin-border background-color-set-red ft-size-16 float-right'
                                    }
                                  >
                                    <AiFillCloseCircle size={25} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <Table
                              BorderLess
                              dark={false}
                              striped={false}
                              responsive={true}
                            >
                              <thead>
                                <tr>
                                  <td>Machine Name</td>
                                  <td>Sub Number</td>
                                  <td>Workshop</td>
                                  <td>Responsible User</td>
                                  <td>On</td>
                                  <td></td>
                                </tr>
                              </thead>

                              <FieldArray
                                name={`machines.${index}.machineList`}
                              >
                                {({ remove, push }) => (
                                  <>
                                    <tbody>
                                      {values.machines[index].machineList
                                        .length > 0 &&
                                        values.machines[index].machineList.map(
                                          (configMachine, j) => (
                                            <tr key={j}>
                                              <td>
                                                <AppInput
                                                  name={`machines.${index}.machineList.${j}.machineName`}
                                                  label=""
                                                  placeholder="Enter Machine Name"
                                                  type="text"
                                                  loading={isLoading}
                                                />
                                              </td>
                                              <td>
                                                <AppInput
                                                  name={`machines.${index}.machineList.${j}.machineSubNumber`}
                                                  label=""
                                                  placeholder="Enter Sub No"
                                                  type="text"
                                                  loading={isLoading}
                                                />
                                              </td>
                                              <td>
                                                <AppDropdown
                                                  name={`workshop.${index}.workshopList.${j}.workshopId`}
                                                  label=""
                                                  placeholder="Workshop"
                                                  loading={isLoading}
                                                >
                                                  <option
                                                    defaultChecked
                                                    value=""
                                                  >
                                                    NotSelected
                                                  </option>
                                                  {workshopOptions}
                                                </AppDropdown>
                                              </td>
                                              <td>
                                                <AppDropdown
                                                  name={`machines.${index}.machineList.${j}.responsibleUserId`}
                                                  label=""
                                                  placeholder="Responsible User"
                                                  loading={isLoading}
                                                >
                                                  <option
                                                    defaultChecked
                                                    value=""
                                                  >
                                                    Unassigned
                                                  </option>
                                                  {usersOptions}
                                                </AppDropdown>
                                              </td>
                                              <td>
                                                <AppCheckBox
                                                  label=""
                                                  checked
                                                  onChange={() =>
                                                    setFieldValue(
                                                      `machines.${index}.machineList.${j}.on`,
                                                      !values.machines[index]
                                                        .machineList[j]['on']
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <a
                                                  onClick={() => remove(j)}
                                                  className={
                                                    isLoading
                                                      ? 'a-tag-disable add-new-link-btn-thin-border ft-size-16'
                                                      : 'add-new-link-btn-thin-border ft-size-16'
                                                  }
                                                >
                                                  <AiFillCloseCircle
                                                    size={25}
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                    <div className="container">
                                      <div className="row justify-content-md-center">
                                        <div className="col-lg-12 p-2">
                                          <a
                                            onClick={() =>
                                              push({
                                                machineName: `${
                                                  getCurrentMachineType(
                                                    values.machines[index]
                                                      .machineTypeId
                                                  )?.name || ''
                                                } ${
                                                  values.machines[index]
                                                    .machineList.length + 1
                                                }`,
                                                machineSection: '',
                                                machineSubNumber: `${
                                                  values.machines[index]
                                                    .machineList.length + 1
                                                }`,
                                                responsibleUserId: '',
                                                on: true,
                                              })
                                            }
                                            className={
                                              isLoading
                                                ? 'a-tag-disable add-new-link-btn ft-size-16'
                                                : 'add-new-link-btn ft-size-16'
                                            }
                                          >
                                            <BiAddToQueue size={18} /> Add
                                            Machine
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </Table>
                          </div>
                        </Col>
                      ))}
                    <div className="row p-3">
                      <div className="col-lg-12">
                        <Alert
                          onClick={() =>
                            push({
                              machineTypeId: `${
                                (machineTypes.length > 0 &&
                                  machineTypes[0].id) ||
                                ''
                              }`,
                              machineList: [],
                            })
                          }
                          color="primary"
                          className="add-machine-type-btn"
                        >
                          <IoAddCircleOutline size={25} />
                          <h4>ADD MACHINE TYPE</h4>
                        </Alert>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
              <ButtonContainer>
                <Button
                  type="submit"
                  color="success"
                  disabled={isLoading || !isValid}
                >
                  Submit
                </Button>
              </ButtonContainer>
              {machineTypeId && (
                <MachineInfoModal
                  show={machineTypeId ? true : false}
                  onClose={handleInfoModalClose}
                  machineTypeId={machineTypeId}
                />
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <div>Error: Not found machine Types</div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  machineTypes: getMachineTypes(state),
  workshops: getWorkshops(state),
  users: getUserList(state),
  getCurrentMachineType: (id: string) => getMachineType(state, id),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MachinesFirstTimeConfigForm);
