import { Observable } from 'rxjs';

import { Subscription } from '../../models/subscription';
import { GET, POST, PUT } from './baseApi';

const SUBSCRIPTION_ENDPOINT = '/Subscription/';

export const getSubscriptions = (
  companyId?: string
): Observable<Array<Subscription>> => GET(SUBSCRIPTION_ENDPOINT, { companyId });

export const getSubscription = (
  subscriptionId: string
): Observable<Subscription> => GET(`${SUBSCRIPTION_ENDPOINT}${subscriptionId}`);

export const createSubscription = (
  subscription: Subscription
): Observable<Subscription> => POST(SUBSCRIPTION_ENDPOINT, { ...subscription });

export const updateSubscription = (
  subscription: Subscription
): Observable<Subscription> =>
  PUT(`${SUBSCRIPTION_ENDPOINT}${subscription.subscriptionId}`, {
    ...subscription,
  });
