import AdminMaintenanceDetails from '../../dashboard/container/Admin/AdminMaintenanceDetails';
import MaintenancesView from '../page/MaintenancesView';

export const MAINTENANCES = '/maintenances';
export const MAINTENANCE = '/maintenances/:id';

export default [
  {
    path: MAINTENANCES,
    component: AdminMaintenanceDetails,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: MAINTENANCE,
    component: MaintenancesView,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
