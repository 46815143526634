import { Dashboard } from '../pages';
import Profile from '../pages/dashboard/Profile';
import UpdatePasswordPage from '../pages/dashboard/UpdatePasswordPage';
import CompanyHome from '../pages/home/CompanyHome';

export const DASHBOARD = '/';
export const PROFILE = '/profile';
export const UPDATE_PASSWORD = '/update-password';
export const HOME = '/home';

export default [
  {
    path: DASHBOARD,
    component: Dashboard,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: PROFILE,
    component: Profile,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: UPDATE_PASSWORD,
    component: UpdatePasswordPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: HOME,
    component: CompanyHome,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
