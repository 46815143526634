/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { AuthenticationResponse, LoginRequest } from '../../../models/auth';
import authConstants from '../../../constants/storage';
import { UserRole } from '../../../models/userRole';
import { isSuperAdmin } from './authSelector';
import { roleTypes } from '../../../constants/userRole';

export interface AuthReducerState {
  auth: AuthenticationResponse | null;
  userRole: UserRole | null;
}

export const initialState: AuthReducerState = {
  auth: null,
  userRole: null,
};

const handleAuthenticationSuccess = (
  state: AuthReducerState,
  action: PayloadAction<AuthenticationResponse>
): void => {
  state.auth = action.payload;
};

const handleLogoutUser = (state: AuthReducerState): void => {
  state.auth = null;
  localStorage.removeItem(authConstants.AUTH_RESPONSE);
};

const setUserRole = (
  state: AuthReducerState,
  action: PayloadAction<UserRole>
): void => {
  state.userRole = action.payload;
};

const epicActions = {
  login: createAction<LoginRequest>('Login'),
  fetchUserRole: createAction<string>('fetchUserRole'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: handleLogoutUser,
    authenticateUserSuccess: handleAuthenticationSuccess,
    setUserRole,
  },
});

export const authActions = {
  ...authSlice.actions,
  ...epicActions,
};

export default authSlice.reducer;
