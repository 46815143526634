import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import {
  AppButton,
  AppDropdown,
  AppInput,
} from '../../../shared/forms/components';
import { notificationActions } from '../../../app/redux/notification.slice';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { Category } from '../../../models/category';
import { updateCategory } from '../../../services/api/categoryApi';
import { getUserList } from '../../../app/redux/appSelector';
import { RootState } from '../../../app/redux/rootReducer';

const getInitialValues = (category: Category) => ({
  ...category,
});

const validationSchema = Yup.object().shape({
  categoryName: Yup.string().required('This is a required field'),
  responsibleUserId: Yup.string().required('This is a required field'),
});

interface Props {
  category: Category;
  handleModalToggle?: () => void;
  setCategory?: (category: Category) => void;
}

const EditCategoryForm: React.FC<Props> = ({
  category,
  handleModalToggle,
  setCategory,
}: Props) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState(false);
  const users = getUserList(state);

  const getUsersOptions = () =>
    users.map((user) => (
      <option key={user.userId} value={user.userId}>
        {user.email}
      </option>
    ));

  const onSubmitHandler = (values: Category) => {
    setIsLoading(true);
    updateCategory(values).subscribe(
      (response: Category) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully Updated Category',
            title: 'Success',
          })
        );
        handleModalToggle && handleModalToggle();
        setCategory && setCategory(response);
      },
      (err: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: err.message,
            title: 'Error: Failed To Update Category',
          })
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues(category)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <AppInput
            name="categoryName"
            label="Category Name*"
            placeholder="Enter Category Name"
            type="text"
            loading={isLoading}
          />
          <AppDropdown
            name="responsibleUserId"
            label="Responsible User"
            placeholder="Responsible User"
          >
            <option defaultChecked value="">
              Unassigned
            </option>
            {getUsersOptions()}
          </AppDropdown>
          <AppButton type="submit" text="Update" loading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

export default EditCategoryForm;
