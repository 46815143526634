import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { ActivityType, CreateActivityType } from '../../models/activityType';

const ACTIVITY_TYPE_ENDPOINT = '/activitytype/';

export const getActivityTypes = (): Observable<Array<ActivityType>> =>
  GET(ACTIVITY_TYPE_ENDPOINT);

export const getActivityType = (id: string): Observable<ActivityType> =>
  GET(`${ACTIVITY_TYPE_ENDPOINT}${id}`);

export const createActivityType = (
  activityType: CreateActivityType
): Observable<ActivityType> =>
  POST(ACTIVITY_TYPE_ENDPOINT, { ...activityType });

export const updateActivityType = (
  activityType: ActivityType
): Observable<ActivityType> =>
  PUT(`${ACTIVITY_TYPE_ENDPOINT}${activityType.activityTypeId}`, {
    ...activityType,
  });
