import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppButton, AppInput } from '../../../shared/forms/components';
import AppDropdown from '../../../shared/forms/components/AppDropDown/AppDropDown.component';
import { User } from '../../../models/user';
import { getUserList } from '../../../app/redux/appSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { CreateCategory } from '../../../models/category';
import { organizationActions } from '../../dashboard/redux/organizationSlice';

const initialValues = {
  categoryName: '',
  responsibleUserId: '',
};

const validationSchema = Yup.object().shape({
  categoryName: Yup.string().required(),
  responsibleUserId: Yup.string().required(),
});

interface Props {
  state: RootState;
  users: Array<User>;
  createCategory: (category: CreateCategory) => void;
  onSubmit?: () => void;
}

const CreateCategoryForm: React.FC<Props> = ({
  state,
  users,
  createCategory,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const getUsersOptions = () =>
    users.map((user) => (
      <option key={user.userId} value={user.userId}>
        {user.email}
      </option>
    ));

  const onSubmitHandler = (values: CreateCategory) => {
    onSubmit && onSubmit();
    createCategory(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <AppInput
            name="categoryName"
            label="Category Name*"
            placeholder="Enter Category Name"
            type="text"
            loading={isLoading}
          />
          <AppDropdown
            name="responsibleUserId"
            label="Responsible User"
            placeholder="Responsible User"
            loading={isLoading}
          >
            <option defaultChecked value="">
              Unassigned
            </option>
            {getUsersOptions()}
          </AppDropdown>
          <AppButton type="submit" text="Create" loading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  users: getUserList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createCategory: (category: CreateCategory) => {
    dispatch(organizationActions.createCategory(category));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategoryForm);
