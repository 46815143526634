import CategoryListViewPage from '../page/CategoryListViewPage';
import CategoryViewPage from '../page/CategoryViewPage';
import SectionViewPage from '../page/SectionViewPage';

export const SETTINGS = '/settings';
export const SETTINGS_CATEGORY = SETTINGS + '/category';
export const CATEGORY = SETTINGS + '/category/:id';
export const SECTION = SETTINGS + '/section/:categoryId/:sectionId';

export default [
  {
    path: SETTINGS_CATEGORY,
    component: CategoryListViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: CATEGORY,
    component: CategoryViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: SECTION,
    component: SectionViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
