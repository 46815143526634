import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BiAddToQueue } from 'react-icons/bi';

import MachineView from '../component/MachineDetails';
import { AppModal, Loader, WarningModal } from '../../../shared/components';
import CreateActivityForm from '../../activity/forms/CreateActivityForm';
import { RootState } from '../../../app/redux/rootReducer';
import { isAdmin as checkIsAdmin } from '../../auth/redux/authSelector';
import { AiOutlineDelete } from 'react-icons/ai';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { getMachine } from '../../dashboard/redux/organizationSelector';

interface ParamsProps {
  id: string;
}
const MachineViewPage: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const state = useSelector((state: RootState) => state);
  const isAdmin = checkIsAdmin(state);
  const machine = getMachine(state, id);
  console.log(machine);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const toggleWarningModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const handleOnClickWarning = () => {
    dispatch(
      organizationActions.deleteMachine({
        machineId: id,
      })
    );
    toggleWarningModal();
  };

  return machine ? (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          {isAdmin && (
            <>
              <button
                onClick={toggleWarningModal}
                className="add-new-link-btn add-new-link-btn-red ft-size-16"
              >
                <AiOutlineDelete size={18} /> Delete Machine
              </button>
              <button
                onClick={handleModalToggle}
                className={
                  machine && !machine.on
                    ? 'add-new-link-btn ft-size-16 a-tag-disable'
                    : 'add-new-link-btn ft-size-16 '
                }
              >
                <BiAddToQueue size={18} /> Add New Activity
              </button>
              <AppModal
                onClose={handleModalToggle}
                show={showModal}
                title="Add Activity"
              >
                <CreateActivityForm
                  machineTypeId={machine.machineTypeId}
                  machineId={id}
                  onSubmit={handleModalToggle}
                />
              </AppModal>
            </>
          )}
        </div>
      </div>
      <MachineView machineDetailsObj={machine} />
      {isAdmin && (
        <>
          <WarningModal
            show={showWarningModal}
            onClose={toggleWarningModal}
            onClickOk={handleOnClickWarning}
          />
        </>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default MachineViewPage;
