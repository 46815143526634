import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';
import { HiFilter } from 'react-icons/hi';

import { Alert } from 'reactstrap';
import { Activity } from '../../../../models/activity';
import { ActivityType } from '../../../../models/activityType';
import { Machine, MachineState } from '../../../../models/machine';
import { MachineType } from '../../../../models/machineType';
import { getActivities } from '../../../../services/api/activityApi';
import {
  getActivityType,
  getMachineList,
  getMachineTypes,
} from '../../../dashboard/redux/organizationSelector';
import { AppModal } from '../../../../shared/components';
import DateRangePicker from '../../../../shared/components/DateRangePicker/DateRangePickerComp';
import SearchDropDown from '../../../../shared/components/SearchDropDown/SearchDropDown';
import { RootState } from '../../../../app/redux/rootReducer';
import { organizationActions } from '../../../dashboard/redux/organizationSlice';
import { User } from '../../../../models/user';
import { getUserList } from '../../../../app/redux/appSelector';

interface DropDownElement {
  value: string;
  label: string;
}

export interface FilterValues {
  machineId: string;
  machineTypeId: string;
  activityId: string;
  dateRange: Array<string>;
  responsiblePersonId: string;
}

interface SearchProps {
  state: RootState;
  filterValueObj: FilterValues;
  selectedFilter?: (value: FilterValues | any) => void;
  machines: Array<Machine>;
  fetchMachines: () => void;
  machineTypes: Array<MachineType>;
  users: Array<User>;
}

let tmpFilterValueList: FilterValues;
let activityTypeDetails: ActivityType;

const SearchBox: React.FC<SearchProps> = ({
  state,
  filterValueObj,
  selectedFilter,
  machines,
  fetchMachines,
  machineTypes,
  users,
}: SearchProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isMachineNameSelected, setIsMachineNameSelected] = useState(false);
  const [isMachineTypeSelected, setIsMachineTypeSelected] = useState(false);
  const [
    isResponsiblePersonSelected,
    setIsResponsiblePersonSelected,
  ] = useState(false);

  const [
    selectedMachineNameFilter,
    setMachineNameFilter,
  ] = useState<DropDownElement>();
  const [
    selectedMachineTypeFilter,
    setMachineTypeFilter,
  ] = useState<DropDownElement>();
  const [
    selectedActivityNameFilter,
    setActivityNameFilter,
  ] = useState<DropDownElement>();
  const [
    selectedResponsiblePersonFilter,
    setResponsiblePersonFilter,
  ] = useState<DropDownElement>();

  const [machineNameList, setMachineNameList] = useState<
    Array<DropDownElement>
  >([]);
  const [machineTypeList, setMachineTypeList] = useState<
    Array<DropDownElement>
  >([]);
  const [activityNameList, setActivityNameList] = useState<
    Array<DropDownElement>
  >([]);
  const [responsiblePersonList, setResponsiblePersonList] = useState<
    Array<DropDownElement>
  >([]);

  const [filterValueList, setFilterValueList] = useState<FilterValues>(
    filterValueObj
  );

  function activityNameListGet(machineIdParm: string) {
    const tempActivityList: Array<DropDownElement> = [];

    getActivities({ machineId: machineIdParm }).subscribe(
      (response: Array<Activity>) => {
        response.map((item) => {
          activityTypeDetails = getActivityType(state, item.activityTypeId)!;
          tempActivityList.push({
            value: item.activityId,
            label: activityTypeDetails?.name,
          });

          if (filterValueList.activityId) {
            const tempActivityNameObj = tempActivityList.filter(
              (option) => option.value === filterValueList.activityId
            );

            if (tempActivityNameObj.length > 0) {
              setActivityNameFilter(tempActivityNameObj[0]);
            }
          }
        });
        setActivityNameList(tempActivityList);
        setIsLoading(false);
      },
      (err: Error) => {
        setError(err.message);
        setIsLoading(false);
      }
    );
  }

  function handleModalOpen() {
    setShowModal(!showModal);
  }

  function handleModalClose() {
    setShowModal(!showModal);
  }

  function handleApplyFilterClick() {
    setShowModal(!showModal);
    selectedFilter && selectedFilter(filterValueList);
  }

  const { list, loading } = useSelector(
    (state: RootState): MachineState => state.organization.machine
  );

  useEffect(() => {
    setIsLoading(true);
    setResponsiblePersonList(
      users.map((item) => ({
        value: item.userId,
        label: `${item?.firstName || ''} ${item?.lastName || ''}`,
      }))
    );

    if (list) {
      // Machines
      machines.map((item) => {
        machineNameList.push({
          value: item.machineId,
          label: item.machineName,
        });
      });
      setMachineNameList(machineNameList);

      setIsLoading(false);

      const tempMachineNameObj = machineNameList.filter(
        (option) => option.value === filterValueList.machineId
      );

      if (tempMachineNameObj.length > 0) {
        setMachineNameFilter(tempMachineNameObj[0]);
        activityNameListGet(tempMachineNameObj[0].value);
        setIsMachineNameSelected(true);
      }

      // Machine Types
      machineTypes.map((item) => {
        machineTypeList.push({
          value: item.id,
          label: item.name,
        });
      });
      setMachineTypeList(machineTypeList);
      setIsLoading(false);
    } else if (!loading) {
      fetchMachines();
    }
  }, [loading]);

  function handleResponsiblePersonTagClose() {
    tmpFilterValueList = {
      ...filterValueList,
      responsiblePersonId: '',
    };
    setFilterValueList(tmpFilterValueList);
    selectedFilter && selectedFilter(tmpFilterValueList);
    setIsResponsiblePersonSelected(false);
  }

  function handleMachineNameTagClose() {
    tmpFilterValueList = {
      ...filterValueList,
      machineId: '',
      activityId: '',
    };
    setFilterValueList(tmpFilterValueList);
    selectedFilter && selectedFilter(tmpFilterValueList);
    setIsMachineNameSelected(false);
  }

  function handleMachineNameChange(option: DropDownElement | null) {
    option &&
      setFilterValueList({
        ...filterValueList,
        machineId: option.value,
        activityId: '',
      });
    option && setMachineNameFilter(option);
    option && activityNameListGet(option.value);
    setIsMachineNameSelected(true);
  }

  function handleMachineTypeTagClose() {
    tmpFilterValueList = {
      ...filterValueList,
      machineTypeId: '',
      activityId: '',
    };
    setFilterValueList(tmpFilterValueList);
    selectedFilter && selectedFilter(tmpFilterValueList);
    setIsMachineTypeSelected(false);
  }

  function handleMachineTypeChange(option: DropDownElement | null) {
    option &&
      setFilterValueList({
        ...filterValueList,
        machineTypeId: option.value,
        activityId: '',
      });
    option && setMachineTypeFilter(option);
    option && activityNameListGet(option.value);

    setIsMachineTypeSelected(true);
  }

  function handleActivityNameTagClose() {
    tmpFilterValueList = {
      ...filterValueList,
      activityId: '',
    };
    setFilterValueList(tmpFilterValueList);
    selectedFilter && selectedFilter(tmpFilterValueList);
  }

  function handleActivityNameChange(option: DropDownElement | null) {
    option &&
      setFilterValueList({
        ...filterValueList,
        activityId: option.value,
      });
    option && setActivityNameFilter(option);
  }

  function handleResponsiblePersonChange(option: DropDownElement | null) {
    option &&
      setFilterValueList({
        ...filterValueList,
        responsiblePersonId: option.value,
      });
    option && setResponsiblePersonFilter(option);
  }

  function handleDateChange(option: Array<string> | null) {
    if (option) {
      tmpFilterValueList = {
        ...filterValueList,
        dateRange: option,
      };
      setFilterValueList(tmpFilterValueList);
      selectedFilter && selectedFilter(tmpFilterValueList);
    }
  }

  return (
    <div className="container">
      <div className="row pt-2">
        <DateRangePicker
          selectedObjectChanged={(value) => handleDateChange(value)}
          startDate={filterValueObj.dateRange[0]}
          endDate={filterValueObj.dateRange[1]}
        />

        <Alert
          className="mr-2"
          color="danger"
          isOpen={filterValueList.responsiblePersonId === '' ? false : true}
          toggle={() => handleResponsiblePersonTagClose()}
        >
          Responsible Person: {selectedResponsiblePersonFilter?.label}
        </Alert>

        <Alert
          className="mr-2"
          color="info"
          isOpen={filterValueList.machineId === '' ? false : true}
          toggle={() => handleMachineNameTagClose()}
        >
          Machine: {selectedMachineNameFilter?.label}
        </Alert>

        <Alert
          className="mr-2"
          color="info"
          isOpen={filterValueList.machineTypeId === '' ? false : true}
          toggle={() => handleMachineTypeTagClose()}
        >
          Machine Type: {selectedMachineTypeFilter?.label}
        </Alert>

        <Alert
          className="mr-2"
          color="success"
          isOpen={filterValueList.activityId === '' ? false : true}
          toggle={() => handleActivityNameTagClose()}
        >
          Activity: {selectedActivityNameFilter?.label}
        </Alert>

        <div className="col">
          <a
            onClick={() => handleModalOpen()}
            className="add-new-link-btn ft-size-16 float-right margin-top-none"
          >
            <HiFilter size={18} /> Add New Filter
          </a>
        </div>
      </div>
      <AppModal
        onClose={() => handleModalClose()}
        show={showModal}
        title="Add your filter list"
      >
        <div className="container">
          {!isLoading && !error && !isMachineTypeSelected && (
            <div className="row">
              <div className="col">
                <SearchDropDown
                  dataList={machineNameList}
                  selectedValueElement={filterValueList.machineId}
                  placeholder="Enter machine name"
                  nameTop="Machine Name"
                  selectedObjectChanged={(value) =>
                    handleMachineNameChange(value)
                  }
                />
              </div>
            </div>
          )}
          {/* {!isLoading && !error && !isMachineNameSelected && (
            <div className="row pt-3">
              <div className="col">
                <SearchDropDown
                  dataList={machineTypeList}
                  selectedValueElement={filterValueList.machineTypeId}
                  placeholder="Enter machine type"
                  nameTop="Machine Type:"
                  selectedObjectChanged={(value) =>
                    handleMachineTypeChange(value)
                  }
                />
              </div>
            </div>
          )} */}
          {(isMachineNameSelected || isMachineTypeSelected) && (
            <div className="row pt-3">
              <div className="col">
                <SearchDropDown
                  dataList={activityNameList}
                  selectedValueElement={filterValueList.activityId}
                  placeholder="Enter Activity"
                  nameTop="Activity:"
                  selectedObjectChanged={(value) =>
                    handleActivityNameChange(value)
                  }
                />
              </div>
            </div>
          )}
          {!isLoading && !error && (
            <div className="row pt-3">
              <div className="col">
                <SearchDropDown
                  dataList={responsiblePersonList}
                  selectedValueElement={filterValueList.responsiblePersonId}
                  placeholder="Enter responsible person"
                  nameTop="Responsible Person:"
                  selectedObjectChanged={(value) =>
                    handleResponsiblePersonChange(value)
                  }
                />
              </div>
            </div>
          )}
          <div className="row pt-3">
            <div className="col d-flex justify-content-center">
              <a
                onClick={() => handleApplyFilterClick()}
                className="add-new-link-btn ft-size-16"
              >
                <HiFilter size={18} /> APPLY FILTER
              </a>
            </div>
          </div>
        </div>
      </AppModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  machines: getMachineList(state),
  machineTypes: getMachineTypes(state),
  users: getUserList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMachines: () => {
    dispatch(organizationActions.fetchMachineList());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
