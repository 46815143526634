import React, { useState } from 'react';
import { DateRangePicker, Range, RangeWithKey } from 'react-date-range';
import { AiOutlineFileSearch } from 'react-icons/ai';
import moment from 'moment-timezone';
import { Alert } from 'reactstrap';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateRangePickerComp.css';

interface SelectionProps {
  section: RangeWithKey;
}

interface Props {
  selectedObjectChanged?: (value: Array<string> | any) => void;
  startDate: string;
  endDate: string;
}

const DateRangePickerComp: React.FC<Props> = ({
  selectedObjectChanged,
  startDate,
  endDate,
}: Props) => {
  const [currentDateRange, setCurrentDateRange] = useState<Range>({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'section',
  });
  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const [isButtonShow, setIsButtonShow] = useState<boolean>(false);

  function handleSelect(date: any) {
    const dateTemp = date as SelectionProps;

    setCurrentDateRange({
      startDate: dateTemp.section.startDate,
      endDate: dateTemp.section.endDate,
      key: 'section',
    });

    setIsButtonShow(true);
  }

  function startSearchClick() {
    setIsOpenDatePicker(!isOpenDatePicker);

    const startDate = moment(currentDateRange.startDate).format('YYYY-MM-DD');
    const endDate = moment(currentDateRange.endDate).format('YYYY-MM-DD');

    selectedObjectChanged && selectedObjectChanged([startDate, endDate]);
  }

  function inputBoxClick() {
    setIsOpenDatePicker(!isOpenDatePicker);
  }
  return (
    <div className="">
      <div className="date-picker-relative-div">
        <Alert
          className="date-range-input-box mr-2"
          color="warning"
          onClick={() => inputBoxClick()}
        >
          Duration:{' '}
          {currentDateRange.endDate &&
            moment(currentDateRange.startDate)
              .tz(moment.tz.guess())
              .format('YYYY-MM-DD')
              .concat(' - ')
              .concat(
                moment(currentDateRange.endDate)
                  .tz(moment.tz.guess())
                  .format('YYYY-MM-DD')
              )}
        </Alert>
        {isOpenDatePicker && (
          <div className="date-picker-div">
            <DateRangePicker
              className="date-picker-picker"
              editableDateInputs={false}
              onChange={(values) => handleSelect(values)}
              moveRangeOnFirstSelection={false}
              ranges={[currentDateRange]}
              // minDate={addDays(new Date(), -120)}
              rangeColors={['#3579bc']}
              maxDate={new Date()}
              months={1}
              direction={window.innerWidth >= 600 ? 'horizontal' : 'vertical'}
              showDateDisplay={false}
            />
            {isButtonShow && (
              <a
                onClick={() => startSearchClick()}
                className="add-new-link-btn ft-size-16 float-right margin-top-none"
              >
                <AiOutlineFileSearch size={18} /> Apply
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangePickerComp;
